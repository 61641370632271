import React, { useEffect, useState, forwardRef } from 'react';
import { Box, TextFieldProps } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import CodeField from './CodeField';

export type ITwoFAFieldProps = {
	codeLength?: number;
	onChange?: (value: string) => void;
	value?: string;
} & Omit<TextFieldProps, 'onFocus' | 'onPaste' | 'onChange' | 'value'>;

export const TwoFAField = forwardRef<HTMLDivElement, ITwoFAFieldProps>(
	({ onChange, value, codeLength = 6, ...textFieldProps }, ref) => {
		const existingTheme = useTheme();
		const newTheme = createTheme({
			...existingTheme,
			components: {
				...existingTheme.components,
				MuiInputBase: {
					styleOverrides: {
						input: {
							textAlign: 'center',
							minWidth: '20px',
							[existingTheme.breakpoints.down('sm')]: {
								padding: '12px 6px',
							},
						},
					},
				},
			},
		});

		const [currentFocusedIndex, setCurrentFocusedIndex] = useState<number>(0);
		const [inputValues, setInputValues] = useState<string[]>(Array(codeLength).fill(''));

		const clampIndex = (index: number): number => {
			if (index >= codeLength) {
				return codeLength - 1;
			}
			if (index < 0) {
				return 0;
			}

			return index;
		};

		const handleInput = (index: number, currentValue: string) => {
			setInputValues([...inputValues.slice(0, index), currentValue.trim(), ...inputValues.slice(index + 1)]);

			if (currentValue !== '') {
				setCurrentFocusedIndex(clampIndex(currentFocusedIndex + 1));
			}
		};

		const handleBack = () => {
			if (inputValues[currentFocusedIndex] !== '') {
				handleInput(currentFocusedIndex, '');
			}
			setCurrentFocusedIndex(clampIndex(currentFocusedIndex - 1));
		};

		const handlePaste = (pastedValue: string) => {
			setInputValues(inputValues.map((_, index) => pastedValue[index] || ''));
		};

		const handleFocus = (focusedIndex: number) => {
			setCurrentFocusedIndex(focusedIndex);
		};

		useEffect(() => {
			if (value) {
				handlePaste(value);
			}
		}, [value]);

		useEffect(() => {
			const lastValue = inputValues.join('');
			if (onChange && lastValue) {
				onChange(lastValue);
			}
		}, [inputValues, onChange]);

		useEffect(() => {
			if (value === '') {
				setInputValues(Array(codeLength).fill(''));
			}
		}, [value]);

		return (
			<ThemeProvider theme={newTheme}>
				<Box
					ref={ref}
					sx={{
						display: 'flex',
						gap: '10px',
						minWidth: 'min-content',
					}}
				>
					{inputValues.map((inputValue: string, index: number) => {
						const key = `key_${index}`;
						return (
							<CodeField
								key={key}
								index={index}
								value={inputValue}
								onChange={handleInput}
								onBackspace={handleBack}
								onPaste={handlePaste}
								onFocus={handleFocus}
								isFocused={index === currentFocusedIndex}
								{...textFieldProps}
							/>
						);
					})}
				</Box>
			</ThemeProvider>
		);
	}
);

TwoFAField.displayName = 'TwoFAField';

export default TwoFAField;
