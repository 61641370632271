import React, { useCallback, useMemo } from 'react';
import { Chip, ChipProps } from '@mui/material';

export interface StatusChipLegend {
	id: string | number;
	label?: string;
	icon?: React.ReactElement;
	color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export interface StatusChipProps extends Partial<ChipProps> {
	legend: StatusChipLegend[];
	statusId: string | number;
}

export function StatusChip({ legend, statusId, ...chipProps }: StatusChipProps) {
	const getStatus = useCallback(
		(id: string | number) => {
			return legend.find((item) => item.id === id);
		},
		[legend]
	);

	const { id, ...statusChipProps } = useMemo(() => getStatus(statusId), [getStatus, statusId]) || {};

	if (id == null) {
		return null;
	}

	return (
		<Chip
			component="span"
			size="small"
			sx={{ fontWeight: 700, textTransform: 'capitalize' }}
			{...statusChipProps}
			{...chipProps}
		/>
	);
}

export default StatusChip;
