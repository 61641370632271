import React, { useEffect, useState, useRef, useMemo } from 'react';
import DataGrid from 'components/common/dataGrid';
import { Paper, PaperTitle } from 'components/common/dashboardLayout';
import { GridColDef } from '@mui/x-data-grid';
import { FiltersBar } from 'components/common/inputs/filters';
import { Box, Button, Chip, Drawer, Link, Stack, Toolbar } from '@mui/material';
import {
	useConfirmation,
	useDisclosure,
	usePatientFiltersDeleteMutation,
	usePatientFiltersQuery,
	usePatientListQuery,
} from 'hooks';
import { PatientListFilters } from 'components/enterprise';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PatientFilter, PatientFilterQuery } from 'core/api/enterprise/patient.models';
import ConfirmationDialog from 'components/common/confirmationDialog';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import routes from 'routes';

export function PatientList() {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const [filterId, setFilterId] = useState<number>();
	const [filterQuery, setFilterQuery] = useState<Partial<PatientFilterQuery>>({});
	const { data, isLoading } = usePatientListQuery({
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
		filterId,
		...filterQuery,
	});
	const { member: patientsData, totalItems: totalPatients } = data ?? {};

	const rowCountRef = useRef(totalPatients || 0);
	const rowCount = useMemo(() => {
		if (totalPatients !== undefined) {
			rowCountRef.current = totalPatients;
		}
		return rowCountRef.current;
	}, [totalPatients]);

	const { data: filtersData, isLoading: isLoadingFilters } = usePatientFiltersQuery();
	const { member: filtersList } = filtersData ?? {};

	const { mutate: deleteFilterMutate } = usePatientFiltersDeleteMutation();

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', sortable: false, flex: 1 },
		{
			field: 'fullName',
			headerName: 'Name',
			sortable: false,
			flex: 2,
			renderCell: ({ row, value }) => (
				<Link
					component={RouterLink}
					to={generatePath(routes.enterprise.patients.patient.profile, {
						patientId: row.id,
					})}
					fontWeight="bold"
				>
					{value || `Patient #${row.id}`}
				</Link>
			),
		},
		{ field: 'territory', headerName: 'Territory', sortable: false, flex: 1 },
		{ field: 'state', headerName: 'State', sortable: false, flex: 1 },
		{ field: 'medsWithoutRefills', headerName: 'Meds W/O Refills', sortable: false, flex: 1 },
		{ field: 'status', headerName: 'Status', sortable: false, flex: 1 },
		{ field: 'onboardingStatus', headerName: 'Onboarding Status', sortable: false, flex: 2 },
		{ field: 'postalCode', headerName: 'Postal Code', sortable: false, flex: 1 },
		{ field: 'dateOfBirth', headerName: 'DOB', sortable: false, flex: 1 },
	];

	const { isOpen: isOpenFiltersBar, onToggle: toggleFiltersBar } = useDisclosure();
	const {
		isOpen: isOpenConfirmationDialog,
		onOpen: onOpenConfirmationDialog,
		onCancel: onCancelConfirmationDialog,
		onConfirm: onConfirmDeleteFilter,
	} = useConfirmation();

	const handleFilter = (filter: Partial<PatientFilter>) => {
		if (filter?.id) {
			setFilterId(filter.id);
		} else {
			setFilterId(undefined);
			setFilterQuery(filter);
		}
		toggleFiltersBar();
	};

	const handleSetFilter = (currentFilterId: number) => {
		if (currentFilterId === filterId) {
			setFilterId(undefined);
		} else {
			setFilterId(currentFilterId);
		}
	};
	const handleDeleteFilter = (currentFilterId: number) => {
		if (currentFilterId === filterId) {
			setFilterId(undefined);
		}

		deleteFilterMutate({ id: currentFilterId.toString() });
	};

	useEffect(() => {
		if (filterId) {
			const filter = filtersList?.find((item) => item.id === filterId);

			setFilterQuery(filter || {});
		}
	}, [filterId, filtersList]);

	return (
		<Paper>
			<PaperTitle>Patients ({rowCount})</PaperTitle>
			<FiltersBar>
				<Box sx={{ flex: 4 }}>
					{isLoadingFilters && <Box>Loading filters...</Box>}
					{!isLoadingFilters && (
						<Stack direction="row" spacing={1}>
							{filtersList?.map((filter) => (
								<Chip
									key={filter.id}
									label={filter.name}
									onClick={() => handleSetFilter(filter.id)}
									onDelete={() => onOpenConfirmationDialog(() => handleDeleteFilter(filter.id))}
									variant={filterId === filter.id ? 'filled' : 'outlined'}
								/>
							))}
						</Stack>
					)}

					<ConfirmationDialog
						title="Delete filter"
						content="Are you sure you want to delete this filter?"
						open={isOpenConfirmationDialog}
						onConfirm={onConfirmDeleteFilter}
						onCancel={onCancelConfirmationDialog}
					/>
				</Box>
				<Button onClick={toggleFiltersBar} startIcon={<FilterListIcon />} variant="outlined">
					Filters
				</Button>
			</FiltersBar>
			<DataGrid
				autoHeight
				rows={patientsData || []}
				columns={columns}
				loading={isLoading}
				rowCount={rowCount}
				pageSizeOptions={[5, 10, 25]}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				paginationMode="server"
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
				getRowClassName={(params) => `row-status--${params.row.status}`}
			/>
			<Drawer anchor="right" open={isOpenFiltersBar} onClose={toggleFiltersBar}>
				<Toolbar />
				<PatientListFilters
					onFilter={handleFilter}
					onSaveFilter={handleFilter}
					onClose={toggleFiltersBar}
					{...filterQuery}
				/>
			</Drawer>
		</Paper>
	);
}

export default PatientList;
