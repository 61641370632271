import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	changeAddressRequest,
	changeNotificationSetting,
	deleteAccountRequest,
	makeAPaymentRequest,
} from 'core/api/userAccount/userAccount.api';
import { userAccountQueries } from 'queries/patient/userAccountQueries';

export function useAccountProfile() {
	return useQuery(userAccountQueries.userAccountProfile());
}

export function useNotificationSettings() {
	return useQuery(userAccountQueries.notificationSettingList());
}

export function useUserAccountMutations() {
	const queryClient = useQueryClient();
	const userAccountQueryKey = userAccountQueries.userAccount().queryKey;

	const createPaymentUrlMutation = useMutation({
		mutationKey: [...userAccountQueryKey, 'payment-url'],
		mutationFn: makeAPaymentRequest,
	});

	const changeAddressMutation = useMutation({
		mutationFn: changeAddressRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: userAccountQueryKey });
		},
	});

	const changeNotificationSettingMutation = useMutation({
		mutationKey: [...userAccountQueries.notificationSetting().queryKey, 'update'],
		mutationFn: changeNotificationSetting,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: userAccountQueries.notificationSettingList().queryKey,
			});
		},
	});

	const deleteAccountMutation = useMutation({
		mutationFn: deleteAccountRequest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: userAccountQueryKey });
		},
	});

	return {
		createPaymentUrlMutation,
		changeAddressMutation,
		changeNotificationSettingMutation,
		deleteAccountMutation,
	};
}
