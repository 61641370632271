import React from 'react';
import { Stack, Typography } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { CalendarWeekDayStyled } from './CalendarWeekDay.styles';

interface CalendarWeekDayProps {
	day: number;
	dayLabel?: string;
	today?: boolean;
	todayLabel?: string;
	color?: keyof PaletteOptions;
	showWeekDayLabel?: boolean;
}

export function CalendarWeekDay({
	dayLabel,
	day,
	color,
	today = false,
	todayLabel = 'Today',
	showWeekDayLabel,
}: CalendarWeekDayProps) {
	return (
		<Stack direction="column" alignItems="center" gap="4px">
			{showWeekDayLabel && (
				<Typography
					fontSize={12}
					fontWeight={today ? 700 : 400}
					color={today ? 'text.secondary' : 'text.primary'}
					textTransform="uppercase"
				>
					{today ? todayLabel : dayLabel || day}
				</Typography>
			)}
			<CalendarWeekDayStyled {...{ color, today }}>{day}</CalendarWeekDayStyled>
		</Stack>
	);
}
