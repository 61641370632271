import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { CareTeamMemberStatus } from 'core/models/admin/enterprise.models';

interface CaregiverStatusChipProps extends Omit<ChipProps, 'label' | 'size' | 'color'> {
	status: CareTeamMemberStatus;
}

function CaregiverStatusChip({ status }: CaregiverStatusChipProps) {
	const chipStyle = {
		fontWeight: 700,
		textTransform: 'capitalize',
	};

	switch (status) {
		case CareTeamMemberStatus.APPROVED:
			return <Chip sx={chipStyle} component="span" label="Approved" size="small" color="success" />;
		case CareTeamMemberStatus.PENDING:
			return <Chip sx={chipStyle} component="span" label="Pending" size="small" color="warning" />;
		case CareTeamMemberStatus.REGISTERED:
			return <Chip sx={chipStyle} component="span" label="Registered" size="small" color="info" />;
		default:
			return null;
	}
}

export default CaregiverStatusChip;
