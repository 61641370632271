import React from 'react';
import { AppProvider } from './AppContext';
import { UserProvider } from './UserContext';

type RootProviderProps = {
	children: React.ReactNode;
};

export function RootProvider({ children }: RootProviderProps) {
	return (
		<UserProvider>
			<AppProvider>{children}</AppProvider>
		</UserProvider>
	);
}
