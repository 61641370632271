import { Paper as MuiPaper, styled } from '@mui/material';

export const Paper = styled(MuiPaper)(({ theme }) => ({
	boxShadow: 'none',
	borderRadius: '20px',
	padding: '24px 24px 0 24px',
	background: theme.palette.background.default,
	height: '100%',

	[theme.breakpoints.down('sm')]: {
		background: 'transparent',
		padding: 0,
		borderRadius: 0,
	},
}));
