import React, { useCallback, useMemo } from 'react';
import { Paper, PaperTitle } from 'components/common/dashboardLayout';
import DataGrid from 'components/common/dataGrid';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteUserMetaDataParams, PatientDetails } from 'core/api/admin/patients.models';
import { Stack } from '@mui/material';
import { useUserMetaDataList, useDeleteUserMetaData } from 'hooks/admin/useUserMetaData';
import useDialog from 'hooks/common/useDialog';
import ConfirmationDialog from 'components/common/confirmationDialog';

interface PatientsMetaDataListProps extends Pick<PatientDetails, 'userId'> {}

export function PatientsMetaDataList({ userId }: PatientsMetaDataListProps) {
	const { data, isLoading } = useUserMetaDataList({
		userId: userId.toString(),
	});
	const deleteUserMetaDataMutation = useDeleteUserMetaData();
	const { member, totalItems } = data ?? {};

	const [
		isOpenDeleteConfirmationDialog,
		openDeleteConfirmationDialog,
		closeDeleteConfirmationDialog,
		metaDataToDelete,
	] = useDialog<DeleteUserMetaDataParams>();

	const handleDeleteMetaData = useCallback(() => {
		if (metaDataToDelete) {
			deleteUserMetaDataMutation.mutate(metaDataToDelete, { onSuccess: closeDeleteConfirmationDialog });
		}
	}, [metaDataToDelete]);

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'id', headerName: 'ID', sortable: true, flex: 1 },
			{
				field: 'key',
				headerName: 'Key',
				sortable: false,
				flex: 2,
			},
			{
				field: 'value',
				headerName: 'Value',
				sortable: false,
				flex: 9,
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				getActions: ({ row }) => [
					<GridActionsCellItem
						icon={<DeleteIcon />}
						title="Delete"
						label="Delete"
						color="inherit"
						onClick={() => openDeleteConfirmationDialog({ id: row.id, userId: userId.toString() })}
					/>,
				],
			},
		],
		[]
	);

	return (
		<Paper>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
				<PaperTitle>User meta data</PaperTitle>
			</Stack>
			<DataGrid
				autoHeight
				rows={member || []}
				columns={columns}
				loading={isLoading}
				rowCount={totalItems || 0}
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
			/>
			<ConfirmationDialog
				isLoading={deleteUserMetaDataMutation.isPending}
				title="Delete Meta Data"
				content="Are you sure you want to delete this meta data?"
				open={isOpenDeleteConfirmationDialog}
				onConfirm={handleDeleteMetaData}
				onCancel={closeDeleteConfirmationDialog}
			/>
		</Paper>
	);
}

export default PatientsMetaDataList;
