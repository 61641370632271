import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { AppBar, Box, Menu, Link, Button, LinkProps, styled } from '@mui/material';

export const MobileTopAppBar = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backgroundColor: theme.palette.background.secondary,

	'& .MuiToolbar-root': {
		height: '110px',
		padding: '12px',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
}));

export const MobileBottomAppBar = styled(AppBar)(({ theme }) => ({
	top: 'auto',
	bottom: 0,
	background: theme.palette.background.default,
	boxShadow: '0px -4px 12px 0px rgba(117, 117, 117, 0.3)',
	'& .MuiToolbar-root': {
		color: theme.palette.text.secondary,
		textAlign: 'center',
		fontSize: '0.75rem',
		fontWeight: '700',
		lineHeight: '1rem',
		letterSpacing: '0.031rem',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		padding: '0px 8px 0px 8px',
		gap: '4px',
	},
	'& .MuiLink-root': {
		textDecoration: 'none',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		alignItems: 'center',
		color: theme.palette.text.primary,
		padding: '12px 0px 24px 0px',
		gap: '4px',
		'& .MuiBadge-anchorOriginTopRight': {
			transform: 'scale(1) translate(5%, -5%)',
		},
		'& .iconButton': {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '16px',
			padding: '6px 20px 6px 20px',
		},
		'&.active': {
			color: theme.palette.text.secondary,
			'& .iconButton': {
				background: theme.palette.grey[100],
				boxShadow: '0px 0px 4px 0px rgba(34, 34, 34, 0.25) inset',
			},
		},
	},
}));

export const DesktopAppBar = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	borderRadius: '0px 0px 20px 20px',
	'& .MuiToolbar-root': {
		gap: '24px',
		padding: '32px 24px 24px 24px',
	},
	'& .MainMenu': {
		background: theme.palette.grey[100],
		boxShadow: '0px 0px 8px 0px rgba(34, 34, 34, 0.15) inset',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: '50px',
		padding: '4px',
		gap: '10px',
		'& .MuiBadge-anchorOriginTopRight': {
			transform: 'scale(1) translate(5%, -5%)',
			lineHeight: '1rem',
			fontSize: '0.75rem',
			fontWeight: 700,
		},
		'& .MuiLink-root': {
			padding: '6px 22px 6px 22px',
			gap: '10px',
			borderRadius: '100px',
			color: theme.palette.grey[900],
			fontWeight: 700,
			fontSize: theme.typography.h1.fontSize,
			lineHeight: theme.typography.h1.lineHeight,
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			textDecoration: 'none',
			border: '2px solid transparent',
			'&.active': {
				borderColor: theme.palette.grey[900],
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '1rem',
				lineHeight: '1.5rem',
			},
		},
	},
}));

export const AccountMenu = styled(Menu)(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: '10px',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		boxShadow: '0px 0px 8px 0px rgba(97, 0, 138, 0.10), 0px 0px 1px 0px rgba(97, 0, 138, 0.05)',
	},
	'& .MuiMenuItem-root': {
		fontWeight: 700,
		justifyContent: 'flex-end',
		fontSize: '1rem',
		lineHeight: '1.5rem',
	},
	'& .MuiMenuItem-root.Eca-account-balance': {
		opacity: 100,
		color: theme.palette.primary.main,
	},
}));

export const MobileBackLink = styled(Link)<LinkProps<'a', RouterLinkProps>>(({ theme }) => ({
	display: 'flex',
	fontWeight: 'bold',
	textDecoration: 'none',
	color: theme.palette.secondary.main,
	'& .MuiSvgIcon-root': {
		fontSize: '0.875rem',
	},
}));

export const MobileLogo = styled(Link)<LinkProps<'a', RouterLinkProps>>({
	'& svg': {
		width: '222px',
		height: 'auto',
		display: 'block',
	},
});

export const MobileHelpLink = styled(Link)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontSize: '1.5rem',
}));

export const MobileToolbarSpacer = styled('div')<{ $showLogo?: boolean }>(({ $showLogo }) => ({
	minHeight: $showLogo ? '110px' : '76px',
}));

export const MobileMenuLink = styled(Link)<LinkProps<'a', RouterLinkProps>>(({ theme }) => ({
	textDecoration: 'none',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	color: theme.palette.text.primary,
	'&.active': {
		color: theme.palette.text.secondary,
		'& .iconButton': {
			background: theme.palette.grey[100],
			boxShadow: '0px 0px 4px 0px rgba(34, 34, 34, 0.25) inset',
		},
	},
}));

export const DesktopLogo = styled(Link)<LinkProps<'a', RouterLinkProps>>({
	'& svg': {
		width: '250px',
		height: 'auto',
		display: 'block',
	},
});

export const DesktopMenuButton = styled(Button)(({ theme }) => ({
	'&.MuiButton-text': {
		fontSize: '16px',
		textDecoration: 'none',
		textTransform: 'capitalize',
		color: theme.palette.text.secondary,
		':hover': {
			textDecoration: 'none',
			opacity: 0.8,
		},
	},
}));
