import React, { useMemo } from 'react';
import routes from 'routes';
import { Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import isActiveRoute from 'utils/isActiveRoute';
import PhoneForwardedIcon from 'components/common/icons/PhoneForwardedIcon';
import Pill2Icon from 'components/common/icons/Pill2Icon';

interface DefaultContentProps {
	children: React.ReactNode;
	anyOrderDelivered: boolean | null;
	currentOrderToday: boolean | null;
}

export function DefaultContent({ children, anyOrderDelivered, currentOrderToday }: DefaultContentProps) {
	const location = useLocation();

	const introText = useMemo(() => {
		if (!currentOrderToday && anyOrderDelivered) {
			return (
				<Stack gap="12px" pb="48px" alignItems="center" textAlign="center" justifyContent="center" height="100%">
					<PhoneForwardedIcon color="primary" sx={{ fontSize: '3.75rem' }} />
					<Typography variant="h1" fontWeight="bold" color="text.secondary">
						Please call us at 1-877-355-7225
					</Typography>
					<Typography variant="body1" color="text.secondary">
						We need you to call us before we can send your next order.
					</Typography>
				</Stack>
			);
		}

		if (!currentOrderToday && !anyOrderDelivered) {
			return (
				<Stack gap="12px" pb="48px" alignItems="center" textAlign="center" justifyContent="center" height="100%">
					<Pill2Icon color="primary" sx={{ fontSize: '3.75rem' }} />
					<Typography variant="h1" fontWeight={700} color="text.secondary">
						We are working on getting your prescriptions transferred to us.
					</Typography>
					<Typography variant="body2" color="text.secondary">
						You can begin using your medication schedule after we receive them. <br />
						This is where you can track and manage what medications you have taken.
					</Typography>
				</Stack>
			);
		}

		return (
			<Stack gap="12px" pb="48px" alignItems="center" textAlign="center" justifyContent="center" height="100%">
				<Typography variant="body2" color="text.secondary">
					Your medication schedule is based on feedback from your prescribers, pharmacists and you. Use this section to
					track if you have taken your medication. This does not replace consultation from your pharmacist or provider.
					Please call us if you have questions or concerns.
				</Typography>
			</Stack>
		);
	}, [anyOrderDelivered, currentOrderToday]);

	if (
		(!currentOrderToday &&
			anyOrderDelivered &&
			isActiveRoute(location.pathname, [
				routes.mySchedule.todaysMedication,
				routes.mySchedule.logAsNeededMed,
				routes.mySchedule.manageMedicationReminders,
			])) ||
		(!currentOrderToday && !anyOrderDelivered)
	) {
		return introText;
	}

	return children;
}
