import { Box, Theme, styled } from '@mui/material';
import { PaletteColor, PaletteOptions } from '@mui/material/styles/createPalette';

export const CalendarWeekDayStyled = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'today',
})<{
	color?: keyof PaletteOptions;
	today: boolean;
}>`
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme, color }: { theme: Theme; color?: keyof PaletteOptions }) => {
		if (color && theme.palette?.[color]) return (theme.palette[color] as PaletteColor)?.main;
		return 'transparent';
	}};
	color: ${({ theme, color, today }: { theme: Theme; color?: keyof PaletteOptions; today: boolean }) => {
		if (color && theme.palette?.[color]) return (theme.palette[color] as PaletteColor)?.contrastText;
		if (today) return theme.palette.text.secondary;
		return theme.palette.text.primary;
	}};
	font-weight: ${({ today }) => (today ? 700 : 400)};
`;
