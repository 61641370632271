import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SchedulerDayStyled = styled(Stack)(({ theme }) => ({
	padding: '12px',
	border: 0,
	margin: 0,
	width: '14.28%',
	overflow: 'hidden',
	minHeight: 120,
	justifyContent: 'space-between',
	borderRadius: '10px',
	height: '100%',
	boxShadow: '0px 0px 4px 0px rgba(158, 158, 158, 0.35)',
	[theme.breakpoints.down('sm')]: {
		boxShadow: 'none',
		padding: 0,
		minHeight: 'auto',
	},
}));
