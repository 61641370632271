import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
	getInboxNotifications,
	InboxNotificationListParams,
	InboxNotificationListResponse,
} from 'core/api/inboxNotification';
import extractQueryParam from 'utils/extractQueryParam';

interface InboxListParams extends InboxNotificationListParams {}

export function useInboxList({ page, ...restRequest }: InboxListParams) {
	const queryInfo = useQuery<InboxNotificationListResponse, Error>({
		queryKey: ['inbox', 'list', restRequest, page],
		queryFn: () => getInboxNotifications({ page, ...restRequest }),
		refetchInterval: 30000,
		refetchIntervalInBackground: true,
		enabled: true,
		refetchOnWindowFocus: true,
	});

	const lastPage = useMemo(() => {
		if (queryInfo.data?.view?.last) {
			return Number(extractQueryParam(queryInfo.data.view.last, 'page'));
		}
		return undefined;
	}, [queryInfo.data]);

	const listData = queryInfo.data;
	return {
		isLoading: queryInfo.isLoading,
		messages: listData?.member ?? [],
		totalMessages: listData?.totalItems ?? 0,
		lastPage,
	};
}
