import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ClockIcon } from 'components/common/icons/ClockIcon';

export default function NoTimeSelected() {
	return (
		<Stack gap="12px" pb="48px" alignItems="center" textAlign="center" justifyContent="center" height="100%">
			<ClockIcon sx={{ fontSize: '3.75rem' }} color="primary" />
			<Typography variant="h1" color="text.secondary" fontWeight="bold">
				Select a time of day to log your medication.
			</Typography>
			<Typography variant="body1" color="text.secondary">
				No time of day currently selected.
			</Typography>
		</Stack>
	);
}
