import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loginAsCareTeamMember } from 'core/api/admin/enterprise.api';
import { loginAsPatient } from 'core/api/admin/patients.api';
import { getLoginMethod, sendAuthCode, verifyAuthCode } from 'core/api/login/login.api';
import { LoginParams, TwoFactorAuthOptions } from 'core/api/login/login.models';
import { UserRole } from 'core/api/userAccount/userAccount.models';
import { IApiError } from 'core/errors/ApiError';
import useBearerToken from 'hooks/common/useBearerToken';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';

export function useAuth() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { updateToken, token: currentUserToken } = useBearerToken();

	const get2FAMethods = useMutation<
		TwoFactorAuthOptions,
		IApiError<Record<keyof LoginParams, string>>,
		LoginParams,
		unknown
	>({
		mutationKey: ['auth', 'twoFALogin', 'get2FAMethods'],
		mutationFn: getLoginMethod,
	});

	const sendAuthenticationCode = useMutation({
		mutationKey: ['auth', 'twoFALogin', 'sendAuthenticationCode'],
		mutationFn: sendAuthCode,
	});

	const getDefaultRouteForRoles = (roles: UserRole[]): string => {
		if (roles.some((role) => role === UserRole.ADMIN || role === UserRole.SUPERADMIN)) {
			return routes.admin.home;
		}

		if (
			roles.some((role) => role === UserRole.ENTERPRISE_CARE_TEAM_MEMBER || role === UserRole.ENTERPRISE_SUPERVISOR)
		) {
			return routes.enterprise.home;
		}

		if (roles.includes(UserRole.PATIENT)) {
			return routes.home;
		}

		return routes.errors.unauthorizedAccess;
	};

	const verifyAuthenticationCode = useMutation({
		mutationKey: ['auth', 'twoFALogin', 'verifyAuthenticationCode'],
		mutationFn: verifyAuthCode,
		onSuccess: ({ jwtToken, roles }) => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
			updateToken(jwtToken);
			navigate(getDefaultRouteForRoles(roles));
		},
	});

	const switchToPatient = useMutation({
		mutationFn: loginAsPatient,
		onSuccess: ({ userFullName, adminEmail, userToken }) => {
			const userData = {
				previewUserToken: currentUserToken,
				userFullName,
				adminEmail,
			};

			updateToken(userToken);
			window.localStorage.setItem('userData', JSON.stringify(userData));

			window.open(routes.home, '_blank');
		},
	});

	const switchToCareTeamMember = useMutation({
		mutationFn: loginAsCareTeamMember,
		onSuccess: ({ userFullName, adminEmail, userToken }) => {
			const userData = {
				previewUserToken: currentUserToken,
				userFullName,
				adminEmail,
			};

			updateToken(userToken);
			window.localStorage.setItem('userData', JSON.stringify(userData));

			window.open(routes.home, '_blank');
		},
	});

	const restoreToAdmin = () => {
		const userData = localStorage.getItem('userData');

		if (userData) {
			const { previewUserToken: token } = JSON.parse(userData);
			updateToken(token);
			window.localStorage.removeItem('userData');
			navigate(0);
		}
	};

	return {
		getDefaultRouteForRoles,
		userToken: currentUserToken,
		get2FAMethods,
		sendAuthenticationCode,
		verifyAuthenticationCode,
		switchToPatient,
		switchToCareTeamMember,
		restoreToAdmin,
	};
}

export default useAuth;
