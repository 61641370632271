import { useMutation, useQueryClient } from '@tanstack/react-query';
import { careTeamMemberAdd, careTeamMemberEdit } from 'core/api/enterprise/supervisor.api';
import { CareTeamMemberSaveParams } from 'core/api/enterprise/supervisor.models';

export function useCareTeamMemberSaveMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['enterprise', 'careTeamMember', 'save'],
		mutationFn: (params: CareTeamMemberSaveParams) =>
			'id' in params ? careTeamMemberEdit(params) : careTeamMemberAdd(params),
		onSuccess: (data, variables) => {
			if ('id' in variables) {
				queryClient.setQueryData(['enterprise', 'careTeamMember', 'view', variables.id], data);
			}
			queryClient.invalidateQueries({ queryKey: ['enterprise', 'careTeamMember', 'list'] });
		},
	});
}

export default useCareTeamMemberSaveMutation;
