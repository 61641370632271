import React from 'react';
import { Stack, SxProps, Theme } from '@mui/material';
import AnewHealthExactCareLightLogo from 'components/common/logo/AnewHealthExactCareLightLogo';

interface AuthSidebarProps {
	children: React.ReactNode;
	sx?: SxProps<Theme>;
}

export function AuthSidebar({ children, sx }: AuthSidebarProps) {
	return (
		<Stack
			direction="column"
			alignItems="left"
			justifyContent="space-between"
			flexGrow={1}
			padding={{ xs: '0.75rem', md: '4rem 3.5rem' }}
			maxWidth={{ sm: '100%', md: '35%' }}
			minHeight="650px"
			gap={2}
			sx={sx}
		>
			<AnewHealthExactCareLightLogo sx={{ width: '240px', height: 'auto' }} />
			{children}
		</Stack>
	);
}

export default AuthSidebar;
