import React, { useEffect } from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import Dialog from 'components/common/dialog';
import { useDisclosure } from 'hooks';

interface RequestRefillButtonProps extends Omit<ButtonProps, 'children'> {
	isSuccessMessage: boolean;
	isLoading: boolean;
	reset: () => void;
	onRequestRefill: () => void;
}

export function RequestRefillButton({
	onRequestRefill,
	isSuccessMessage,
	isLoading,
	reset,
	...buttonArgs
}: RequestRefillButtonProps) {
	const { isOpen: isOpenSuccessDialog, onOpen: onOpenSuccessDialog, onClose: onCloseSuccessDialog } = useDisclosure();

	useEffect(() => {
		if (isSuccessMessage) {
			onOpenSuccessDialog();
			reset();
		}
	}, [isSuccessMessage]);

	return (
		<>
			<Button disabled={isLoading} onClick={() => onRequestRefill()} {...buttonArgs}>
				{isLoading ? 'Loading...' : 'Request a Refill From ExactCare'}
			</Button>
			<Dialog maxWidth="xs" title="Thank You!" open={isOpenSuccessDialog} onClose={onCloseSuccessDialog}>
				<Typography component="p" mt={2} color={(theme) => theme.palette.success.main}>
					Request Sent
				</Typography>
				<Typography component="p" mt={2}>
					Your request for a refill has been sent to the ExactCare team. We will contact you if we have any questions.
				</Typography>
				<Typography component="p" mt={2}>
					Need this medication right away? Call us: 1-877-355-7225
				</Typography>
			</Dialog>
		</>
	);
}

export default RequestRefillButton;
