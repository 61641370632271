import { WalkThroughScreen, WalkThroughScreenKeys } from 'components/patient/common/walkThrough/WalkThrough';

export const accountWalkThrough: WalkThroughScreen = {
	screenKey: WalkThroughScreenKeys.ACCOUNT,
	steps: [
		{
			target: '.EcaListItem-personalInformation',
			title: 'Manage personal info like your phone number or address.',
			content: '',
			showProgress: true,
			disableBeacon: true,
		},
		{
			target: '.EcaListItem-notificationsPreferences',
			title: 'Choose how you want to receive notifications.',
			content: '',
			showProgress: true,
		},
		{
			target: '.EcaListItem-paymentsAndBilling',
			title: 'Enjoy easier online payments!',
			content: 'Make quick payments and securely save your info for future use.',
			disableOverlayClose: false,
		},
	],
};
