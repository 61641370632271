import React, { useEffect, createContext, useContext, useMemo, useState, useLayoutEffect } from 'react';
import { Typography, Stack, useMediaQuery, Link, StepLabel, Step, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useRegisterCheckStatusQuery, useRegisterHash } from 'hooks/auth';
import { ICheckStatusResponse } from 'core/api/registration/registration.models';
import routes from 'routes';
import { AuthSidebar, AuthMainContent } from 'components/auth';
import Suspense from 'components/common/Suspense';
import Stepper from './Stepper.styles';

enum RegistrationStatus {
	CreateUser = 0,
	RegisterPhoneNumber = 1,
	VerifyPhoneNumber = 2,
	Completed = 3,
}

export enum RegistrationSteps {
	VERIFY_INFO,
	CREATE_ACCOUNT,
	PHONE_NUMBER,
	VERIFY_CODE,
	FINISH,
}

export enum StepTitles {
	VERIFY_INFO = 'Verify Your Information',
	CREATE_ACCOUNT = 'Create Your Account',
	PHONE_NUMBER = 'Confirm Your Phone Number',
	VERIFY_CODE = 'Finish Signing Up',
}

type RegistrationStatusToStepMap = Record<RegistrationStatus, RegistrationSteps>;

const verifyIdentityStatusToStepMap: RegistrationStatusToStepMap = {
	[RegistrationStatus.CreateUser]: RegistrationSteps.CREATE_ACCOUNT,
	[RegistrationStatus.RegisterPhoneNumber]: RegistrationSteps.PHONE_NUMBER,
	[RegistrationStatus.VerifyPhoneNumber]: RegistrationSteps.PHONE_NUMBER,
	[RegistrationStatus.Completed]: RegistrationSteps.VERIFY_CODE,
} as const;

interface StepTextsProps {
	introText: string;
	extraIntroText: string;
}

interface RegisterContextValue {
	currentStep?: RegistrationSteps;
	setCurrentStep: (step: RegistrationSteps) => void;
	hash: string;
	setHash: (hash: string) => void;
	userToken: string | null;
	setUserToken: (token: string | null) => void;
	isLoading: boolean;
	initialData: ICheckStatusResponse | null;
	stepText: StepTextsProps;
	setStepText: (texts: StepTextsProps) => void;
}

const RegisterContext = createContext<RegisterContextValue | undefined>(undefined);

export const useRegister = () => {
	const context = useContext(RegisterContext);
	if (!context) {
		throw new Error('useRegister must be used within a RegisterProvider');
	}
	return context;
};

export function StepTexts(texts: StepTextsProps): null {
	const { setStepText } = useRegister();

	useLayoutEffect(() => {
		setStepText(texts);
	}, []);

	return null;
}

export function RegisterProvider({ children }: { children: React.ReactNode }): JSX.Element {
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
	const { hash, setHash } = useRegisterHash();
	const { data: initialData, isLoading, isError } = useRegisterCheckStatusQuery({ hash });
	const [currentStep, setCurrentStep] = useState<RegistrationSteps>();
	const [userToken, setUserToken] = useState<string | null>(null);
	const [stepText, setStepText] = useState<StepTextsProps>({
		introText: '',
		extraIntroText: '',
	});

	const getStepByStatus = (status: RegistrationStatus | null): RegistrationSteps => {
		return status !== null ? verifyIdentityStatusToStepMap[status] : RegistrationSteps.VERIFY_INFO;
	};

	useEffect(() => {
		if (!isLoading) {
			const stepIndex = getStepByStatus(initialData?.status ?? null);
			setCurrentStep(stepIndex);
		}
	}, [initialData?.status, isLoading]);

	useEffect(() => {
		if (isError) {
			setHash('');
			setCurrentStep(RegistrationSteps.VERIFY_INFO);
		}
	}, [isError, setHash]);

	const value = useMemo(
		() => ({
			currentStep,
			setCurrentStep,
			hash,
			setHash,
			userToken,
			setUserToken,
			isLoading,
			initialData: initialData || null,
			stepText,
			setStepText,
		}),
		[currentStep, hash, userToken, isLoading, initialData, stepText]
	);

	return (
		<RegisterContext.Provider value={value}>
			<>
				{upSmallScreen && (
					<AuthSidebar>
						<Suspense isLoading={isLoading || currentStep === undefined}>
							<Stack>
								<Typography
									component="h1"
									fontSize="2.313rem"
									lineHeight="2.5rem"
									fontWeight="bold"
									color="text.secondary"
								>
									{stepText.introText}
								</Typography>
								<Typography component="span" color="text.secondary" variant="small">
									{stepText.extraIntroText}
								</Typography>
							</Stack>

							<Stepper orientation="vertical" activeStep={currentStep} sx={{ padding: '3rem 0' }}>
								{Object.values(StepTitles).map((label) => (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								))}
							</Stepper>
						</Suspense>
					</AuthSidebar>
				)}

				<AuthMainContent>
					<Suspense isLoading={isLoading || currentStep === undefined}>
						{upSmallScreen ? (
							<>
								{currentStep === RegistrationSteps.VERIFY_INFO && (
									<Link component={RouterLink} to={routes.login} color="text.secondary" mb={5}>
										Return to Login
									</Link>
								)}
								<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="primary">
									Step {currentStep! + 1}
								</Typography>
							</>
						) : (
							<>
								<Typography component="h2" variant="h2" fontWeight="bold" color="text.secondary">
									{stepText.introText}
								</Typography>
								<Typography component="span" color="text.secondary" variant="small">
									{stepText.extraIntroText}
								</Typography>
								{!!currentStep && (
									<Typography mt={2} component="h2" variant="h4" fontWeight="bold" color="primary">
										Step {currentStep + 1} of {Object.values(StepTitles).length}
									</Typography>
								)}
							</>
						)}

						{children}
					</Suspense>
				</AuthMainContent>
			</>
		</RegisterContext.Provider>
	);
}

export default RegisterProvider;
