import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { InboxList, InboxNotificationDialog } from 'components/enterprise';
import { useDialog } from 'hooks';

function PatientsInboxPage() {
	const { patientId } = useParams();
	const [isOpenInboxNotificationDialog, onOpenInboxNotificationDialog, onCloseInboxNotificationDialog, notificationId] =
		useDialog<string>();

	return (
		<Grid item xs={12}>
			<InboxList patientId={patientId || ''} onClickNotification={onOpenInboxNotificationDialog} />
			<InboxNotificationDialog
				patientId={patientId || ''}
				notificationId={notificationId}
				open={isOpenInboxNotificationDialog}
				onClose={onCloseInboxNotificationDialog}
			/>
		</Grid>
	);
}

export default PatientsInboxPage;
