import React from 'react';
import { Chip, Stack } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { generatePath, Link as RouterLink, useLocation } from 'react-router-dom';
import { InboxNotification } from 'core/api/inboxNotification';
import relativeDateFormat from 'utils/relativeDateFormat';
import routes from 'routes';
import InboxMessageSummary from './InboxMessageSummary.styles';
import { MessageItemButton, MessageTime, NoMessages } from './InboxMessagesList.styles';

interface InboxMessagesListProps {
	readonly messages: InboxNotification[];
}

export function InboxMessagesList({ messages }: InboxMessagesListProps) {
	const location = useLocation();

	if (!messages.length) {
		return <NoMessages>You have no messages at this time.</NoMessages>;
	}

	return (
		<Stack>
			{messages.map(({ id, readAt, subject, preview, createdAt }) => (
				<MessageItemButton
					component={RouterLink}
					key={id}
					to={generatePath(routes.inbox.item, {
						messageId: id,
					})}
					state={{ backTo: location }}
				>
					<InboxMessageSummary primary={subject} secondary={preview} />
					<Stack gap={1} alignItems="flex-end">
						<MessageTime>
							{relativeDateFormat(createdAt)} <KeyboardArrowRightIcon />
						</MessageTime>
						{!readAt && <Chip label="New" color="primary" size="small" sx={{ px: '16px' }} />}
					</Stack>
				</MessageItemButton>
			))}
		</Stack>
	);
}

export default InboxMessagesList;
