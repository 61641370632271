import React from 'react';
import { ContentLayout, medicationsWalkThrough, WalkThrough } from 'components/patient/common';
import MedicationSidebar from './MedicationSidebar';

interface MedicationLayoutProps {
	children: React.ReactNode;
}

export function MedicationLayout({ children }: MedicationLayoutProps) {
	return (
		<ContentLayout sidebar={<MedicationSidebar />}>
			{children}
			<WalkThrough {...medicationsWalkThrough} />
		</ContentLayout>
	);
}

export default MedicationLayout;
