import React from 'react';
import { Outlet } from 'react-router-dom';
import GoogleAnalytics from 'components/common/GoogleAnalytics';
import AutoLogout from 'components/common/AutoLogout';
import Hotjar from 'components/common/Hotjar';
import RootProvider from 'contexts/RootProvider';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { MainThemeProvider } from 'themes/MainTheme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function App() {
	return (
		<MainThemeProvider>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<HelmetProvider>
					<SnackbarProvider autoHideDuration={2000}>
						<RootProvider>
							<AutoLogout>
								<GoogleAnalytics />
								<Outlet />
								<Hotjar />
							</AutoLogout>
						</RootProvider>
					</SnackbarProvider>
				</HelmetProvider>
			</LocalizationProvider>
		</MainThemeProvider>
	);
}

export default App;
