import React from 'react';
import { ContentLayout, homeWalkThrough, WalkThrough } from 'components/patient/common';
import HomeSidebar from './HomeSidebar';

interface HomeLayoutProps {
	children: React.ReactNode;
}

export function HomeLayout({ children }: HomeLayoutProps) {
	return (
		<ContentLayout sidebar={<HomeSidebar />}>
			{children}
			<WalkThrough {...homeWalkThrough} />
		</ContentLayout>
	);
}
