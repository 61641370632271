import { Box, styled, Theme } from '@mui/material';
import { PaletteColor, PaletteOptions } from '@mui/material/styles/createPalette';

export const Bullet = styled(Box)<{
	color?: keyof PaletteOptions;
}>`
	width: 14px;
	min-width: auto;
	height: 14px;
	border-radius: 50%;
	background-color: ${({ theme, color }: { theme: Theme; color?: keyof PaletteOptions; today?: boolean }) => {
		if (color && theme.palette?.[color]) return (theme.palette[color] as PaletteColor)?.main;
		return theme.palette.grey[200];
	}};
`;
