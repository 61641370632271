import React from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { useUserContext } from 'contexts/RootProvider';
import { useAccountEmailMutation } from 'hooks/common/useAccountEmailMutation';

export function ResendVerificationEmail() {
	const { email: currentEmail } = useUserContext();
	const { resendVerificationEmailMutation } = useAccountEmailMutation();

	return (
		<>
			{resendVerificationEmailMutation.isSuccess && (
				<Stack gap={2}>
					<Typography component="p" fontWeight={700} color="text.secondary">
						{currentEmail} <br />
						<Typography component="span" color="error" fontWeight={700}>
							Unverified
						</Typography>
					</Typography>
					<Typography component="p" color={(theme) => theme.palette.success.main}>
						A confirmation email was sent to the requested email address. Please follow the link in the email to
						complete the request.
					</Typography>
				</Stack>
			)}
			{!resendVerificationEmailMutation.isSuccess && (
				<Stack gap="12px">
					<Typography variant="h2" fontWeight={700} color="text.secondary">
						First, please verify your email address.
					</Typography>
					<Button
						onClick={() => resendVerificationEmailMutation.mutate()}
						variant="contained"
						color="primary"
						fullWidth
					>
						{resendVerificationEmailMutation.isPending ? 'loading...' : 'Resend Verification Email'}
					</Button>
				</Stack>
			)}
		</>
	);
}

export default ResendVerificationEmail;
