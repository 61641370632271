import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteUserMetaData } from 'core/api/admin/patients.api';
import { UserMetaDataParams } from 'core/api/admin/patients.models';
import { adminPatientQueries } from 'queries/admin';

export function useUserMetaDataList(props: UserMetaDataParams) {
	return useQuery(adminPatientQueries.userMetaDataList(props));
}

export function useDeleteUserMetaData() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteUserMetaData,
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'patients', 'view', variables.userId, 'userMetaData'],
			});
		},
	});
}
