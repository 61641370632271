import React from 'react';
import { useRegister, RegistrationSteps } from './RegisterProvider';
import { VerifyInfo, CreateAccount, PhoneNumber, VerifyCode, Success } from './steps';

export function StepContent() {
	const { currentStep } = useRegister();

	switch (currentStep) {
		case RegistrationSteps.VERIFY_INFO:
			return <VerifyInfo />;
		case RegistrationSteps.CREATE_ACCOUNT:
			return <CreateAccount />;
		case RegistrationSteps.PHONE_NUMBER:
			return <PhoneNumber />;
		case RegistrationSteps.VERIFY_CODE:
			return <VerifyCode />;
		case RegistrationSteps.FINISH:
			return <Success />;
		default:
			return null;
	}
}

export default StepContent;
