import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import routes from 'routes';
import { useAuth } from 'hooks';
import { Box } from '@mui/material';
import CircularLoading from 'components/common/CircularLoading';
import { UserRole } from 'core/api/userAccount/userAccount.models';
import { useUserContext } from 'contexts/RootProvider';

interface ProtectedRouteProps {
	children: React.ReactNode;
	roles?: UserRole[];
}

function ProtectedRoute({ children, roles = [] }: ProtectedRouteProps) {
	const { getDefaultRouteForRoles, userToken } = useAuth();
	const { roles: userRoles, isLoading } = useUserContext();
	const location = useLocation();

	if (!userToken) {
		return <Navigate to={routes.login} />;
	}

	if (!isLoading && !!userRoles?.length && !roles.some((role) => userRoles.includes(role))) {
		if (location.pathname === routes.home) {
			return <Navigate to={getDefaultRouteForRoles(userRoles)} />;
		}

		return <Navigate to={routes.errors.unauthorizedAccess} />;
	}

	if (isLoading) {
		return (
			<Box sx={{ height: '100vh' }}>
				<CircularLoading />
			</Box>
		);
	}

	return children;
}

export default ProtectedRoute;
