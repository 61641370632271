import React from 'react';
import { createBrowserRouter, generatePath, Outlet, redirect, RouterProvider } from 'react-router-dom';
import routes from 'routes';
import { ProtectedRoute, GuestRoute } from 'components/router';
import SignInPage from 'pages/auth/SignInPage';
import PersonalInformationPage from 'pages/patient/account/PersonalInformationPage';
import PaymentBillingPage from 'pages/patient/account/PaymentBillingPage';
import MedicationPage from 'pages/patient/MedicationPage';
import AdminPatientsPage from 'pages/admin/patients/PatientsPage';
import AdminsPage from 'pages/admin/AdminsPage';
import EnterpriseListPage from 'pages/admin/enterprise/EnterpriseListPage';
import EnterpriseCaregiverListPage from 'pages/admin/enterprise/EnterpriseCaregiverListPage';
import ManageMedicationRemindersPage from 'pages/patient/mySchedule/ManageMedicationRemindersPage';
import { ReviewMyHistoryPage } from 'pages/patient/mySchedule/ReviewMyHistoryPage';
import { LogAsNeededMedPage } from 'pages/patient/mySchedule/LogAsNeededMedPage';
import TodaysMedPage from 'pages/patient/mySchedule/TodaysMedPage';
import NotFoundPage from 'pages/errors/NotFoundPage';
import UnauthorizedAccessPage from 'pages/errors/UnauthorizedAccessPage';
import CareTeamMembersPage from 'pages/enterprise/CareTeamMembersPage';
import EnterprisePatientsPage from 'pages/enterprise/patients/PatientsPage';
import EnterprisePatientsProfilePage from 'pages/enterprise/patients/PatientsProfilePage';
import EnterprisePatientsMedicationsPage from 'pages/enterprise/patients/PatientsMedicationsPage';
import EnterprisePatientsDeliveriesPage from 'pages/enterprise/patients/PatientsDeliveriesPage';
import EnterprisePatientsInboxPage from 'pages/enterprise/patients/PatientsInboxPage';
import EnterprisePatientsMySchedulePage from 'pages/enterprise/patients/PatientsMySchedulePage';
import RegisterPage from 'pages/auth/RegisterPage';
import NotificationsPreferencesPage from 'pages/patient/account/NotificationsPreferencesPage';
import HomeLayout from 'components/patient/home/HomeLayout';
import InboxMessagesPage from 'pages/patient/inbox/InboxMessagesPage';
import InboxMessagePage from 'pages/patient/inbox/InboxMessagePage';
import AdminPatientsMedSyncPage from 'pages/admin/patients/PatientsMedSyncPage';
import AdminPatientsNotificationsPage from 'pages/admin/patients/PatientsNotificationsPage';
import AdminPatientsMetaDataPage from 'pages/admin/patients/PatientsMetaDataPage';
import AdminPatientsPushDevicesPage from 'pages/admin/patients/PatientsPushDevicesPage';
import App from 'App';
import { QueryClient } from '@tanstack/react-query';
import { PatientDetails } from 'core/api/admin/patients.models';
import EnterprisePatientLayout, {
	loader as enterprisePatientLoader,
} from 'components/enterprise/EnterprisePatientLayout/EnterprisePatientLayout';
import { PatientDetailsResponse } from 'core/api/enterprise/patient.models';
import AccountLayout from 'components/patient/account/AccountLayout';
import MyScheduleLayout from 'components/patient/mySchedule/MyScheduleLayout';
import AppLayout from 'components/patient/common/AppLayout';
import AdminLayout from 'components/admin/AdminLayout';
import EnterpriseLayout from 'components/enterprise/EnterpriseLayout';
import AuthLayout from 'components/auth/AuthLayout';
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage';
import ChangePasswordPage from 'pages/auth/ChangePasswordPage';
import SchedulerDayDetailsPage from 'pages/patient/mySchedule/SchedulerDayDetailsPage';
import DeliveryPage from 'pages/patient/DeliveryPage';
import DeliveriesLayout from 'components/patient/delivery/DeliveriesLayout';
import MedicationLayout from 'components/patient/medication/MedicationLayout';
import ChangeEmailConfirmPage from 'pages/auth/ChangeEmailConfirmPage';
import AdminPatientLayout, { loader as adminPatientLoader } from 'components/admin/AdminPatientLayout';
import { UserRole } from 'core/api/userAccount/userAccount.models';
import BlockedLoginsPage from 'pages/admin/BlockedLoginsPage';
import { ErrorBoundary } from 'components/errors/ErrorBoundary';

const queryClient = new QueryClient();

const router = createBrowserRouter([
	{
		element: <App />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				element: (
					<GuestRoute>
						<AuthLayout>
							<Outlet />
						</AuthLayout>
					</GuestRoute>
				),
				children: [
					{
						path: routes.login,
						element: <SignInPage />,
					},
					{
						path: routes.register,
						element: <RegisterPage />,
					},
					{
						path: routes.forgotPassword,
						element: <ForgotPasswordPage />,
					},
					{
						path: routes.changePassword,
						element: <ChangePasswordPage />,
					},
				],
			},
			{
				path: routes.changeEmailConfirm,
				element: <ChangeEmailConfirmPage />,
			},
			{
				element: (
					<ProtectedRoute roles={[UserRole.PATIENT]}>
						<AppLayout>
							<Outlet />
						</AppLayout>
					</ProtectedRoute>
				),
				children: [
					{
						path: routes.delivery,
						element: (
							<DeliveriesLayout>
								<DeliveryPage />
							</DeliveriesLayout>
						),
						handle: {
							back: () => ({
								name: 'Deliveries',
								to: generatePath(routes.delivery),
							}),
							showSidebarNavigation: true,
						},
					},
					{
						path: routes.medication,
						element: (
							<MedicationLayout>
								<MedicationPage />
							</MedicationLayout>
						),
						handle: {
							back: () => ({
								name: 'Medication',
								to: generatePath(routes.medication),
							}),
							showSidebarNavigation: true,
						},
					},
					{
						element: (
							<AccountLayout>
								<Outlet />
							</AccountLayout>
						),
						children: [
							{
								path: routes.account.personalInformation,
								element: <PersonalInformationPage />,
								handle: {
									back: () => ({
										name: 'Account',
										to: routes.account.personalInformation,
									}),
									showSidebarNavigation: true,
								},
							},
							{
								path: routes.account.paymentsAndBilling,
								element: <PaymentBillingPage />,
								handle: {
									back: () => ({
										name: 'Account',
										to: routes.account.personalInformation,
									}),
								},
							},
							{
								path: routes.account.notificationsPreferences,
								element: <NotificationsPreferencesPage />,
								handle: {
									back: () => ({
										name: 'Account',
										to: routes.account.personalInformation,
									}),
								},
							},
						],
					},
					{
						element: (
							<MyScheduleLayout>
								<Outlet />
							</MyScheduleLayout>
						),
						children: [
							{
								path: routes.mySchedule.todaysMedication,
								element: <TodaysMedPage />,
								handle: {
									back: () => ({
										name: 'My Schedule',
										to: routes.mySchedule.todaysMedication,
									}),
									showSidebarNavigation: true,
								},
							},
							{
								path: routes.mySchedule.logAsNeededMed,
								element: <LogAsNeededMedPage />,
								handle: {
									back: () => ({
										name: 'My Schedule',
										to: routes.mySchedule.todaysMedication,
									}),
								},
							},
						],
					},
					{
						element: (
							<HomeLayout>
								<Outlet />
							</HomeLayout>
						),
						children: [
							{
								path: routes.inbox.item,
								element: <InboxMessagePage />,
								handle: {
									back: () => ({
										name: 'Messages',
										to: generatePath(routes.inbox.list, { tab: 'all' }),
										state: { showSidebarNavigation: false },
									}),
									showSidebarNavigation: false,
								},
							},
							{
								index: true,
								path: routes.inbox.list,
								element: <InboxMessagesPage />,
								handle: {
									back: () => ({
										name: 'Home',
										to: routes.home,
									}),
									showSidebarNavigation: true,
								},
							},
							{
								path: routes.mySchedule.reviewMyHistory.calendar,
								element: (
									<>
										<ReviewMyHistoryPage />
										<Outlet />
									</>
								),
								handle: {
									back: () => ({
										name: 'My Schedule',
										to: routes.mySchedule.todaysMedication,
									}),
								},
								children: [
									{
										path: routes.mySchedule.reviewMyHistory.day,
										element: <SchedulerDayDetailsPage />,
									},
								],
							},
							{
								path: routes.mySchedule.manageMedicationReminders,
								element: <ManageMedicationRemindersPage />,
								handle: {
									back: () => ({
										name: 'My Schedule',
										to: routes.mySchedule.todaysMedication,
									}),
								},
							},
						],
					},
				],
			},
			{
				element: (
					<ProtectedRoute roles={[UserRole.ADMIN, UserRole.SUPERADMIN]}>
						<AdminLayout>
							<Outlet />
						</AdminLayout>
					</ProtectedRoute>
				),
				handle: {
					crumb: () => ({
						name: 'Home',
						to: routes.admin.home,
					}),
				},
				children: [
					{
						path: routes.admin.patients.list,
						handle: {
							crumb: () => ({
								name: 'Patients list',
								to: routes.admin.patients.list,
							}),
						},
						children: [
							{
								index: true,
								element: <AdminPatientsPage />,
							},
							{
								loader: adminPatientLoader(queryClient),
								element: (
									<AdminPatientLayout>
										<Outlet />
									</AdminPatientLayout>
								),
								handle: {
									crumb: ({ fullName, userId }: PatientDetails) => ({
										name: fullName ? `Patient ${fullName}` : `Patient #${userId}`,
									}),
								},
								children: [
									{
										path: routes.admin.patients.patient.medSync,
										element: <AdminPatientsMedSyncPage />,
										handle: {
											crumb: () => ({
												name: 'Med Sync',
											}),
										},
									},
									{
										path: routes.admin.patients.patient.notifications,
										element: <AdminPatientsNotificationsPage />,
										handle: {
											crumb: () => ({
												name: 'Notifications',
											}),
										},
									},
									{
										path: routes.admin.patients.patient.metaData,
										element: <AdminPatientsMetaDataPage />,
										handle: {
											crumb: () => ({
												name: 'Meta data',
											}),
										},
									},
									{
										path: routes.admin.patients.patient.pushDevices,
										element: <AdminPatientsPushDevicesPage />,
										handle: {
											crumb: () => ({
												name: 'Push devices',
											}),
										},
									},
								],
							},
						],
					},
					{
						path: routes.admin.admins.list,
						element: <AdminsPage />,
						handle: {
							crumb: () => ({
								name: 'Admins',
							}),
						},
					},
					{
						path: routes.admin.enterprise.list,
						handle: {
							crumb: () => ({
								name: 'Enterprises',
								to: routes.admin.enterprise.list,
							}),
						},
						children: [
							{
								index: true,
								element: <EnterpriseListPage />,
							},
							{
								path: routes.admin.enterprise.caregiver.list,
								element: <EnterpriseCaregiverListPage />,
								handle: {
									crumb: () => ({
										name: 'Caregivers list',
									}),
								},
							},
						],
					},
					{
						path: routes.admin.blockedLogins,
						element: <BlockedLoginsPage />,
						handle: {
							crumb: () => ({
								name: 'Blocked Logins',
							}),
						},
					},
				],
			},
			{
				element: (
					<ProtectedRoute roles={[UserRole.ENTERPRISE_CARE_TEAM_MEMBER, UserRole.ENTERPRISE_SUPERVISOR]}>
						<EnterpriseLayout>
							<Outlet />
						</EnterpriseLayout>
					</ProtectedRoute>
				),
				handle: {
					crumb: () => ({
						name: 'Home',
						to: routes.enterprise.home,
					}),
				},
				children: [
					{
						index: true,
						path: routes.enterprise.home,
						loader: async () => {
							return redirect(routes.enterprise.careTeamMembers);
						},
					},
					{
						path: routes.enterprise.careTeamMembers,
						element: <CareTeamMembersPage />,
						handle: {
							crumb: () => ({
								name: 'Care team members',
								to: routes.enterprise.careTeamMembers,
							}),
						},
					},
					{
						path: routes.enterprise.patients.list,
						handle: {
							crumb: () => ({
								name: 'Patients',
								to: routes.enterprise.patients.list,
							}),
						},
						children: [
							{
								index: true,
								element: <EnterprisePatientsPage />,
							},
							{
								loader: enterprisePatientLoader(queryClient),
								element: (
									<EnterprisePatientLayout>
										<Outlet />
									</EnterprisePatientLayout>
								),
								handle: {
									crumb: ({ patientProfile }: PatientDetailsResponse) => ({
										name: `Patient ${patientProfile?.fullName}`,
									}),
								},
								children: [
									{
										path: routes.enterprise.patients.patient.profile,
										element: <EnterprisePatientsProfilePage />,
										handle: {
											crumb: () => ({
												name: 'Profile',
											}),
										},
									},
									{
										path: routes.enterprise.patients.patient.medications,
										element: <EnterprisePatientsMedicationsPage />,
										handle: {
											crumb: () => ({
												name: 'Medications',
											}),
										},
									},
									{
										path: routes.enterprise.patients.patient.deliveries,
										element: <EnterprisePatientsDeliveriesPage />,
										handle: {
											crumb: () => ({
												name: 'Deliveries',
											}),
										},
									},
									{
										path: routes.enterprise.patients.patient.inbox,
										element: <EnterprisePatientsInboxPage />,
										handle: {
											crumb: () => ({
												name: 'Inbox',
											}),
										},
									},
									{
										path: routes.enterprise.patients.patient.mySchedule,
										element: <EnterprisePatientsMySchedulePage />,
										handle: {
											crumb: () => ({
												name: 'My Schedule',
											}),
										},
									},
								],
							},
						],
					},
				],
			},
			{
				path: routes.errors.unauthorizedAccess,
				element: <UnauthorizedAccessPage />,
			},
			{
				path: routes.errors.notFound,
				element: <NotFoundPage />,
			},
			{
				path: '*',
				element: <NotFoundPage />,
			},
		],
	},
]);

export default function Router() {
	return <RouterProvider router={router} />;
}
