import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { recordTaken } from 'core/api/mySchedule/mySchedule.api';
import { IScheduledMedsRequestParams } from 'core/api/mySchedule/mySchedule.models';
import { myScheduleQueries } from 'queries/patient/myScheduleQueries';

export function useScheduledMedsQuery(props: IScheduledMedsRequestParams) {
	return useQuery(myScheduleQueries.scheduledMedsList(props));
}

export function useTakeMedMutation() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['schedule', 'take'],
		mutationFn: recordTaken,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['schedule'] });
		},
	});
}

export default {
	useTakeMedMutation,
	useScheduledMedsQuery,
};
