import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteLoginAttempt } from 'core/api/admin/blockedLogins.api';
import { LoginAttemptCollectionRequest } from 'core/api/admin/blockedLogins.models';
import { loginAttemptQueries } from 'queries/admin';

export function useLoginAttemptQuery(props: LoginAttemptCollectionRequest) {
	return useQuery(loginAttemptQueries.loginAttemptList(props));
}

export function useLoginAttemptMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteLoginAttempt,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'loginAttempt', 'list'],
			});
		},
	});
}

export default {
	useLoginAttemptQuery,
	useLoginAttemptMutation,
};
