import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function MailIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 36 36" {...props}>
			<path
				d="M34.1 27.6C34.1 28.4 33.4 29.1 32.6 29.1H3.5C2.7 29.1 2 28.4 2 27.6V8C2 7.2 2.7 6.5 3.5 6.5H32.6C33.4 6.5 34.1 7.2 34.1 8V27.6Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M31.9 8.7L21.7 18.9C19.7 20.9 16.5 20.9 14.5 18.9L4.29999 8.7"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path d="M4.29999 26.9L12.3 18.9" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M31.9 26.9L23.9 18.9" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
		</SvgIcon>
	);
}
