import React from 'react';
import { ListItem, ListItemProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

interface SidebarListItemProps extends ListItemProps {
	component?: React.ElementType;
	to?: string;
	state?: any;
	selected?: boolean;
	disabled?: boolean;
	dense?: boolean;
}

const SidebarListItem = styled(ListItem)<SidebarListItemProps>(({ theme, selected, disabled }) => ({
	padding: '12px',
	gap: '8px',
	boxShadow: selected ? '0px 0px 12px 0px rgba(18, 18, 18, 0.1)' : '0px 0px 3px 0px rgba(18, 18, 18, 0.2)',
	border: '1px solid transparent',
	borderColor: selected ? theme.palette.grey[600] : 'transparent',
	borderRadius: '10px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	backgroundColor: 'transparent',
	cursor: disabled ? 'not-allowed' : 'pointer',
	color: disabled ? theme.palette.grey[500] : theme.palette.text.secondary,
	filter: disabled ? 'grayscale(100%)' : 'none',

	'&.Mui-selected': {
		backgroundColor: 'transparent',
	},

	'&.MuiListItem-dense': {
		backgroundColor: theme.palette.background.secondary,
	},

	[theme.breakpoints.down('sm')]: {
		backgroundColor: alpha(theme.palette.background.paper, 0.7),
	},

	'&:hover, &.active': {
		boxShadow: !disabled ? '0px 0px 12px 0px rgba(18, 18, 18, 0.1)' : '0px 0px 3px 0px rgba(18, 18, 18, 0.2)',
		borderColor: !disabled ? theme.palette.grey[600] : 'transparent',
	},
}));

export default SidebarListItem;
