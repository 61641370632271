import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { MedicationList, MedicationViewDialog } from 'components/enterprise';
import { PatientMedication } from 'core/api/enterprise/patient.models';
import { useDialog } from 'hooks';

function PatientsMedicationsPage() {
	const { patientId } = useParams();
	const [isOpenMedDialog, onOpenMedDialog, onCloseMedDialog, medicationData] = useDialog<PatientMedication>();

	return (
		<Grid item xs={12}>
			<MedicationList patientId={patientId || ''} onClickMedication={onOpenMedDialog} />
			<MedicationViewDialog
				patientId={patientId || ''}
				open={isOpenMedDialog}
				onClose={onCloseMedDialog}
				medicationData={medicationData}
			/>
		</Grid>
	);
}

export default PatientsMedicationsPage;
