import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import InfoCardWidget from 'components/common/InfoCardWidget';
import { isActiveRoute, camelize } from 'utils';
import { SidebarList, SidebarListItem } from 'components/common/sidebarList';

export interface MenuItem {
	label: string;
	to?: string;
	onClick?: () => void;
	state?: any;
	icon?: React.ReactNode;
	alert?: boolean;
	activeRoutes?: string[];
	disabled?: boolean;
	helperText?: React.ReactNode;
	disableArrow?: boolean;
	dense?: boolean;
}

interface MainSidebarMenuProps {
	menuItems: MenuItem[];
}

export function MainSidebarMenu({ menuItems }: MainSidebarMenuProps) {
	const location = useLocation();

	return (
		<SidebarList>
			{menuItems.map(
				({
					label: title,
					to,
					icon,
					activeRoutes,
					state,
					disabled,
					alert,
					helperText,
					disableArrow,
					dense,
					onClick,
				}) => (
					<SidebarListItem
						dense={dense}
						key={title}
						component={RouterLink}
						to={disabled || onClick ? '#' : to}
						state={state}
						onClick={onClick}
						className={`EcaListItem-${camelize(title)}`}
						{...{ disabled, selected: isActiveRoute(location.pathname, activeRoutes) }}
					>
						<InfoCardWidget {...{ alert, title, icon, disableArrow, dense }}>{helperText}</InfoCardWidget>
					</SidebarListItem>
				)
			)}
		</SidebarList>
	);
}
