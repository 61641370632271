import React from 'react';
import routes from 'routes';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { DashboardLayout } from 'components/common/dashboardLayout';
import LoggedInAsUserBar from 'components/common/LoggedInAsUserBar';
import { useAppContext } from 'contexts/RootProvider';

interface EnterpriseLayoutProps {
	children: React.ReactNode;
}

export function EnterpriseLayout({ children }: EnterpriseLayoutProps) {
	const { pageTitle } = useAppContext();
	const mainMenu = [
		{
			to: routes.enterprise.careTeamMembers,
			label: 'Care team',
			icon: <BusinessCenterOutlinedIcon />,
			activeRoutes: [routes.enterprise.careTeamMembers],
		},
		{
			to: routes.enterprise.patients.list,
			label: 'Patients',
			icon: <PeopleAltOutlinedIcon />,
			activeRoutes: [
				routes.enterprise.patients.list,
				routes.enterprise.patients.patient.profile,
				routes.enterprise.patients.patient.medications,
				routes.enterprise.patients.patient.deliveries,
				routes.enterprise.patients.patient.inbox,
				routes.enterprise.patients.patient.mySchedule,
			],
		},
	];

	return (
		<>
			<LoggedInAsUserBar />
			<DashboardLayout {...{ pageTitle, mainMenu }} className="EcaLayout-enterprise">
				{children}
			</DashboardLayout>
		</>
	);
}

export default EnterpriseLayout;
