import React from 'react';
import { Box, Typography } from '@mui/material';
import eventService from 'services/eventService';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { getRefillRequestFormPdf } from 'core/api/medications/medications.api';
import { IMedsGrouped, MedicationRefillAlert } from 'core/api/medications/medications.models';
import { PrescriberList, PrescriberListItem, PrescriberListItemName } from './RefillRequestForm.styles';

interface IRefillRequestFormProps {
	medsGroupedByAlerts: IMedsGrouped | null;
	onDownloadPdfError?: (error: string) => void;
}

interface IPrescriber {
	prescriberSfid: string | null;
	prescriberName: string | null;
}

export function RefillRequestForm({ medsGroupedByAlerts, onDownloadPdfError }: IRefillRequestFormProps) {
	const uniquePrescribersList: IPrescriber[] =
		(medsGroupedByAlerts?.[MedicationRefillAlert.NO_REFILLS] &&
			medsGroupedByAlerts[MedicationRefillAlert.NO_REFILLS].reduce((uniquePrescribers: IPrescriber[], med) => {
				if (med.prescriber && med.prescriberName) {
					const isPrescriberUnique = !uniquePrescribers.some(
						(uniquePrescriber) => uniquePrescriber.prescriberSfid === med.prescriber
					);
					if (isPrescriberUnique) {
						uniquePrescribers.push({ prescriberSfid: med.prescriber, prescriberName: med.prescriberName });
					}
				}
				return uniquePrescribers;
			}, [])) ||
		[];

	const handleDownloadPdf = getRefillRequestFormPdf;

	return (
		<Box>
			<Typography mb={2}>
				This will generate a PDF that you can save or print and share with your prescriber(s).
			</Typography>
			<Typography mb={2}>
				If more than one refill is needed from a prescriber, they will all be listed on the form.
			</Typography>
			<Typography component="h3" variant="h3" fontWeight="bold" mb={2}>
				Choose your prescriber(s)
			</Typography>
			<PrescriberList>
				{uniquePrescribersList.map(({ prescriberSfid, prescriberName }) => (
					<PrescriberListItem key={prescriberSfid}>
						<PrescriberListItemName>{prescriberName}</PrescriberListItemName>
						<DownloadPdfButton
							fetchPdfData={() => {
								eventService.emitEvent({ category: 'button', action: 'refillRequestFormDownloadPdf' });
								return handleDownloadPdf({ prescriberSfid: prescriberSfid || '' });
							}}
							onError={onDownloadPdfError}
							variant="outlined"
							color="primary"
							size="small"
							sx={{ fontSize: '14px' }}
						>
							Create Forms
						</DownloadPdfButton>
					</PrescriberListItem>
				))}
			</PrescriberList>
		</Box>
	);
}

export default RefillRequestForm;
