import { queryOptions } from '@tanstack/react-query';
import {
	getAsNeededMeds,
	getMedHistory,
	getScheduledMeds,
	getScheduledTimeReminderSettingsN,
} from 'core/api/mySchedule/mySchedule.api';
import {
	IAsNeededMedsParams,
	IScheduledMedsRequestParams,
	IScheduledMedsResponse,
	MedHistoryParams,
} from 'core/api/mySchedule/mySchedule.models';
import dayjs from 'dayjs';

export const myScheduleQueries = {
	mySchedule: queryOptions({ queryKey: ['mySchedule'] as const, queryFn: () => [] as const }),
	asNeededMedsList: ({ day }: IAsNeededMedsParams) =>
		queryOptions({
			queryKey: ['asNeededMed', 'list', day],
			queryFn: () => getAsNeededMeds({ day }),
		}),
	medHistory: (dateRange: MedHistoryParams) =>
		queryOptions({
			queryKey: ['schedule', 'history', dateRange],
			queryFn: () => getMedHistory(dateRange),
		}),
	scheduledMedsList: <TData = IScheduledMedsResponse>(
		{ day }: IScheduledMedsRequestParams,
		select?: (data: IScheduledMedsResponse) => TData
	) =>
		queryOptions({
			queryKey: ['schedule', 'list', day || dayjs().format('YYYY-MM-DD')],
			queryFn: () => getScheduledMeds({ day }),
			select,
		}),
	timeReminderSettings: () =>
		queryOptions({
			queryKey: ['schedule', 'timeReminders'],
			queryFn: () => getScheduledTimeReminderSettingsN(),
		}),
};
