import React from 'react';
import { List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import eventService from 'services/eventService';
import { IGetDeliveriesSummaryResponse, SFOrderStatus } from 'core/api/delivery/delivery.models';
import { getPdfDeliverySummary } from 'core/api/delivery/delivery.api';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { DeliveryMeds, DeliveryTrackingButton } from 'components/common/delivery';
import ContentCard, { CardTitle } from 'components/patient/common/ContentCard';

interface DeliverySummaryProps extends IGetDeliveriesSummaryResponse {
	deliveryDetailsMessageCard: React.ReactNode;
	onDownloadPdfError?: (error: string) => void;
}

export function DeliverySummary({
	sfid,
	orderName,
	orderNumber,
	orderStatus,
	orderStartDate,
	trackingButton,
	shipTo,
	recordType,
	notIncludedMedication,
	exactPackMedication,
	refrigerateMedication,
	additionalMedication,
	deliveryDetailsMessageCard,
	onDownloadPdfError,
}: DeliverySummaryProps) {
	const handleDownloadPdf = () => {
		eventService.emitEvent({ category: 'button', action: 'deliveryDownloadPdf' });

		return getPdfDeliverySummary({
			sfid: sfid || '',
		});
	};

	const titleEl = (
		<Stack gap="12px">
			<CardTitle variant="h2">{orderName}</CardTitle>
			<List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '6px', sm: '12px' } }}>
				<ListItem disablePadding>
					<ListItemText type="inline" variant="subtitle1" primary="Order:" secondary={orderNumber} />
				</ListItem>
				{orderStartDate && (
					<ListItem disablePadding>
						<ListItemText type="inline" variant="subtitle1" primary="Start Date:" secondary={orderStartDate} />
					</ListItem>
				)}
				{shipTo && (
					<ListItemText
						sx={{ display: { xs: 'inline-block', sm: 'none' } }}
						type="inline"
						variant="subtitle1"
						primary="Ship to:"
						secondary={shipTo}
					/>
				)}
			</List>
		</Stack>
	);

	const actionsEl = (
		<Stack sx={{ alignItems: { xs: 'stretch', sm: 'flex-end' } }} gap="12px">
			{shipTo && (
				<ListItemText
					sx={{ display: { xs: 'none', sm: 'inline-block' } }}
					type="inline"
					primary="Ship to:"
					primaryTypographyProps={{ fontWeight: 700 }}
					secondary={shipTo}
				/>
			)}
			{trackingButton && <DeliveryTrackingButton trackingButton={trackingButton} />}
			{[
				SFOrderStatus.DELIVERED,
				SFOrderStatus.IN_TRANSIT,
				SFOrderStatus.AWAITING_DRIVER,
				SFOrderStatus.RETURNED,
			].includes(orderStatus!) && (
				<DownloadPdfButton
					fetchPdfData={handleDownloadPdf}
					onError={onDownloadPdfError}
					variant="text"
					color="inherit"
					sx={{ '&.MuiButton-text': { width: { xs: '100%', sm: 'fit-content' } } }}
				>
					Download Delivery Summary
				</DownloadPdfButton>
			)}
		</Stack>
	);

	return (
		<ContentCard
			title={titleEl}
			disableTitleTypography
			actions={actionsEl}
			sx={{ '&>.MuiCardContent-root': { p: '0 0 24px 0' } }}
		>
			{deliveryDetailsMessageCard}

			{notIncludedMedication && notIncludedMedication.length > 0 && (
				<DeliveryMeds
					tableName="Medications Not Included"
					status="error"
					rows={notIncludedMedication}
					recordType={recordType}
				/>
			)}

			{exactPackMedication && exactPackMedication.length > 0 && (
				<DeliveryMeds tableName="Medications in the ExactPack" rows={exactPackMedication} recordType={recordType} />
			)}

			{refrigerateMedication && refrigerateMedication.length > 0 && (
				<DeliveryMeds
					tableName="Refrigerated Medication"
					rows={refrigerateMedication}
					recordType={recordType}
					showRefrigerateAlert
				/>
			)}

			{additionalMedication && additionalMedication.length > 0 && (
				<DeliveryMeds tableName="Additional Medications" rows={additionalMedication} recordType={recordType} />
			)}

			<Typography component="p" variant="small" sx={{ margin: { xs: '12px', sm: '0' } }}>
				Our pharmacists are available for you and your family. If you have any questions about your prescriptions and
				would like to speak to an ExactCare pharmacist, please call 1-877-355-7225.
			</Typography>
		</ContentCard>
	);
}

export default DeliverySummary;
