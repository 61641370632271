import { endpoints } from 'core/api/endpoints';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'core/api/api-service';
import { IPatientsList } from 'core/models/admin/patients.models';
import {
	IDeletePatientRequest,
	IGetPatientsListResponse,
	IPatientsListRequest,
	IUnblockPatientRequest,
	ISuspendPatientRequest,
	IChangeEmailPatientRequest,
	IChangeDateOfBirthPatientRequest,
	IChangePasswordPatientRequest,
	IChangeBypassAuthCodeRequest,
	PatientNotificationsResponse,
	PatientNotificationsParams,
	PatientDetailsParams,
	PatientDetails,
	MedsProcessingJobParams,
	MedsProcessingJobsResponse,
	CreateMedsProcessingJobsParams,
	CreateMedsProcessingJobsResponse,
	UserLoginAsParams,
	UserLoginAs,
	UserMetaDataParams,
	UserMetaDataResponse,
	DeleteUserMetaDataParams,
	PatientPushDevicesParams,
	PatientPushDevicesResponse,
} from './patients.models';

function mapPatientsList(result: IGetPatientsListResponse): IPatientsList {
	const patients = result.member.map((patient) => {
		return {
			userId: patient.userId,
			fullName: patient.fullName,
			dateOfBirth: patient.dateOfBirth,
			email: patient.email,
			phone: patient.phone,
			createdAt: patient.createdAt,
			status: patient.status,
		};
	});

	return {
		patients,
		total: result.totalItems,
	};
}

export async function getPatientsList(request: IPatientsListRequest): Promise<IPatientsList> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		search: request?.search || '',
		dob: request?.dob || '',
		status: request?.status || '',
	};

	const result = await getRequest<IGetPatientsListResponse>(endpoints.admin.patients.list, body, {});

	return mapPatientsList(result);
}

export async function getPatientsListN(request: IPatientsListRequest): Promise<IGetPatientsListResponse> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		search: request?.search || '',
		dob: request?.dob || '',
		status: request?.status || '',
	};

	return getRequest<IGetPatientsListResponse>(endpoints.admin.patients.list, body, {});
}

export async function deletePatient(request: IDeletePatientRequest): Promise<void> {
	await deleteRequest<void>(endpoints.admin.patients.delete(request.userId), {}, {});
}

export async function unblockPatient(request: IUnblockPatientRequest): Promise<void> {
	await patchRequest<void>(endpoints.admin.patients.unblock(request.userId), {}, {});
}

export async function suspendPatient(request: ISuspendPatientRequest): Promise<void> {
	const body = {
		suspend: request.suspend,
	};

	await patchRequest<void>(endpoints.admin.patients.suspend(request.userId), body, {});
}

export async function changeEmail(request: IChangeEmailPatientRequest): Promise<void> {
	const body = {
		email: request.email,
	};

	await patchRequest<void>(endpoints.admin.patients.changeEmail(request.userId), body, {});
}

export async function changeDateOfBirth(request: IChangeDateOfBirthPatientRequest): Promise<void> {
	const body = {
		dateOfBirth: request.dateOfBirth,
	};

	await patchRequest<void>(endpoints.admin.patients.changeDateOfBirth(request.userId), body, {});
}

export async function changePassword(request: IChangePasswordPatientRequest): Promise<void> {
	const body = {
		newPassword: request.newPassword,
	};

	await patchRequest<void>(endpoints.admin.patients.changePassword(request.userId), body, {});
}

export async function changeBypassAuthCode(request: IChangeBypassAuthCodeRequest): Promise<void> {
	const body = {
		bypassAuthCode: request.bypassAuthCode,
	};

	await patchRequest<void>(endpoints.admin.patients.changeBypassAuthCode(request.userId), body, {});
}

export async function loginAsPatient(request: UserLoginAsParams): Promise<UserLoginAs> {
	return getRequest<UserLoginAs>(endpoints.admin.patients.loginAs(request.id), {}, {});
}

export async function patientDetails(request: PatientDetailsParams): Promise<PatientDetails> {
	return getRequest<PatientDetails>(endpoints.admin.patients.details(request.id), {}, {});
}

export async function patientNotificationList(
	request: PatientNotificationsParams
): Promise<PatientNotificationsResponse> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		order_by: request?.orderBy || 'id',
		order_direction: request?.orderDirection || 'DESC',
	};

	const id = request?.patientId as string;

	return getRequest<PatientNotificationsResponse>(endpoints.admin.patients.notifications(id), body, {});
}

export async function medsProcessingJobs(request: MedsProcessingJobParams): Promise<MedsProcessingJobsResponse> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		order_by: request?.orderBy || 'id',
		order_direction: request?.orderDirection || 'DESC',
	};

	const id = request?.patientId as string;

	return getRequest<MedsProcessingJobsResponse>(endpoints.admin.patients.medsProcessingJobs(id), body, {});
}

export function createMedsProcessingJobs({
	patientId,
	...request
}: CreateMedsProcessingJobsParams): Promise<CreateMedsProcessingJobsResponse> {
	const body = {
		from: request.from,
		to: request.to,
	};
	return postRequest<CreateMedsProcessingJobsResponse>(
		endpoints.admin.patients.createMedsProcessingJobs(patientId),
		body,
		{}
	);
}

export async function userMetaDataList(request: UserMetaDataParams): Promise<UserMetaDataResponse> {
	const body = {
		key: request?.key || '',
	};

	const id = request?.userId as string;

	return getRequest<UserMetaDataResponse>(endpoints.admin.patients.userMetaDataList(id), body, {});
}

export async function deleteUserMetaData(request: DeleteUserMetaDataParams): Promise<void> {
	await deleteRequest<void>(endpoints.admin.patients.deleteUserMetaData(request.userId, request.id), {}, {});
}

export async function patientPushDeviceList(request: PatientPushDevicesParams): Promise<PatientPushDevicesResponse> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		order_by: request?.orderBy || 'id',
		order_direction: request?.orderDirection || 'DESC',
	};

	const id = request?.patientId as string;

	return getRequest<PatientPushDevicesResponse>(endpoints.admin.patients.pushDevices(id), body, {});
}
