import React from 'react';
import ContentCard from 'components/patient/common/ContentCard';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { getRefillRequestFormPdf } from 'core/api/medications/medications.api';
import { IGetMedicationSummaryResponse, MedicationAction } from 'core/api/medications/medications.models';
import { MedicationDetails, RequestRefillButton } from 'components/patient/medication';

interface IMedicationSummaryProps {
	medicationSummaryData: IGetMedicationSummaryResponse;
	onRequestRefillFromExactCare: () => void;
	resetRequestRefillFromExactCare: () => void;
	requestRefillFromExactCareIsSuccess: boolean;
	requestRefillFromExactCareIsLoading: boolean;
}

export function MedicationSummary({
	medicationSummaryData,
	requestRefillFromExactCareIsSuccess,
	requestRefillFromExactCareIsLoading,
	onRequestRefillFromExactCare,
	resetRequestRefillFromExactCare,
}: IMedicationSummaryProps) {
	const contentCardActions = ({ sfid, actions, prescriber }: IGetMedicationSummaryResponse) => {
		if (sfid && actions?.includes(MedicationAction.REQUEST_REFILL_FROM_ECP)) {
			return (
				<RequestRefillButton
					isSuccessMessage={requestRefillFromExactCareIsSuccess}
					isLoading={requestRefillFromExactCareIsLoading}
					onRequestRefill={onRequestRefillFromExactCare}
					reset={resetRequestRefillFromExactCare}
					variant="contained"
					color="primary"
				/>
			);
		}

		if (sfid && prescriber && actions?.includes(MedicationAction.GET_PRESCRIBER_REFILL_REQUEST_FORM)) {
			return (
				<DownloadPdfButton
					fetchPdfData={() =>
						getRefillRequestFormPdf({
							prescriberSfid: prescriber || '',
							currentMedicationSfid: sfid || undefined,
						})
					}
					variant="contained"
					color="primary"
					sx={{ fontSize: '12px' }}
				>
					Download Refill Request Form
				</DownloadPdfButton>
			);
		}
		return null;
	};

	return (
		<ContentCard
			title={medicationSummaryData?.name}
			actions={medicationSummaryData ? contentCardActions(medicationSummaryData) : null}
			sx={{ '& .MuiCardContent-root': { background: (theme) => theme.palette.background.default } }}
		>
			<MedicationDetails {...medicationSummaryData} />
		</ContentCard>
	);
}

export default MedicationSummary;
