import { useQuery } from '@tanstack/react-query';
import { Badges } from 'core/api/badges/badges.models';
import getBadgesQuery from 'queries/patient/getBadgesQuery';
import { useMemo } from 'react';

export interface BadgeResults {
	isLoading: boolean;
	homeButtonBadge: number;
	medicationButtonBadge: boolean;
	unreadMessagesBadge: number;
	refillAlertsBadge: number;
}

export function useBadges(): BadgeResults {
	const { isLoading, data = {} as Badges } = useQuery(getBadgesQuery());
	const { unreadMessages = 0, refillAlerts = 0, onboardingAlerts = false } = data;

	const badgeResults = useMemo(
		() => ({
			isLoading,
			homeButtonBadge: unreadMessages,
			medicationButtonBadge: Boolean(refillAlerts || onboardingAlerts),
			unreadMessagesBadge: unreadMessages,
			refillAlertsBadge: refillAlerts,
		}),
		[isLoading, unreadMessages, refillAlerts, onboardingAlerts]
	);

	return badgeResults;
}

export default useBadges;
