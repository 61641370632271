import { Paper, Stack, Typography, styled } from '@mui/material';

export const ScheduleActions = styled(Stack)(() => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	alignItems: 'center',
	gap: '20px',
}));

export const ScheduleDetails = styled(Stack)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	gap: '6px',

	'& .MuiButton-root': {
		color: theme.palette.grey[600],
	},
}));

export const ScheduleTime = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.body1.fontSize,
	lineHeight: theme.typography.body1.lineHeight,
	display: 'inline-flex',
	alignItems: 'center',
	gap: '5px',
}));

export const ScheduleTitle = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.h2.fontSize,
	lineHeight: theme.typography.h2.lineHeight,
	fontWeight: 600,
}));

export const ScheduleItem = styled(Paper)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: '24px',
	padding: '24px',
	boxShadow: 'none',
	borderRadius: '20px',
	background: theme.palette.background.default,

	[theme.breakpoints.down('sm')]: {
		background: 'transparent',
		padding: 0,
		borderRadius: 0,
	},
}));

export const ScheduleList = styled(Stack)(() => ({
	spacing: 2,
	flexDirection: 'column',
}));
