import React, { useEffect, useState } from 'react';
import { Button, Typography, Stack } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { useQueryClient } from '@tanstack/react-query';
import { useChangePasswordMutation } from 'hooks';
import { AuthLayout, AuthSidebar } from 'components/auth';
import ImageCover from 'components/common/ImageCover';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';
import { IChangePasswordRequest } from 'core/api/userAccount/userAccount.models';
import setFormErrors from 'utils/setFormErrors';
import initQuery from 'queries/initQuery';

const REDIRECT_COUNTDOWN_INITIAL = 10;

const schema = Joi.object({
	password: Joi.string().required().messages({
		'string.empty': 'Current password cannot be empty',
		'any.required': 'Current password is required',
	}),
	newPassword: buildPasswordSchema(),
	repeatNewPassword: Joi.string().required().valid(Joi.ref('newPassword')).messages({
		'string.empty': 'Repeat new password cannot be empty',
		'any.required': 'Repeat new password is required',
		'any.only': 'New password and repeat new password must match',
	}),
});

interface ExpiredPasswordForm extends IChangePasswordRequest {
	repeatNewPassword: string;
}

function ExpiredPasswordScreen() {
	const [redirectCountDown, setRedirectCountDown] = useState(REDIRECT_COUNTDOWN_INITIAL);
	const queryClient = useQueryClient();
	const {
		control,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<ExpiredPasswordForm>({ mode: 'onChange', resolver: joiResolver(schema) });
	const { mutate: changePasswordMutate, isPending, isSuccess } = useChangePasswordMutation();

	const handleSubmitExpiredPasswordForm = (formData: IChangePasswordRequest) =>
		changePasswordMutate(formData, {
			onError: (error) => setFormErrors<IChangePasswordRequest>(error, setError),
		});

	useEffect(() => {
		if (isSuccess) {
			if (redirectCountDown > 0) {
				const timer = setTimeout(() => setRedirectCountDown(redirectCountDown - 1), 1000);
				return () => clearTimeout(timer);
			}

			queryClient.invalidateQueries({ queryKey: initQuery().queryKey });
		}

		return () => {};
	}, [redirectCountDown, isSuccess]);

	return (
		<AuthLayout>
			<AuthSidebar>
				{isSuccess && (
					<>
						<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="success.main">
							Thank you for updating your password.
						</Typography>
						<Typography variant="body1" color="text.secondary">
							{redirectCountDown > 0
								? `You will be redirected to your ExactCare dashboard in ${redirectCountDown} seconds.`
								: `Redirecting now...`}
						</Typography>
					</>
				)}
				{!isSuccess && (
					<>
						<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
							Password Expired
						</Typography>
						<Typography component="span" variant="body1" sx={{ color: 'text.secondary' }}>
							ExactCare security guidelines require you to change your password every 12 months.
						</Typography>
						<Typography component="strong" variant="body1" sx={{ color: 'text.secondary' }}>
							Please change your password to continue.
						</Typography>

						<Stack gap="12px" component="form" onSubmit={handleSubmit(handleSubmitExpiredPasswordForm)}>
							<Controller
								name="password"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<PasswordField
										{...field}
										error={!!error}
										helperText={error?.message}
										value={field.value ?? ''}
										label="Current password"
										autoComplete="current-password"
										variant="standard"
										size="small"
										margin="normal"
										fullWidth
										disableTooltip
									/>
								)}
							/>
							<Controller
								name="newPassword"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<PasswordField
										{...field}
										error={!!error}
										helperText={error?.message}
										value={field.value ?? ''}
										label="Enter New password"
										autoComplete="new-password"
										variant="standard"
										size="small"
										margin="normal"
										fullWidth
									/>
								)}
							/>
							<Controller
								name="repeatNewPassword"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<PasswordField
										{...field}
										error={!!error}
										helperText={error?.message}
										value={field.value ?? ''}
										label="Repeat New Password"
										autoComplete="new-password"
										variant="standard"
										size="small"
										margin="normal"
										fullWidth
										disableTooltip
									/>
								)}
							/>

							<FormErrorMessages errors={errors} name="root" />
							<Button disabled={isPending} variant="contained" color="primary" fullWidth type="submit">
								{isPending ? 'Loading...' : 'Save'}
							</Button>
						</Stack>
					</>
				)}
			</AuthSidebar>
			<ImageCover
				src="/images/auth-seniors.png"
				sx={{
					display: { sm: 'none', md: 'flex' },
					flexGrow: 2,
				}}
			/>
		</AuthLayout>
	);
}

export default ExpiredPasswordScreen;
