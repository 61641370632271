import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { localStorageService } from 'services/storageService';

export function useRegisterHash() {
	const [hash, setHash] = useState<string>(() => localStorageService.getItem('register') ?? '');
	const params = useParams<{ hash?: string }>();

	useEffect(() => {
		if (hash && hash.length > 0) {
			localStorageService.setItem('register', hash);
		} else {
			localStorageService.removeItem('register');
		}
	}, [hash]);

	useEffect(() => {
		const subscription = localStorageService.watch<string>('register').subscribe((value) => {
			if (value !== hash) {
				setHash(value ?? '');
			}
		});

		return () => subscription.unsubscribe();
	}, [hash]);

	useEffect(() => {
		if (params?.hash) {
			setHash(params.hash);
		}
	}, [params?.hash]);

	return { hash, setHash };
}

export default useRegisterHash;
