import React, { useMemo } from 'react';
import Dialog, { IDialogProps } from 'components/common/dialog';
import { Stack, Typography } from '@mui/material';
import { usePatientInboxNotificationQuery } from 'hooks/enterprise';
import Markdown from 'components/common/Markdown/Markdown';
import { InboxStatus } from 'core/api/inboxNotification';
import StatusChip, { StatusChipLegend } from 'components/common/StatusChip';
import relativeDateFormat from 'utils/relativeDateFormat';
import Suspense from 'components/common/Suspense';

interface InboxNotificationDialogProps extends Omit<IDialogProps, 'children'> {
	notificationId: string;
	patientId: string;
}

export function InboxNotificationDialog({
	notificationId,
	patientId,
	...restDialogProps
}: InboxNotificationDialogProps) {
	const { data, isLoading } = usePatientInboxNotificationQuery({
		patientId,
		notificationId,
	});
	const { subject, body, createdAt, statusId } = data || {};
	const markdownContent = `${body}`.trim();

	const statusLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: InboxStatus.Inbox,
				label: 'Inbox',
				color: 'primary',
			},
			{
				id: InboxStatus.Deleted,
				label: 'Deleted',
				color: 'error',
			},
		],
		[]
	);

	return (
		<Dialog scroll="paper" fullWidth maxWidth="md" title={subject} {...restDialogProps}>
			<Suspense isLoading={isLoading}>
				<Stack direction="row" alignItems="center" gap={1}>
					<Typography variant="caption">{relativeDateFormat(createdAt || '')}</Typography>
					{statusId != null && <StatusChip legend={statusLegend} statusId={statusId} variant="outlined" size="small" />}
				</Stack>
				<Markdown>{markdownContent}</Markdown>
			</Suspense>
		</Dialog>
	);
}

export default InboxNotificationDialog;
