import React, { useCallback, useEffect, useMemo } from 'react';
import routes from 'routes';
import { Link, Typography } from '@mui/material';
import useDownloadPdf from 'hooks/common/useDownloadPdf';
import { getPdfScheduledMeds } from 'core/api/mySchedule/mySchedule.api';
import MedScheduleListByDay from 'components/common/mySchedule/MedScheduleListByDay';
import Suspense from 'components/common/Suspense';
import { IScheduledMedDetails, ScheduledMedStatus } from 'core/api/mySchedule/mySchedule.models';
import { useNavigate, generatePath, useParams, useLocation } from 'react-router-dom';
import { SUPPORT_URLS } from 'constants/urls';
import {
	MainSidebarMenu,
	ContentCard,
	useMyScheduleTodaysMedicationWalkThrough,
	WalkThrough,
} from 'components/patient/common';
import { useScheduledMedsQuery, useTakeMedMutation } from 'hooks/patient/useMySchedule';
import isActiveRoute from 'utils/isActiveRoute';

interface MyScheduleSidebarProps {
	anyOrderDelivered: boolean | null;
	currentOrderToday: boolean | null;
}

export function MyScheduleSidebar({ anyOrderDelivered, currentOrderToday }: MyScheduleSidebarProps) {
	const { id } = useParams<{ id?: string }>();
	const navigate = useNavigate();
	const location = useLocation();

	const { data: scheduledMedsData, isLoading: isLoadingScheduledMedsData } = useScheduledMedsQuery({});
	const { mutate: onLogChange, isPending } = useTakeMedMutation();

	const handleShowScheduleDetails = (scheduleDetails: IScheduledMedDetails) =>
		navigate(
			generatePath(routes.mySchedule.todaysMedication, {
				id: scheduleDetails.id,
			})
		);

	const isSkipped = useMemo(
		() =>
			(scheduledMedsData?.member ?? []).filter((med: IScheduledMedDetails) => med.status === ScheduledMedStatus.Passed)
				.length > 0,
		[scheduledMedsData]
	);

	const firstActiveScheduledMed = useMemo(
		() =>
			(scheduledMedsData?.member ?? []).find((med: IScheduledMedDetails) => med.status === ScheduledMedStatus.Active),
		[scheduledMedsData]
	);

	const isActive = useMemo(() => !!firstActiveScheduledMed, [firstActiveScheduledMed]);
	const walkThroughProps = useCallback(useMyScheduleTodaysMedicationWalkThrough, []);

	const { isLoading: isLoadingPdf, downloadPdfAction } = useDownloadPdf({
		fetchPdfData: () => getPdfScheduledMeds({}),
	});

	const handleDownloadPdf = () => {
		downloadPdfAction();
	};

	useEffect(() => {
		if (isActiveRoute(location.pathname, ['/today']) && firstActiveScheduledMed) {
			navigate(
				generatePath(routes.mySchedule.todaysMedication, {
					id: firstActiveScheduledMed.id,
				})
			);
		}
	}, [firstActiveScheduledMed, location, navigate]);

	const menuItems = useMemo(
		() =>
			[
				{
					label: 'Log As-Needed Medication',
					dense: true,
					to: routes.mySchedule.logAsNeededMed,
					activeRoutes: [routes.mySchedule.logAsNeededMed],
					disabled: !currentOrderToday,
				},
				{
					label: 'Manage My History',
					dense: true,
					to: routes.mySchedule.reviewMyHistory.calendar,
					activeRoutes: [routes.mySchedule.reviewMyHistory.calendar, routes.mySchedule.reviewMyHistory.day],
					disabled: !anyOrderDelivered,
				},
				{
					label: isLoadingPdf ? 'Loading...' : "Print Today's Schedule",
					disabled: isLoadingPdf || !currentOrderToday,
					dense: true,
					onClick: isLoadingPdf || !currentOrderToday ? undefined : handleDownloadPdf,
				},
			].filter(Boolean),
		[currentOrderToday, anyOrderDelivered, isLoadingPdf, handleDownloadPdf]
	);

	return (
		<ContentCard title="Today's Medication">
			<Suspense isLoading={isLoadingScheduledMedsData}>
				<MedScheduleListByDay
					data={scheduledMedsData}
					onShowScheduleDetails={handleShowScheduleDetails}
					hideActionButtons
					activeId={Number(id) || null}
					{...{ onLogChange, isPending }}
				/>
				<WalkThrough {...walkThroughProps({ isSkipped, isActive })} />
			</Suspense>
			<MainSidebarMenu menuItems={menuItems} />
			<Typography variant="body1" color="text.secondary">
				Your medication schedule is based on feedback from your prescribers, pharmacists, and you. This does not replace
				consultation from your pharmacist or provider. Call if you have questions for our pharmacists.
			</Typography>
			<Typography variant="body1" color="text.secondary">
				<Typography variant="body1" fontWeight={700} component="b">
					Here are some helpful tips:{' '}
				</Typography>
				<Link href={SUPPORT_URLS.ASK_A_PHARMACIST} target="_blank" color="text.secondary">
					What should I do if I forget to take my medications?
				</Link>
			</Typography>
		</ContentCard>
	);
}

export default MyScheduleSidebar;
