import { LocalStorageService } from 'services/storageService';

export type Token = string | null;

class BearerTokenService extends LocalStorageService {
	private static instance: BearerTokenService;

	private readonly BEARER_KEY = 'bearer';

	public static getInstance(): BearerTokenService {
		if (!BearerTokenService.instance) {
			BearerTokenService.instance = new BearerTokenService();
		}
		return BearerTokenService.instance;
	}

	public getToken(): Token {
		return this.getItem<string>(this.BEARER_KEY);
	}

	public updateToken(newToken: Token): void {
		if (newToken) {
			this.setItem(this.BEARER_KEY, newToken);
		} else {
			this.removeItem(this.BEARER_KEY);
		}
	}

	public watchToken() {
		return this.watch<string>(this.BEARER_KEY);
	}
}

export const bearerTokenService = BearerTokenService.getInstance();
export default bearerTokenService;
