import React from 'react';
import ContentLayout from 'components/patient/common/ContentLayout';
import { useAppContext } from 'contexts/RootProvider';
import MyScheduleSidebar from './MyScheduleSidebar';
import DefaultContent from '../DefaultContent';

interface MyScheduleLayoutProps {
	children: React.ReactNode;
}

export function MyScheduleLayout({ children }: MyScheduleLayoutProps) {
	const { anyOrderDelivered, currentOrderToday } = useAppContext();

	return (
		<ContentLayout sidebar={<MyScheduleSidebar {...{ anyOrderDelivered, currentOrderToday }} />}>
			<DefaultContent {...{ anyOrderDelivered, currentOrderToday }}>{children}</DefaultContent>
		</ContentLayout>
	);
}

export default MyScheduleLayout;
