import { useState } from 'react';

interface UseDisclosureProps {
	isOpen?: boolean;
}

export function useDisclosure(props: UseDisclosureProps = {}) {
	const { isOpen: defaultIsOpen = false } = props;

	const [isOpen, setIsOpen] = useState(defaultIsOpen);

	const onToggle = () => {
		setIsOpen(!isOpen);
	};

	const onOpen = () => {
		setIsOpen(true);
	};

	const onClose = () => {
		setIsOpen(false);
	};

	return { isOpen, onClose, onOpen, onToggle };
}

export default useDisclosure;
