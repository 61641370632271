import React from 'react';
import {
	Box,
	styled,
	AccordionProps,
	AccordionSummaryProps,
	Accordion as MuiAccordion,
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export const Medication = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	width: '100%',
	gap: 24,
}));

export const MedicationItem = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	gap: 24,

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
	},
}));

export const MedicationItemImage = styled(Box)(() => ({
	'.imagePreview': {
		height: 'fit-content',
		maxWidth: 150,
		borderRadius: 5,
		overflow: 'hidden',
		display: 'flex',
		'& img': {
			minWidth: '100%',
			minHeight: '100%',
			objectFit: 'cover',
		},
	},
}));

export const MedicationItemContent = styled(Box)(() => ({
	'& .MuiList-root': {
		padding: 0,
	},

	'& .MuiListItem-root': {
		padding: 0,
	},

	'& .MuiListItemText-root': {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		gap: 3,
		margin: 0,
	},

	'& .MuiListItemText-primary': {
		fontStyle: 'normal',
		fontWeight: '700',
		letterSpacing: 0.5,
	},

	'& .MuiListItemText-secondary': {
		fontStyle: 'normal',
		fontWeight: '400',
		letterSpacing: 0.5,
	},
}));

export const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
	'.MuiAccordion-root,.Mui-expanded,.MuiAccordionDetails-root': {
		border: 'none',
	},
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	backgroundColor: 'transparent',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
