import React, { useEffect } from 'react';
import { Button, Link, Typography, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Link as RouterLink, useParams } from 'react-router-dom';
import routes from 'routes';
import { useResetPasswordMutation } from 'hooks/auth';
import { AuthSidebar } from 'components/auth';
import ImageCover from 'components/common/ImageCover';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { IChangePasswordRequest } from 'core/api/resetPassword/resetPassword.models';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';
import { setFormErrors } from 'utils/setFormErrors';

const schema = Joi.object({
	hash: Joi.string().required(),
	password: buildPasswordSchema(),
	confirmPassword: Joi.string().valid(Joi.ref('password')).required().messages({
		'any.only': 'Passwords do not match',
	}),
});

interface FormFields extends IChangePasswordRequest {
	confirmPassword: string;
}

export default function ChangePasswordPage() {
	const { mutate, isPending, isSuccess } = useResetPasswordMutation();
	const {
		setValue,
		control,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<FormFields>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			password: '',
			confirmPassword: '',
			hash: '',
		},
	});
	const { hash } = useParams();

	const handleResetPassword = (data: FormFields) =>
		mutate(
			{
				hash: data.hash,
				password: data.password,
			},
			{
				onError: (error) => setFormErrors<IChangePasswordRequest>(error, setError),
			}
		);

	useEffect(() => {
		if (hash) {
			setValue('hash', hash);
		}
	}, [hash]);

	return (
		<>
			<AuthSidebar>
				{!isSuccess && (
					<>
						<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
							Change Your Password
						</Typography>
						<Stack gap="12px" component="form" onSubmit={handleSubmit(handleResetPassword)}>
							<Controller name="hash" control={control} render={({ field }) => <input type="hidden" {...field} />} />
							<Controller
								name="password"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<PasswordField
										{...field}
										error={!!error}
										helperText={error?.message}
										value={field.value ?? ''}
										label="Create Your New Password"
										autoComplete="new-password"
										variant="standard"
										size="small"
										margin="normal"
										fullWidth
									/>
								)}
							/>
							<Controller
								name="confirmPassword"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<PasswordField
										{...field}
										error={!!error}
										helperText={error?.message}
										value={field.value ?? ''}
										label="Enter Your New Password Again"
										autoComplete="new-password"
										variant="standard"
										size="small"
										margin="normal"
										fullWidth
										disableTooltip
									/>
								)}
							/>
							<FormErrorMessages errors={errors} name="root" />
							<Button disabled={isPending} type="submit" fullWidth variant="contained" color="primary">
								{isPending ? 'Save...' : 'Save'}
							</Button>
						</Stack>
					</>
				)}
				{isSuccess && (
					<>
						<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
							Your Password Has Been Updated
						</Typography>
						<Link component={RouterLink} to={routes.login} color="text.secondary" variant="body1">
							Return to Login
						</Link>
					</>
				)}
			</AuthSidebar>
			<ImageCover
				src="/images/auth-seniors.png"
				sx={{
					display: { sm: 'none', md: 'flex' },
					flexGrow: 2,
				}}
			/>
		</>
	);
}
