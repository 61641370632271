import { styled, ListItem, ListItemButton, Stack } from '@mui/material';

export const NoMessages = styled(ListItem)(({ theme }) => ({
	alignItems: 'stretch',
	gap: '15px',
	borderTop: '1px solid',
	borderColor: theme.palette.divider,
}));

export const MessageItemButton = styled(ListItemButton)<{ component: React.ElementType; to?: string; state?: any }>(
	({ theme }) => ({
		alignItems: 'stretch',
		gap: '15px',
		padding: '24px 16px',
		borderTop: '1px solid',
		borderColor: theme.palette.divider,
	})
);

export const MessageTime = styled(Stack)(({ theme }) => ({
	color: theme.palette.grey[600],
	fontWeight: 400,
	textAlign: 'left',
	justifyContent: 'center',
	flexDirection: 'row',
	alignItems: 'center',
	fontSize: '1rem',
	lineHeight: '1.5rem',
	[theme.breakpoints.down('sm')]: {
		fontSize: '0.875rem',
		lineHeight: '1rem',
	},
}));
