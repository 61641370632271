import React from 'react';
import { ContentLayout, accountWalkThrough, WalkThrough } from 'components/patient/common';
import AccountSidebar from './AccountSidebar';

interface AccountLayoutProps {
	children: React.ReactNode;
}

export function AccountLayout({ children }: AccountLayoutProps) {
	return (
		<ContentLayout sidebar={<AccountSidebar />}>
			{children}
			<WalkThrough {...accountWalkThrough} />
		</ContentLayout>
	);
}
