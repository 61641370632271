import React, { useCallback, useMemo, useState, useRef } from 'react';
import routes from 'routes';
import { Grid } from '@mui/material';
import {
	GridColDef,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import { PaperTitle, Paper } from 'components/common/dashboardLayout';
import { FiltersBar, SearchFilter } from 'components/common/inputs/filters';
import DataGrid from 'components/common/dataGrid';
import { generatePath, useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import ConfirmationDialog from 'components/common/confirmationDialog';
import { useEnterpriseListQuery, useEnterpriseMutation } from 'hooks/admin';
import useDialog from 'hooks/common/useDialog';
import { AppProvider } from 'contexts/RootProvider';

function EnterpriseListPage() {
	const navigate = useNavigate();
	const [name, setName] = useState<string | undefined>(undefined);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const { data, isLoading } = useEnterpriseListQuery({
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
		name,
	});
	const { member: enterprisesData, totalItems: enterprisesTotal } = data ?? {};

	const rowCountRef = useRef(enterprisesTotal || 0);
	const rowCount = useMemo(() => {
		if (enterprisesTotal !== undefined) {
			rowCountRef.current = enterprisesTotal;
		}
		return rowCountRef.current;
	}, [enterprisesTotal]);

	const { deleteEnterprise, updateEnterprise } = useEnterpriseMutation();

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const [isOpenDeleteConfirmationDialog, openDeleteConfirmationDialog, closeDeleteConfirmationDialog, userIdToDelete] =
		useDialog<number>();

	const handleDeleteEnterprise = useCallback(() => {
		if (userIdToDelete) {
			deleteEnterprise.mutate({ id: userIdToDelete.toString() }, { onSuccess: () => closeDeleteConfirmationDialog() });
		}
	}, [deleteEnterprise, userIdToDelete]);

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const handleEditClick = useCallback(
		(id: GridRowId) => {
			setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
		},
		[rowModesModel]
	);

	const handleSaveClick = useCallback(
		(id: GridRowId) => {
			setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
		},
		[rowModesModel]
	);

	const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
		if (event && params.reason === GridRowEditStopReasons.rowFocusOut) {
			// eslint-disable-next-line no-param-reassign
			event.defaultMuiPrevented = true;
		}
	}, []);

	const handleProcessRowUpdate = useCallback((newRow: GridRowModel, originalRow: GridRowModel) => {
		if (newRow?.id && newRow?.name && newRow?.name !== originalRow?.name) {
			updateEnterprise.mutate({ id: newRow.id, name: newRow.name });
		}

		return newRow;
	}, []);

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'id', headerName: 'ID', sortable: false, flex: 1 },
			{
				field: 'sfid',
				headerName: 'SFID',
				sortable: false,
				flex: 2,
			},
			{
				field: 'name',
				headerName: 'Name',
				sortable: false,
				flex: 4,
				editable: true,
			},
			{
				flex: 1,
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				cellClassName: 'actions',
				getActions: ({ id, row }) => {
					const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

					return [
						<GridActionsCellItem
							key="caregivers"
							icon={<PeopleIcon />}
							label="Caregivers"
							title="Caregivers"
							className="textPrimary"
							color="inherit"
							onClick={() =>
								navigate(
									generatePath(routes.admin.enterprise.caregiver.list, {
										enterpriseId: id,
									})
								)
							}
						/>,
						<GridActionsCellItem
							key={isInEditMode ? 'save' : 'edit'}
							data-testid={isInEditMode ? 'save-button' : 'edit-button'}
							icon={isInEditMode ? <SaveIcon /> : <EditIcon />}
							label={isInEditMode ? 'Save' : 'Edit'}
							title={isInEditMode ? 'Save' : 'Edit'}
							className="textPrimary"
							color="inherit"
							sx={
								isInEditMode
									? {
											color: 'primary.main',
										}
									: undefined
							}
							onClick={() => (isInEditMode ? handleSaveClick(id) : handleEditClick(id))}
						/>,
						<GridActionsCellItem
							key="delete"
							icon={<DeleteIcon />}
							label="Delete"
							title="Delete"
							color="inherit"
							onClick={() => openDeleteConfirmationDialog(row)}
						/>,
					];
				},
			},
		],
		[handleSaveClick, handleEditClick, rowModesModel]
	);

	const handleClearFilters = useCallback(() => {
		setName(undefined);
	}, []);

	return (
		<Grid item xs={12}>
			<AppProvider.PageTitle>Enterprises</AppProvider.PageTitle>
			<Paper>
				<PaperTitle>Enterprises list</PaperTitle>
				<FiltersBar onClearFilters={handleClearFilters} buttonProps={{ variant: 'outlined' }}>
					<SearchFilter
						sx={{ flex: 4 }}
						name="search"
						label="Search enterprise"
						value={name}
						onChange={(event) => setName(event.target.value)}
						variant="outlined"
						size="small"
					/>
				</FiltersBar>
				<DataGrid
					autoHeight
					rows={enterprisesData || []}
					columns={columns}
					loading={isLoading}
					rowCount={rowCount}
					pageSizeOptions={[5, 10, 25]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowModesModelChange={handleRowModesModelChange}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={handleProcessRowUpdate}
					disableRowSelectionOnClick
					disableColumnSelector
					disableColumnMenu
					disableColumnFilter
				/>
				<ConfirmationDialog
					isLoading={deleteEnterprise.isPending}
					title="Delete Enterprise"
					content="Are you sure you want to delete this enterprise?"
					open={isOpenDeleteConfirmationDialog}
					onConfirm={handleDeleteEnterprise}
					onCancel={closeDeleteConfirmationDialog}
				/>
			</Paper>
		</Grid>
	);
}

export default EnterpriseListPage;
