import React, { createContext, useContext, useMemo, useState, useLayoutEffect } from 'react';
import { BadgeResults, useBadges } from 'hooks/patient/useBadges';
import { Init } from 'core/api/init/init.models';
import { useQuery } from '@tanstack/react-query';
import initQuery from 'queries/initQuery';
import { Helmet } from 'react-helmet-async';

export interface IAppContext extends Pick<Init, 'anyOrderDelivered' | 'currentOrderToday' | 'homepage'> {
	badges: Exclude<BadgeResults, 'isLoading'>;
	isLoading: boolean;
	pageTitle: string;
	setPageTitle: (title: string | undefined) => void;
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const useAppContext = (): IAppContext => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('useAppContext must be used within a AppProvider');
	}
	return context;
};

interface PageTitleProps {
	children: string;
}

export function PageTitle({ children }: PageTitleProps): null {
	const { setPageTitle } = useAppContext();

	useLayoutEffect(() => {
		setPageTitle(children);
		return () => setPageTitle(undefined);
	}, [children, setPageTitle]);

	return null;
}

interface AppProviderProps {
	children: React.ReactNode;
	defaultTitle?: string;
}

export function AppProvider({ children, defaultTitle = 'ExactCare' }: AppProviderProps) {
	const [pageTitle, setPageTitle] = useState<string>(defaultTitle);
	const { isLoading: isLoadingBadges, ...badges } = useBadges();
	const { data: initData, isLoading: isLoadingAccount } = useQuery(initQuery());
	const { anyOrderDelivered, currentOrderToday, homepage } = initData || {};

	const value = useMemo(
		() => ({
			badges,
			anyOrderDelivered,
			currentOrderToday,
			homepage,
			isLoading: isLoadingAccount && isLoadingBadges,
			pageTitle,
			setPageTitle,
		}),
		[badges, anyOrderDelivered, currentOrderToday, homepage, isLoadingAccount, isLoadingBadges, pageTitle, setPageTitle]
	) as IAppContext;

	return (
		<AppContext.Provider value={value}>
			<Helmet>
				<title>{pageTitle || defaultTitle}</title>
			</Helmet>
			{children}
		</AppContext.Provider>
	);
}

AppProvider.PageTitle = PageTitle;
