import { getRequest, patchRequest } from '../api-service';
import { endpoints } from '../endpoints';
import {
	InboxNotification,
	InboxNotificationListResponse,
	InboxNotificationListParams,
	InboxNotificationParams,
	UpdateInboxNotificationParams,
} from './inboxNotification.models';

export async function getInboxNotifications({
	exists,
	itemsPerPage = 6,
	...request
}: InboxNotificationListParams): Promise<InboxNotificationListResponse> {
	const body = {
		...request,
		itemsPerPage,
		'exists[readAt]': exists?.readAt,
	};

	return getRequest<InboxNotificationListResponse>(endpoints.inboxNotifications.list, body);
}

export function getInboxNotification(request: InboxNotificationParams): Promise<InboxNotification> {
	return getRequest<InboxNotification>(endpoints.inboxNotifications.view(request.id));
}

export function updateInboxNotification({
	id,
	...restRequest
}: UpdateInboxNotificationParams): Promise<InboxNotification> {
	const body = {
		...restRequest,
	};

	return patchRequest<InboxNotification>(endpoints.inboxNotifications.update(id), body, {});
}
