import React from 'react';
import { Stack, Button } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { usePatientMutations } from 'hooks/admin';
import setFormErrors from 'utils/setFormErrors';
import { PatientChangePasswordFormFields } from 'core/api/admin/patients.models';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';

const schema = Joi.object({
	newPassword: buildPasswordSchema(),
	repeatNewPassword: Joi.string().required().valid(Joi.ref('newPassword')).messages({
		'string.empty': 'Repeat new password cannot be empty',
		'any.required': 'Repeat new password is required',
		'any.only': 'New password and repeat new password must match',
	}),
});

type PatientChangePasswordForm = PatientChangePasswordFormFields;

interface PatientChangePasswordProps {
	userId: string;
	onSuccess: () => void;
}

export function PatientChangePassword({ userId, onSuccess }: PatientChangePasswordProps) {
	const { changePatientPasswordMutation } = usePatientMutations();

	const {
		control,
		handleSubmit,
		formState: { isValid, errors },
		setError,
	} = useForm<PatientChangePasswordForm>({
		mode: 'onChange',
		resolver: joiResolver(schema),
	});

	const handleSubmitChangePassword = (data: PatientChangePasswordForm) => {
		changePatientPasswordMutation.mutate(
			{ userId, newPassword: data.newPassword },
			{
				onSuccess,
				onError: (error) => setFormErrors<PatientChangePasswordFormFields>(error, setError),
			}
		);
	};

	return (
		<Stack gap={2} mt={2} component="form" onSubmit={handleSubmit(handleSubmitChangePassword)}>
			<FormErrorMessages errors={errors} name="root" />
			<Controller
				name="newPassword"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<PasswordField
						{...field}
						value={field.value || ''}
						label="New password"
						variant="outlined"
						size="small"
						fullWidth
						helperText={error?.message}
						error={!!error}
					/>
				)}
			/>
			<Controller
				name="repeatNewPassword"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<PasswordField
						{...field}
						value={field.value || ''}
						label="Repeat new password"
						variant="outlined"
						size="small"
						fullWidth
						helperText={error?.message}
						error={!!error}
						disableTooltip
					/>
				)}
			/>
			<Button
				disabled={!isValid || changePatientPasswordMutation.isPending}
				variant="outlined"
				color="primary"
				fullWidth
				type="submit"
			>
				{changePatientPasswordMutation.isPending ? 'Loading...' : 'Save'}
			</Button>
		</Stack>
	);
}

export default PatientChangePassword;
