import { useState, useEffect, useCallback } from 'react';
import bearerTokenService, { Token } from 'services/bearerTokenService';

export const useBearerToken = () => {
	const [token, setToken] = useState<Token>(() => bearerTokenService.getToken());

	useEffect(() => {
		const subscription = bearerTokenService.watchToken().subscribe(setToken);
		return () => subscription.unsubscribe();
	}, []);

	const updateToken = useCallback((newToken: Token): void => {
		bearerTokenService.updateToken(newToken);
	}, []);

	return {
		token,
		updateToken,
	} as const;
};

export default useBearerToken;
