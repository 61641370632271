import React, { useState } from 'react';
import {
	TextField,
	Alert,
	Button,
	Checkbox,
	Typography,
	FormControlLabel,
	Stack,
	useMediaQuery,
	Theme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { IAddressParts } from 'core/api/userAccount/userAccount.models';
import { useAccountProfile, useUserAccountMutations } from 'hooks/patient/useUserAccount';

const addressField = (fieldName: string) =>
	Joi.string()
		.required()
		.messages({
			'string.empty': `${fieldName} is required.`,
			'any.required': `${fieldName} is required.`,
		});

const stateCodeField = addressField('State code').min(2).max(2).messages({
	'string.min': 'State code must be 2 characters.',
	'string.max': 'State code must be 2 characters.',
});

const postalCodeField = addressField('Postal code').min(5).max(9).messages({
	'string.min': 'Postal code must be at least 5 characters.',
	'string.max': 'Postal code must be no more than 9 characters.',
});

const schema = Joi.object({
	shippingStreet: addressField('Shipping street'),
	shippingCity: addressField('Shipping city'),
	shippingStateCode: stateCodeField,
	shippingPostalCode: postalCodeField,
	billingStreet: addressField('Billing street'),
	billingCity: addressField('Billing city'),
	billingStateCode: stateCodeField,
	billingPostalCode: postalCodeField,
});

interface ChangeAddressFormProps extends IAddressParts {}

interface ChangeAddressProps {
	onSuccess?: () => void;
}

export function ChangeAddress({ onSuccess }: ChangeAddressProps) {
	const { data: accountProfile } = useAccountProfile();
	const { changeAddressMutation } = useUserAccountMutations();
	const {
		mutate: changeAddressMutate,
		isPending: isPendingChangeAddress,
		isSuccess,
		error: changeAddressError,
	} = changeAddressMutation;
	const { changeAddress: currentAddress, requestedBillingAddress, requestedShippingAddress } = accountProfile || {};

	const {
		control,
		handleSubmit,
		formState: { isValid },
		setValue,
		getValues,
	} = useForm<ChangeAddressFormProps>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			shippingStreet: currentAddress?.shippingStreet || '',
			shippingCity: currentAddress?.shippingCity || '',
			shippingStateCode: currentAddress?.shippingStateCode || '',
			shippingPostalCode: currentAddress?.shippingPostalCode || '',
			billingStreet: currentAddress?.billingStreet || '',
			billingCity: currentAddress?.billingCity || '',
			billingStateCode: currentAddress?.billingStateCode || '',
			billingPostalCode: currentAddress?.billingPostalCode || '',
		},
	});
	const [checked, setChecked] = useState(false);

	const handleSubmitChangeAddressForm = (data: ChangeAddressFormProps) => changeAddressMutate(data, { onSuccess });

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		if (e.target.checked) {
			// Set billing fields
			setValue('billingStreet', getValues('shippingStreet'));
			setValue('billingCity', getValues('shippingCity'));
			setValue('billingStateCode', getValues('shippingStateCode'));
			setValue('billingPostalCode', getValues('shippingPostalCode'));
		} else {
			// Clear billing fields
			setValue('billingStreet', currentAddress?.billingStreet || '');
			setValue('billingCity', currentAddress?.billingCity || '');
			setValue('billingStateCode', currentAddress?.billingStateCode || '');
			setValue('billingPostalCode', currentAddress?.billingPostalCode || '');
		}
	};

	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	return (
		<>
			{isSuccess && (
				<Stack gap={2}>
					<Typography component="p" color="text.secondary">
						<b>Your shipping address is:</b>
						<br /> {requestedShippingAddress}
					</Typography>
					<Typography component="p" color="text.secondary">
						<b>Your billing address is:</b>
						<br /> {requestedBillingAddress}
					</Typography>
					<Typography component="p" color={(theme) => theme.palette.success.main}>
						Thank you for updating your address. This will take a few days in our system. Call us if it needs to be
						updated sooner: 1-877-355-7225
					</Typography>
				</Stack>
			)}
			{!isSuccess && (
				<Stack component="form" onSubmit={handleSubmit(handleSubmitChangeAddressForm)}>
					{changeAddressError?.message && (
						<Alert severity="error" sx={{ mb: 2 }}>
							{changeAddressError.message}
						</Alert>
					)}
					<Stack gap="20px" direction={upSmallScreen ? 'row' : 'column'} justifyContent="space-between">
						<Stack direction="column" gap="20px" flex="1">
							<Typography component="h1" variant="h1" fontWeight="bold" color="text.secondary">
								Shipping Address
							</Typography>
							<Typography component="p">Your medication will be shipped to this address.</Typography>
							<Controller
								name="shippingStreet"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										value={field.value || ''}
										label="Street"
										helperText={error?.message}
										error={!!error}
										variant="standard"
										size="small"
										fullWidth
									/>
								)}
							/>
							<Controller
								name="shippingCity"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										value={field.value || ''}
										label="City"
										error={!!error}
										helperText={error?.message}
										variant="standard"
										size="small"
										fullWidth
									/>
								)}
							/>
							<Stack gap="20px" direction="row">
								<Controller
									name="shippingStateCode"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											value={field.value || ''}
											label="State"
											error={!!error}
											helperText={error?.message}
											variant="standard"
											size="small"
											fullWidth
										/>
									)}
								/>
								<Controller
									name="shippingPostalCode"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											value={field.value || ''}
											label="ZIP Code"
											error={!!error}
											helperText={error?.message}
											variant="standard"
											size="small"
											fullWidth
										/>
									)}
								/>
							</Stack>

							<FormControlLabel
								control={<Checkbox checked={checked} onChange={handleCheckboxChange} name="billingSameAsShipping" />}
								label="My billing address is the same as my shipping address"
							/>
						</Stack>
						<Stack direction="column" gap="20px" flex="1">
							<Typography component="h1" variant="h1" fontWeight="bold" color="text.secondary">
								Billing Address
							</Typography>

							<Typography component="p">Your monthly statement will be mailed to this address.</Typography>

							<Controller
								name="billingStreet"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										value={field.value || ''}
										label="Street"
										error={!!error}
										helperText={error?.message}
										variant="standard"
										size="small"
										fullWidth
									/>
								)}
							/>

							<Controller
								name="billingCity"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										value={field.value || ''}
										label="City"
										error={!!error}
										helperText={error?.message}
										variant="standard"
										size="small"
										fullWidth
									/>
								)}
							/>
							<Stack gap="20px" direction="row">
								<Controller
									name="billingStateCode"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											value={field.value || ''}
											label="State"
											error={!!error}
											helperText={error?.message}
											variant="standard"
											size="small"
											fullWidth
										/>
									)}
								/>
								<Controller
									name="billingPostalCode"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextField
											{...field}
											value={field.value || ''}
											label="ZIP Code"
											error={!!error}
											helperText={error?.message}
											variant="standard"
											size="small"
											fullWidth
										/>
									)}
								/>
							</Stack>
						</Stack>
					</Stack>
					<Stack mt={2} direction="row" justifyContent="flex-end">
						<Button disabled={!isValid && isPendingChangeAddress} variant="contained" color="primary" type="submit">
							{isPendingChangeAddress ? 'Loading...' : 'Save'}
						</Button>
					</Stack>
				</Stack>
			)}
		</>
	);
}

export default ChangeAddress;
