import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Scheduler } from 'components/patient/mySchedule';
import { useDialog, useMonthRange } from 'hooks';
import { Dayjs } from 'dayjs';
import { usePatientMedHistoryQuery } from 'hooks/enterprise';
import { Paper, PaperTitle } from 'components/common/dashboardLayout';
import Dialog from 'components/common/dialog';
import SchedulerDayDetails from 'components/enterprise/SchedulerDayDetails';

function PatientsMySchedulePage() {
	const { patientId } = useParams();
	const [fromDate, toDate, setMonthRange] = useMonthRange();
	const { data: medHistory, isLoading: medHistoryIsLoading } = usePatientMedHistoryQuery({
		from: fromDate.format('YYYY-MM-DD'),
		to: toDate.format('YYYY-MM-DD'),
		patientId: patientId || '',
	});
	const [isOpenLogMedsDialog, openLogMedsDialog, closeLogMedsDialog, logMedsDialogData] = useDialog<{
		daySelected: Dayjs;
	}>();

	const handleDateClick = useCallback(
		(daySelected: Dayjs | null) => {
			if (daySelected) {
				openLogMedsDialog({ daySelected });
			}
		},
		[openLogMedsDialog]
	);

	const handleMonthChange = useCallback(
		(date: Dayjs | null) => {
			if (date) {
				setMonthRange(date);
			}
		},
		[setMonthRange]
	);

	return (
		<Grid item xs={12}>
			<Paper>
				<PaperTitle>My Schedule</PaperTitle>
				<Scheduler
					data={medHistory?.days}
					isLoading={medHistoryIsLoading}
					onDateClick={handleDateClick}
					onMonthChange={handleMonthChange}
				/>
			</Paper>
			<Dialog
				title={logMedsDialogData?.daySelected?.format('MMMM D, YYYY')}
				scroll="paper"
				fullWidth
				maxWidth="md"
				open={isOpenLogMedsDialog}
				onClose={closeLogMedsDialog}
				useSuspense
			>
				{logMedsDialogData && <SchedulerDayDetails patientId={patientId || ''} {...logMedsDialogData} />}
			</Dialog>
		</Grid>
	);
}

export default PatientsMySchedulePage;
