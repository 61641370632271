import React, { useCallback, useMemo } from 'react';
import Joyride, { CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useUserMetaDataList, useCreateUserMetaData, useUpdateUserMetaData } from 'hooks/patient/useUserMetaData';
import { UserMetaDataKeys } from 'core/api/userAccount/userAccount.models';
import Tooltip from './WalkThroughTooltip';

export interface WalkThroughStep extends Step {}

export enum WalkThroughScreenKeys {
	HOME = 'home',
	MY_SCHEDULE = 'today',
	MY_SCHEDULE_TODAY_MEDS_SKIPPED = 'today#skipped',
	MY_SCHEDULE_TODAY_MEDS_ACTIVE = 'today#active',
	MY_SCHEDULE_TODAY_MEDS_SCHEDULED_TIME = 'today/scheduled-time',
	MEDICATIONS = 'medications',
	DELIVERIES = 'deliveries',
	ACCOUNT = 'account',
}

export interface WalkThroughScreen {
	screenKey?: WalkThroughScreenKeys | WalkThroughScreenKeys[];
	steps: WalkThroughStep[];
}

interface WalkThroughProps extends WalkThroughScreen {}

export function WalkThrough({ screenKey, steps }: WalkThroughProps) {
	const { data: userMetaDataWT, isLoading } = useUserMetaDataList({ key: UserMetaDataKeys.WALKTHROUGH });
	const createMutation = useCreateUserMetaData();
	const updateMutation = useUpdateUserMetaData();

	if (!screenKey) {
		return null;
	}

	const metaId = useMemo(() => userMetaDataWT?.member?.[0]?.id, [userMetaDataWT]);
	const screensCompleted = useMemo(
		() => userMetaDataWT?.member?.[0]?.value.split(',') || [],
		[userMetaDataWT]
	) as WalkThroughScreenKeys[];
	const isVisible = useMemo(() => {
		if (Array.isArray(screenKey)) {
			return screenKey.some((key) => !screensCompleted.includes(key));
		}
		return !screensCompleted.includes(screenKey);
	}, [screensCompleted, screenKey]);

	const handleCallback = useCallback(
		(data: CallBackProps) => {
			if (data.status === STATUS.FINISHED && data.type === EVENTS.STEP_AFTER) {
				const value = [...screensCompleted, ...(Array.isArray(screenKey) ? screenKey : [screenKey])].join(',');
				if (metaId) {
					updateMutation.mutate({
						key: UserMetaDataKeys.WALKTHROUGH,
						value,
						id: metaId,
					});
				} else {
					createMutation.mutate({ key: UserMetaDataKeys.WALKTHROUGH, value });
				}
			}
		},
		[metaId, screensCompleted, screenKey, updateMutation, createMutation]
	);

	if (isLoading) {
		return null;
	}

	return (
		<Joyride
			steps={steps}
			run={isVisible}
			continuous
			disableCloseOnEsc
			disableOverlayClose
			hideCloseButton
			styles={{
				options: {
					zIndex: 1000,
				},
			}}
			tooltipComponent={Tooltip}
			callback={handleCallback}
		/>
	);
}
