import { ListItemText, styled } from '@mui/material';

export const InboxMessageSummary = styled(ListItemText)(({ theme }) => ({
	margin: 0,

	'& .MuiListItemText-primary': {
		textAlign: 'left',
		fontWeight: 600,
		color: theme.palette.text.secondary,
		fontSize: '1.25rem',
		lineHeight: '1.625rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.125rem',
			lineHeight: '1.375rem',
		},
	},
	'& .MuiListItemText-secondary': {
		color: theme.palette.text.primary,
		textAlign: 'left',
		fontWeight: 400,
		fontSize: '1rem',
		lineHeight: '1.5rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.875rem',
			lineHeight: '1rem',
		},
	},
}));

export default InboxMessageSummary;
