import React from 'react';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import bearerTokenService from 'services/bearerTokenService';
import { AuthError } from 'core/errors';

const STALE_TIME = 1000 * 60 * 1;
const REFETCH_INTERVAL = 1000 * 60 * 5;

function handleAuthError() {
	bearerTokenService.updateToken(null);
}

const queryCache = new QueryCache({
	onError: (error) => {
		if (error instanceof AuthError) {
			handleAuthError();
		}
	},
});

const queryClient = new QueryClient({
	queryCache,
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			staleTime: STALE_TIME,
			refetchInterval: REFETCH_INTERVAL,
			retry: false,
		},
	},
});

export function QueryProvider({ children }: React.PropsWithChildren) {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
			{process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
		</QueryClientProvider>
	);
}

export default QueryProvider;
