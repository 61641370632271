import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import DeliverySummary from 'components/patient/delivery/deliverySummary';
import DeliveriesMessage from 'components/patient/delivery/DeliveriesMessage';
import { useDeliveries } from 'hooks/patient';
import Suspense from 'components/common/Suspense';

function DeliveryPage() {
	const { sfid } = useParams();
	const { getDeliveryQuery, getDeliveriesQuery } = useDeliveries;
	const { data: deliverySummary, isLoading: isLoadingDelivery } = getDeliveryQuery({ sfid });
	const { data: deliveriesList, isLoading: isLoadingDeliveriesList } = getDeliveriesQuery();

	if (!sfid) {
		return (
			<Suspense isLoading={isLoadingDeliveriesList}>
				{deliveriesList && <DeliveriesMessage deliveriesList={deliveriesList} />}
			</Suspense>
		);
	}

	return (
		<Suspense isLoading={isLoadingDelivery && isLoadingDeliveriesList}>
			{deliverySummary && deliveriesList && (
				<DeliverySummary
					{...deliverySummary}
					deliveryDetailsMessageCard={
						<Card
							variant="outlined"
							elevation={0}
							sx={{
								borderRadius: '20px',
								padding: '24px 24px 0 24px',
							}}
						>
							<DeliveriesMessage deliveriesList={deliveriesList} deliverySummary={deliverySummary} />
						</Card>
					}
				/>
			)}
		</Suspense>
	);
}

export default DeliveryPage;
