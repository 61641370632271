import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
	MedsNotSentList,
	CareTeamMemberWidget,
	PatientProfileWidget,
	PatientContactsWidget,
	RefillAlertsWidget,
	MedicationLoggedThisWeekWidget,
} from 'components/enterprise';
import MedsNeedRefillList from 'components/enterprise/MedsNeedRefillList';

function PatientsViewPage() {
	const { patientId } = useParams();

	return (
		<>
			<Grid item xs={8} container spacing={2}>
				<Grid item xs={12}>
					<MedsNotSentList patientId={patientId} />
				</Grid>
				<Grid item xs={12}>
					<MedsNeedRefillList patientId={patientId} />
				</Grid>
			</Grid>
			<Grid xs={4} container item spacing={2}>
				<Grid item xs={12}>
					<PatientProfileWidget patientId={patientId} />
				</Grid>
				<Grid item xs={12}>
					<RefillAlertsWidget patientId={patientId} />
				</Grid>
				<Grid item xs={12}>
					<MedicationLoggedThisWeekWidget patientId={patientId} />
				</Grid>
				<Grid item xs={12}>
					<CareTeamMemberWidget patientId={patientId} />
				</Grid>
				<Grid item xs={12}>
					<PatientContactsWidget patientId={patientId} />
				</Grid>
			</Grid>
		</>
	);
}

export default PatientsViewPage;
