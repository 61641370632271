import React, { useEffect, useState, useRef } from 'react';
import { Alert, Button, Box, Typography, Stack } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import IMask from 'imask';
import TwoFAField from 'components/common/inputs/TwoFAField';
import { PHONE_NUMBER_MASK_OPTIONS } from 'components/common/inputs/textField/TextFieldMask';
import { IRegisterVerifyPhoneNumberRequestParams } from 'core/api/registration/registration.models';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';
import { StepTexts, useRegister, RegistrationSteps } from '../RegisterProvider';

const schema = Joi.object({
	phoneVerificationCode: Joi.string().min(6).max(6).required().messages({
		'any.required': 'Verification code is a required field',
	}),
});

export function VerifyCode() {
	const { hash, setCurrentStep, setUserToken, initialData } = useRegister();
	const { registerPhoneNumber, verifyPhoneNumber } = useRegisterMutation();
	const isPhoneVerification = !!initialData?.phoneNumber;

	const handleSendCodeAgain = () => registerPhoneNumber.mutate({ phoneNumber: initialData?.phoneNumber ?? '', hash });

	const formRef = useRef<HTMLFormElement>(null);
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<IRegisterVerifyPhoneNumberRequestParams>({ mode: 'onChange', resolver: joiResolver(schema) });

	const [attempts, setAttempts] = useState<number>(0);

	const phoneNumberMask = IMask.createMask(PHONE_NUMBER_MASK_OPTIONS);
	phoneNumberMask.resolve(initialData?.phoneNumber ?? '');
	const formattedPhoneNumber = phoneNumberMask.value;

	const handleActionFactory = ({ phoneVerificationCode }: IRegisterVerifyPhoneNumberRequestParams) =>
		verifyPhoneNumber.mutate(
			{ phoneVerificationCode, hash },
			{
				onSuccess: (response) => {
					if (response.jwtToken) {
						setUserToken(response.jwtToken);
						setCurrentStep(RegistrationSteps.FINISH);
					}
				},
			}
		);

	useEffect(() => {
		if (verifyPhoneNumber.isError) {
			setAttempts(attempts + 1);
		}
	}, [verifyPhoneNumber.isError]);

	useEffect(() => {
		if (isValid && attempts < 1 && isPhoneVerification) {
			handleSubmit(handleActionFactory)();
		}
	}, [isValid]);

	const getButtonText = () => {
		if (registerPhoneNumber.isPending) return 'Loading...';
		if (isPhoneVerification) return 'Send code again';
		return 'Send me a new email.';
	};

	return (
		<>
			<StepTexts introText="Almost Done!" extraIntroText="Create your account in just a few short steps." />

			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Finish Signing Up
			</Typography>

			{isPhoneVerification ? (
				<Stack
					sx={{ width: { xs: '100%', md: '56%' } }}
					gap={2}
					ref={formRef}
					component="form"
					onSubmit={handleSubmit(handleActionFactory)}
				>
					<Box>
						<Typography variant="input2" fontWeight={700}>
							Enter the 6-digit code sent to you at {formattedPhoneNumber}.
						</Typography>
						<Stack justifyContent="space-between" mt={1} gap="1rem">
							<Controller
								name="phoneVerificationCode"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TwoFAField {...field} error={!!error} variant="standard" size="small" />
								)}
							/>
						</Stack>
					</Box>

					{verifyPhoneNumber.error?.message && (
						<Alert sx={{ mt: 2 }} severity="error">
							{verifyPhoneNumber.error.message}
						</Alert>
					)}
					{registerPhoneNumber.error?.message && (
						<Alert sx={{ mt: 2 }} severity="error">
							{registerPhoneNumber.error.message}
						</Alert>
					)}

					<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
						{attempts > 0 && (
							<Button
								disabled={!isValid && verifyPhoneNumber.isPending}
								variant="contained"
								color="primary"
								fullWidth
								type="submit"
							>
								{verifyPhoneNumber.isPending ? 'Loading...' : 'Complete Your Sign Up'}
							</Button>
						)}
					</Box>
				</Stack>
			) : (
				<>
					{!registerPhoneNumber.error && (
						<Typography component="p" color="text.secondary">
							Please check your email and follow the link to verify.
						</Typography>
					)}

					{registerPhoneNumber.error?.message && (
						<Alert sx={{ mt: 2 }} severity="error">
							{registerPhoneNumber.error.message}
						</Alert>
					)}
				</>
			)}

			<Stack direction="column" sx={{ width: { xs: '100%', sm: '50%' } }}>
				<Typography variant="input2" fontWeight={700}>
					Didn&apos;t receive the {isPhoneVerification ? 'code' : 'email'}?
				</Typography>
				<Button
					onClick={handleSendCodeAgain}
					variant="text"
					sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}
				>
					{getButtonText()}
				</Button>
				<Button
					onClick={() => setCurrentStep(RegistrationSteps.PHONE_NUMBER)}
					variant="text"
					sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}
				>
					{isPhoneVerification ? 'Change phone number' : 'Use phone number instead.'}
				</Button>
			</Stack>
		</>
	);
}

export default VerifyCode;
