import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createUserMetaData, updateUserMetaData } from 'core/api/userAccount/userAccount.api';
import { UserMetaDataParams } from 'core/api/userAccount/userAccount.models';
import { userAccountQueries } from 'queries/patient';

export function useUserMetaDataList(props: UserMetaDataParams) {
	return useQuery(userAccountQueries.userMetaDataList(props));
}

export function useCreateUserMetaData() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: createUserMetaData,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: userAccountQueries.userMetaDataLists().queryKey,
			});
		},
	});
}

export function useUpdateUserMetaData() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: updateUserMetaData,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: userAccountQueries.userMetaDataLists().queryKey,
			});
		},
	});
}
