import { Box, styled } from '@mui/material';

export const RefillAlert = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: '24px',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: '24px',
	alignSelf: 'stretch',
	borderRadius: '10px',
	background: theme.palette.background.secondary,

	'& .MuiListItemText-primary': {
		color: theme.palette.text.secondary,
	},
}));

export const RefillAlertHead = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	flexDirection: 'row',
	alignItems: 'center',

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		gap: '16px',
		alignItems: 'stretch',
	},
}));

export const RefillAlertHeadIcon = styled(Box)(() => ({
	display: 'flex',
	padding: '0px 8px',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: 10,
	'&  .MuiSvgIcon-root': {
		width: 36,
		height: 36,
	},
}));

export const RefillAlertHeadTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-start',
	gap: 12,
	flex: '1 0 0',
	'& h3': {
		fontWeight: 700,
		color: theme.palette.text.secondary,
	},
	'& span': {
		fontWeight: 600,
		color: theme.palette.text.primary,
	},
}));
