import React from 'react';
import { Chip, ChipProps } from '@mui/material';

interface BooleanChipProps extends Omit<ChipProps, 'label' | 'color'> {
	value: boolean;
	colorTrue?: ChipProps['color'];
	colorFalse?: ChipProps['color'];
	labelTrue?: string;
	labelFalse?: string;
}

function BooleanChip({
	value,
	colorTrue = 'success',
	colorFalse = 'default',
	labelTrue = 'Yes',
	labelFalse = 'No',
	size = 'small',
	...rest
}: BooleanChipProps) {
	const booleanChipProps = value ? { color: colorTrue, label: labelTrue } : { color: colorFalse, label: labelFalse };

	return (
		<Chip
			component="span"
			size={size}
			sx={{
				fontWeight: 700,
				textTransform: 'capitalize',
			}}
			{...booleanChipProps}
			{...rest}
		/>
	);
}

export default BooleanChip;
