import React from 'react';
import Dialog, { IDialogProps } from 'components/common/dialog';
import DownloadPdfButton from 'components/common/inputs/DownloadPdfButton';
import { Stack, Typography } from '@mui/material';
import { patientDeliveryDetailsPdf } from 'core/api/enterprise/patient.api';
import { DeliveryMeds, DeliveryTrackingButton } from 'components/common/delivery';
import { usePatientDeliveryQuery } from 'hooks/enterprise';
import Suspense from 'components/common/Suspense';

interface DeliveryViewDialogProps extends Omit<IDialogProps, 'children'> {
	deliverySfid: string;
	patientId: string;
}

export function DeliveryViewDialog({ deliverySfid, patientId, ...restDialogProps }: DeliveryViewDialogProps) {
	const { data, isLoading } = usePatientDeliveryQuery({
		patientId,
		sfid: deliverySfid,
	});
	const {
		orderName,
		orderNumber,
		orderStartDate,
		shipTo,
		trackingButton,
		notIncludedMedication,
		exactPackMedication,
		refrigerateMedication,
		additionalMedication,
	} = data || {};

	const recordType = data?.recordType || null;

	const handleDownloadPdf = () =>
		patientDeliveryDetailsPdf({
			patientId,
			sfid: deliverySfid,
		});

	return (
		<Dialog
			scroll="paper"
			fullWidth
			maxWidth="md"
			title={orderName && orderNumber ? `${orderName} Order: ${orderNumber}` : ''}
			{...restDialogProps}
		>
			<Suspense isLoading={isLoading}>
				<Stack direction="column" gap={2}>
					<Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
						<Typography variant="input2">
							<Typography component="span" fontWeight={700}>
								Ship to:
							</Typography>{' '}
							{shipTo}
						</Typography>
						{trackingButton && <DeliveryTrackingButton trackingButton={trackingButton} />}
					</Stack>

					{orderStartDate && (
						<Typography component="p" variant="small">
							<b>Start Date:</b> {orderStartDate}
						</Typography>
					)}

					{notIncludedMedication && notIncludedMedication.length > 0 && (
						<DeliveryMeds
							tableName="Medications Not Included"
							status="error"
							rows={notIncludedMedication}
							recordType={recordType}
						/>
					)}

					{exactPackMedication && exactPackMedication.length > 0 && (
						<DeliveryMeds tableName="Medications in the ExactPack" rows={exactPackMedication} recordType={recordType} />
					)}

					{refrigerateMedication && refrigerateMedication.length > 0 && (
						<DeliveryMeds
							tableName="Refrigerated Medication"
							rows={refrigerateMedication}
							recordType={recordType}
							showRefrigerateAlert
						/>
					)}

					{additionalMedication && additionalMedication.length > 0 && (
						<DeliveryMeds tableName="Additional Medications" rows={additionalMedication} recordType={recordType} />
					)}

					<Typography component="p" variant="small">
						Our pharmacists are available for you and your family. If you have any questions about your prescriptions
						and would like to speak to an ExactCare pharmacist, please call 1-877-355-7225.
						<br />
						<DownloadPdfButton fetchPdfData={handleDownloadPdf} variant="text">
							Print or Download Delivery Summary
						</DownloadPdfButton>
					</Typography>
				</Stack>
			</Suspense>
		</Dialog>
	);
}

export default DeliveryViewDialog;
