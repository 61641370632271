import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { TextField, Alert, Button, Link, Typography, InputAdornment, Stack } from '@mui/material';
import routes from 'routes';
import { useRequestResetPasswordMutation } from 'hooks/auth';
import ImageCover from 'components/common/ImageCover';
import { AuthSidebar } from 'components/auth';
import { ISendEmailRequest } from 'core/api/login/login.models';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';
import setFormErrors from 'utils/setFormErrors';

const schema = Joi.object({
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.messages({
			'*': 'Please enter a valid email address.',
		}),
});

export default function ForgotPasswordPage() {
	const { mutate, isPending, isSuccess } = useRequestResetPasswordMutation();
	const [lastUsedEmail, setLastUsedEmail] = useState<string | null>(null);
	const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
	const [countdown, setCountdown] = useState<number>(0);

	const {
		control,
		handleSubmit,
		formState: { errors: resetPasswordErrors },
		setError,
	} = useForm<ISendEmailRequest>({ mode: 'onChange', resolver: joiResolver(schema) });

	const handleResetPassword = ({ email }: ISendEmailRequest) => {
		setLastUsedEmail(email);
		mutate(
			{ email },
			{
				onError: (error) => setFormErrors(error, setError),
			}
		);
	};

	const handleResend = () => {
		if (lastUsedEmail) {
			setResendButtonDisabled(true);
			setCountdown(30);
			handleResetPassword({ email: lastUsedEmail });
		}
	};

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null;

		if (resendButtonDisabled && countdown > 0) {
			timer = setInterval(() => {
				setCountdown(countdown - 1);
			}, 1000);
		} else if (countdown === 0) {
			setResendButtonDisabled(false);
		}

		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [resendButtonDisabled, countdown]);

	return (
		<>
			<AuthSidebar>
				{!isSuccess && (
					<>
						<Stack gap="12px">
							<Typography
								component="h1"
								fontSize="2.313rem"
								lineHeight="2.5rem"
								fontWeight="bold"
								color="text.secondary"
							>
								Reset Your Password
							</Typography>
							<Typography color="text.secondary">
								Enter the email address used to create your account. We&apos;ll send you an email to update your
								password
							</Typography>
						</Stack>
						<Stack gap="12px" component="form" onSubmit={handleSubmit(handleResetPassword)}>
							<Controller
								name="email"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										value={field.value || ''}
										error={!!error}
										helperText={error?.message}
										variant="standard"
										size="small"
										fullWidth
										label="Email Address"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<AlternateEmailIcon />
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
							<FormErrorMessages errors={resetPasswordErrors} name="root" />
							<Button disabled={isPending} fullWidth type="submit" variant="contained" color="primary">
								{isPending ? 'Loading...' : 'Reset Password'}
							</Button>
						</Stack>
						<Link component={RouterLink} to={routes.login} color="text.secondary">
							Return to Login
						</Link>
					</>
				)}
				{isSuccess && (
					<>
						<Typography component="h1" fontSize="2.313rem" lineHeight="2.5rem" fontWeight="bold" color="text.secondary">
							Check your email!
						</Typography>
						{resendButtonDisabled && (
							<Alert
								severity="info"
								sx={{ mb: 2 }}
							>{`A password reset email has been sent. You can resend the email in ${countdown} seconds.`}</Alert>
						)}
						<Typography color="text.secondary">
							If an account exists with this email address, we sent you a link to reset your password. Please check your
							email inbox.
						</Typography>
						<Stack gap="12px">
							<Button variant="text" color="inherit" onClick={handleResend} disabled={resendButtonDisabled}>
								Resend the password reset instructions
							</Button>
							<Link component={RouterLink} to={routes.login} color="text.secondary">
								Return to Login
							</Link>
						</Stack>
					</>
				)}
			</AuthSidebar>
			<ImageCover
				src="/images/auth-seniors.png"
				sx={{
					display: { sm: 'none', md: 'flex' },
					flexGrow: 2,
				}}
			/>
		</>
	);
}
