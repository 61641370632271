import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PaperTitle, Paper } from 'components/common/dashboardLayout';
import { Link, Grid, IconButton } from '@mui/material';
import routes from 'routes';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { DateFilter, FiltersBar, SelectFilter, SearchFilter } from 'components/common/inputs/filters';
import DataGrid from 'components/common/dataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { PatientStatus } from 'core/models/admin/patients.models';
import { IPatientResponse } from 'core/api/admin/patients.models';
import dayjs from 'dayjs';
import PatientStatusChip from 'components/common/patientStatusChip/PatientStatusChip';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useAuth, useDialog } from 'hooks';
import { usePatientMutations, usePatientsListQuery } from 'hooks/admin';
import AdminPatientActions from 'components/admin/AdminPatientActions';
import { AppProvider } from 'contexts/RootProvider';

function PatientsPage() {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const [status, setStatus] = useState<PatientStatus | undefined>(undefined);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [dob, setDob] = useState<string>('');
	const { unblockPatientMutation } = usePatientMutations();
	const [
		isOpenDeleteConfirmationDialog,
		openDeleteConfirmationDialog,
		closeDeleteConfirmationDialog,
		patientIdToDelete,
	] = useDialog<number>();
	const [
		isOpenSuspendConfirmationDialog,
		openSuspendConfirmationDialog,
		closeSuspendConfirmationDialog,
		patientToSuspend,
	] = useDialog<IPatientResponse>();
	const [isOpenChangePasswordDialog, openChangePasswordDialog, closeChangePasswordDialog, patientIdToChangePassword] =
		useDialog<number>();
	const [isOpenChangeEmailDialog, openChangeEmailDialog, closeChangeEmailDialog, patientToChangeEmail] =
		useDialog<IPatientResponse>();
	const [
		isOpenChangeBypassAuthCodeDialog,
		openChangeBypassAuthCodeDialog,
		closeChangeBypassAuthCodeDialog,
		patientToChangeBypassAuthCode,
	] = useDialog<IPatientResponse>();

	const { data: patients, isLoading } = usePatientsListQuery({
		status,
		search,
		dob,
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
	});

	const rowCountRef = useRef(patients?.totalItems || 0);
	const rowCount = useMemo(() => {
		if (patients?.totalItems !== undefined) {
			rowCountRef.current = patients.totalItems;
		}
		return rowCountRef.current;
	}, [patients?.totalItems]);

	const { switchToPatient } = useAuth();
	const { mutate: switchUserMutate, isPending: isPendingSwitchUser } = switchToPatient;

	const handleClearFilters = useCallback(() => {
		setStatus(undefined);
		setSearch(undefined);
		setDob('');
	}, []);

	const unblockButton = useCallback(
		(userId: number) => (
			<IconButton
				color="inherit"
				aria-label="Unblock"
				title="Unblock"
				sx={{ mr: 1 }}
				size="small"
				onClick={() => unblockPatientMutation.mutate({ userId: userId.toString() })}
			>
				<LockOpenIcon fontSize="small" />
			</IconButton>
		),
		[]
	);

	const formatDOB = (dateOfBirth: string | null) => {
		return dateOfBirth ? new Date(dateOfBirth) : null;
	};

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'userId', headerName: 'ID', sortable: false, flex: 2, type: 'number' },
			{
				field: 'fullName',
				headerName: 'Full name',
				sortable: false,
				flex: 4,
				renderCell: ({ row, value }) => (
					<>
						{row.status === PatientStatus.BLOCKED && unblockButton(row.userId as number)}
						<Link
							component={RouterLink}
							to={generatePath(routes.admin.patients.patient.medSync, {
								patientId: row.userId,
							})}
							fontWeight="bold"
						>
							{value || `Patient #${row.userId}`}
						</Link>
					</>
				),
			},
			{
				field: 'dateOfBirth',
				headerName: 'DOB',
				sortable: false,
				flex: 2,
				type: 'date',
				valueGetter: formatDOB,
			},
			{ field: 'email', headerName: 'Email', sortable: false, flex: 4 },
			{ field: 'phone', headerName: 'Phone', sortable: false, flex: 2 },
			{ field: 'createdAt', headerName: 'Created At', sortable: false, flex: 3 },
			{ field: 'lastLoginAt', headerName: 'Last login', sortable: false, flex: 3 },
			{
				field: 'status',
				headerName: 'Status',
				sortable: false,
				flex: 2,
				renderCell: ({ value }) => <PatientStatusChip status={value} />,
			},
			{
				field: 'actions',
				type: 'actions',
				getActions: ({ row }) => [
					<AdminPatientActions.Menu
						key={row.userId}
						patient={row}
						onLoginAs={() => switchUserMutate({ id: row.userId })}
						onSuspend={openSuspendConfirmationDialog}
						onChangePassword={openChangePasswordDialog}
						onChangeEmail={openChangeEmailDialog}
						onChangeBypassAuthCode={openChangeBypassAuthCodeDialog}
						onDelete={openDeleteConfirmationDialog}
						isLoginDisabled={isPendingSwitchUser}
					/>,
				],
			},
		],
		[unblockButton]
	);

	return (
		<Grid item xs={12}>
			<AppProvider.PageTitle>Patients list</AppProvider.PageTitle>
			<Paper>
				<PaperTitle>Patients list</PaperTitle>
				<FiltersBar onClearFilters={handleClearFilters} buttonProps={{ variant: 'outlined' }}>
					<SelectFilter
						sx={{ flex: 1 }}
						name="status"
						label="Status"
						value={status}
						placeholder="All"
						items={Object.values(PatientStatus).map((patientStatus) => ({
							value: patientStatus,
							label: patientStatus,
						}))}
						onChange={(value) => {
							setStatus(value as PatientStatus | undefined);
						}}
					/>
					<SearchFilter
						sx={{ flex: 4 }}
						name="search"
						label="Search by name, email, phone and GUID"
						value={search}
						onChange={(event) => setSearch(event.target.value)}
						variant="outlined"
						size="small"
					/>
					<DateFilter
						name="dob"
						label="Search patient by DOB"
						sx={{ flex: 2 }}
						value={dob ? dayjs(dob) : null}
						onChange={(value) => {
							setDob(value?.format('MM/DD/YYYY') || '');
						}}
					/>
				</FiltersBar>
				<DataGrid
					rows={patients?.member || []}
					columns={columns}
					loading={isLoading}
					rowCount={rowCount}
					pageSizeOptions={[5, 10, 25]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					getRowId={(row) => row.userId}
					disableRowSelectionOnClick
					disableColumnSelector
					disableColumnMenu
					disableColumnFilter
					columnBufferPx={8}
					getRowClassName={(params) => `row-status--${params.row.status}`}
					editMode="row"
				/>
				<AdminPatientActions.Dialogs
					deleteDialog={{
						isOpen: isOpenDeleteConfirmationDialog,
						patientId: patientIdToDelete,
						onCancel: closeDeleteConfirmationDialog,
					}}
					suspendDialog={{
						isOpen: isOpenSuspendConfirmationDialog,
						patient: patientToSuspend,
						onCancel: closeSuspendConfirmationDialog,
					}}
					passwordDialog={{
						isOpen: isOpenChangePasswordDialog,
						patientId: patientIdToChangePassword?.toString(),
						onClose: closeChangePasswordDialog,
					}}
					emailDialog={{
						isOpen: isOpenChangeEmailDialog,
						patient: patientToChangeEmail,
						onClose: closeChangeEmailDialog,
					}}
					bypassAuthCodeDialog={{
						isOpen: isOpenChangeBypassAuthCodeDialog,
						patient: patientToChangeBypassAuthCode,
						onClose: closeChangeBypassAuthCodeDialog,
					}}
				/>
			</Paper>
		</Grid>
	);
}

export default PatientsPage;
