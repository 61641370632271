import { updateInboxNotification } from 'core/api/inboxNotification';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useInboxMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['inbox', 'message', 'update'],
		mutationFn: updateInboxNotification,
		onSuccess: (data, variables) => {
			queryClient.setQueryData(['inbox', 'message', variables.id], data);
			queryClient.invalidateQueries({ queryKey: ['inbox', 'list'] });
		},
	});
}
