import { styled, Tabs } from '@mui/material';

export const InboxMessagesTabsStyled = styled(Tabs)(({ theme }) => ({
	borderBottom: 1,
	borderColor: 'divider',
	'& .MuiTab-root': {
		'&.Mui-selected': {
			color: theme.palette.text.secondary,
		},
		color: theme.palette.text.primary,
		textTransform: 'capitalize',
		fontWeight: 600,
		textAlign: 'center',
		fontSize: '1.25rem',
		lineHeight: '1.625rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.125rem',
			lineHeight: '1.375rem',
		},
	},
}));
