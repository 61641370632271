import React from 'react';
import ContentCard from 'components/patient/common/ContentCard';
import { useParams } from 'react-router-dom';
import { IScheduledMedDetails } from 'core/api/mySchedule/mySchedule.models';
import { MedScheduleDetails } from 'components/patient/mySchedule';
import Suspense from 'components/common/Suspense';
import NoTimeSelected from 'components/patient/mySchedule/NoTimeSelected';
import { useScheduledMedsQuery, useTakeMedMutation } from 'hooks/patient/useMySchedule';

export default function TodaysMedPage() {
	const { id } = useParams<{ id?: string }>();
	const { data: scheduledMedsData } = useScheduledMedsQuery({});
	const scheduleDetails = scheduledMedsData?.member.find((item: IScheduledMedDetails) => item.id === Number(id));
	const { mutate: handleLogChange } = useTakeMedMutation();

	if (!id) {
		return (
			<ContentCard>
				<NoTimeSelected />
			</ContentCard>
		);
	}

	return (
		<ContentCard className="EcaPage-TodaysMedDetailsPage">
			<Suspense isLoading={!scheduleDetails}>
				{scheduleDetails && <MedScheduleDetails {...scheduleDetails} onLogChange={handleLogChange} />}
			</Suspense>
		</ContentCard>
	);
}
