import { UnprocessableContentError, InvalidInputError, AuthError, InternalServerError, ApiError } from '../errors';
import { IViolation } from '../errors/ApiError';
import HttpStatus from './httpStatusCodes';

const PATH_PARAMS = ['id', 'sfid', 'hash', 'opportunitySfid'];

type AnyObject = Record<string, unknown>;

function queryParams(params: AnyObject, ignoreIdPath: boolean): any {
	let path = '';
	const parameters = Object.entries(params)
		.filter(([key, value]) => value !== '')
		.flatMap(([key, value]) => {
			if (PATH_PARAMS.includes(key) && !ignoreIdPath) {
				path += `/${value}`;
				return [];
			}
			if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
				return Object.entries(value).map(([subKey, subValue]) => `${key}[${subKey}]=${subValue}`);
			}
			return `${key}=${value}`;
		})
		.filter((param) => param !== '')
		.join('&');

	return { path, query: parameters ? `?${parameters}` : '' };
}

function fetchEndpoint(
	endpoint: string,
	method: string,
	body: AnyObject,
	additionalHeaders: AnyObject = {}
): Promise<Response> {
	let ignoreIdPath = false;
	const bearerToken = localStorage.getItem('bearer');
	const authorization = bearerToken && { authorization: `Bearer ${bearerToken}` };

	if (additionalHeaders.ignoreIdPath) {
		ignoreIdPath = true;
		/* eslint-disable-next-line no-param-reassign */
		delete additionalHeaders.ignoreIdPath;
	}

	const headers = {
		Accept: 'application/ld+json',
		'Content-Type': 'application/ld+json; charset=utf-8',
		...additionalHeaders,
		...authorization,
	};

	let requestProps: RequestInit = {};

	const { path, query } = queryParams(body, ignoreIdPath);

	let newEndpoint = endpoint;

	if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
		requestProps = {
			method,
			headers: {
				...headers,
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify(body),
		};
	} else if (method === 'GET' || method === 'DELETE') {
		requestProps = {
			method,
			headers,
		};
		newEndpoint = `${newEndpoint}${path}${query}`;
	}

	return fetch(newEndpoint, requestProps);
}

async function handleError(response: Response, errorData: AnyObject): Promise<void> {
	if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
		throw new UnprocessableContentError(errorData?.description as string, errorData?.violations as IViolation[]);
	} else if (response.status === HttpStatus.BAD_REQUEST) {
		throw new InvalidInputError(errorData?.description as string);
	} else if (response.status === HttpStatus.UNAUTHORIZED) {
		throw new AuthError(errorData?.description as string);
	} else if (response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
		throw new InternalServerError(errorData?.description as string);
	} else {
		throw new ApiError(response.status, (errorData?.description as string) || 'Something went wrong');
	}
}

async function request<T>(method: string, endpoint: string, body: AnyObject, headers: AnyObject): Promise<T> {
	const response: Response = await fetchEndpoint(endpoint, method, body, headers);
	let data;

	if (response.ok && response.status === HttpStatus.NO_CONTENT) {
		data = {
			success: true,
		};
	} else {
		data = await response.json();
	}

	if (!response.ok) {
		await handleError(response, data);
	}

	return data;
}

export async function blobRequest(method: string, endpoint: string, body: AnyObject, headers: AnyObject): Promise<any> {
	const response: Response = await fetchEndpoint(endpoint, method, body, headers);

	return response.blob();
}

export async function getBlobRequest(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<Blob> {
	return blobRequest('GET', endpoint, body, headers);
}

export async function postRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('POST', endpoint, body, headers);
}

export async function putRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('PUT', endpoint, body, headers);
}

export async function patchRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('PATCH', endpoint, body, headers);
}

export async function getRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('GET', endpoint, body, headers);
}

export async function deleteRequest<T>(endpoint: string, body: AnyObject = {}, headers: AnyObject = {}): Promise<T> {
	return request('DELETE', endpoint, body, headers);
}
