import React from 'react';
import { ListItemIcon, ListItemText, SxProps, Theme, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { ArrowIcon } from 'components/common/icons/ArrowIcon';
import { SidebarListItemWrapper } from 'components/common/sidebarList';

interface IInfoCardWidgetProps {
	title: string;
	children?: React.ReactNode;
	icon?: React.ReactNode;
	disableArrow?: boolean;
	alert?: boolean;
	dense?: boolean;
	sx?: SxProps<Theme>;
}

export default function InfoCardWidget({
	title,
	children,
	icon,
	alert,
	disableArrow,
	dense,
	sx,
}: IInfoCardWidgetProps) {
	return (
		<SidebarListItemWrapper
			gap={1}
			sx={{
				minHeight: dense ? 'auto' : { xs: '34px', sm: '48px' },
				justifyContent: 'center',
				...sx,
			}}
		>
			{icon && (
				<ListItemIcon
					sx={{
						minWidth: 'unset',
						'& .MuiSvgIcon-root': {
							fontSize: { xs: '1.875rem', sm: '2.25rem' },
							fill: 'transparent',
							color: (theme) => theme.palette.grey[500],
						},
					}}
				>
					{icon}
				</ListItemIcon>
			)}
			<ListItemText
				sx={{
					display: 'flex',
					flexDirection: 'column',
					margin: 0,
				}}
				disableTypography
				primary={
					<Typography variant="h2" fontWeight={500} color="text.secondary">
						{title}
					</Typography>
				}
				secondary={children}
			/>
			{alert && (
				<ListItemIcon sx={{ minWidth: 'unset' }}>
					<ErrorIcon color="error" />
				</ListItemIcon>
			)}
			{!disableArrow && (
				<ListItemIcon sx={{ minWidth: 'unset' }}>
					<ArrowIcon />
				</ListItemIcon>
			)}
		</SidebarListItemWrapper>
	);
}
