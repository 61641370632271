import { useQuery } from '@tanstack/react-query';
import { getInboxNotification, InboxNotification } from 'core/api/inboxNotification';

export function useInboxMessage(id: number) {
	return useQuery<InboxNotification, Error>({
		queryKey: ['inbox', 'message', id],
		queryFn: () => getInboxNotification({ id }),
		enabled: !!id,
	});
}
