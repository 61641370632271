import { useMutation } from '@tanstack/react-query';
import { ISuccessResponse } from 'core/api/api-service.models';
import { IChangePasswordRequest } from 'core/api/resetPassword/resetPassword.models';
import { changePassword } from 'core/api/resetPassword/resetPassword.api';
import { IApiError } from 'core/errors/ApiError';

export function useResetPasswordMutation() {
	return useMutation<
		ISuccessResponse,
		IApiError<Record<keyof IChangePasswordRequest, string>>,
		IChangePasswordRequest,
		unknown
	>({
		mutationKey: ['resetPassword'],
		mutationFn: changePassword,
	});
}

export default useResetPasswordMutation;
