import React, { useCallback, useMemo, useState, useRef } from 'react';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import { Paper, PaperTitle } from 'components/common/dashboardLayout';
import DataGrid from 'components/common/dataGrid';
import { PatientDetails, PushDeviceOS } from 'core/api/admin/patients.models';
import { usePatientPushDeviceListQuery } from 'hooks/admin';
import StatusChip, { StatusChipLegend } from 'components/common/StatusChip';

interface PatientPushDeviceListProps extends Pick<PatientDetails, 'userId'> {}

export function PatientPushDeviceList({ userId }: PatientPushDeviceListProps) {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<{
		field: 'id';
		sort: 'ASC' | 'DESC';
	}>({
		field: 'id',
		sort: 'DESC',
	});

	const { data, isLoading } = usePatientPushDeviceListQuery({
		patientId: userId.toString(),
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
		orderBy: sortModel.field,
		orderDirection: sortModel.sort,
	});
	const { member: pushDevicesData, totalItems: totalPushDevices } = data ?? {};

	const rowCountRef = useRef(totalPushDevices || 0);
	const rowCount = useMemo(() => {
		if (totalPushDevices !== undefined) {
			rowCountRef.current = totalPushDevices;
		}
		return rowCountRef.current;
	}, [totalPushDevices]);

	const osLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: PushDeviceOS.IOS,
				label: 'IOS',
				icon: <AppleIcon />,
			},
			{
				id: PushDeviceOS.ANDROID,
				label: 'Android',
				icon: <AndroidIcon />,
			},
		],
		[]
	);

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'id', headerName: 'ID', sortable: true, flex: 1 },
			{
				field: 'deviceOs',
				headerName: 'OS',
				sortable: false,
				flex: 1,
				renderCell: ({ value }) => <StatusChip legend={osLegend} statusId={value} />,
			},
			{
				field: 'deviceModel',
				headerName: 'Device Model',
				sortable: false,
				flex: 3,
			},
			{
				field: 'deviceToken',
				headerName: 'Device Token',
				sortable: false,
				flex: 1,
			},
			{
				field: 'createdAt',
				headerName: 'Created At',
				sortable: false,
				flex: 2,
			},
			{
				field: 'updatedAt',
				headerName: 'Updated At',
				sortable: false,
				flex: 2,
			},
		],
		[]
	);

	const handleSortModelChange = useCallback((gridSortModel: GridSortModel) => {
		const sort = gridSortModel[0]?.sort?.toUpperCase() as 'ASC' | 'DESC';
		const field = gridSortModel[0]?.field as 'id';

		setSortModel({
			field,
			sort,
		});
	}, []);

	return (
		<Paper>
			<PaperTitle>Push Devices</PaperTitle>
			<DataGrid
				rows={pushDevicesData || []}
				columns={columns}
				loading={isLoading}
				rowCount={rowCount}
				pageSizeOptions={[5, 10, 25]}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortModelChange}
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
			/>
		</Paper>
	);
}

export default PatientPushDeviceList;
