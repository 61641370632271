import { styled } from '@mui/material/styles';
import { TableContainer as MuiTableContainer } from '@mui/material';

export const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
	borderRadius: '10px',
	border: '1px solid rgba(0, 0, 0, 0.12)',

	[theme.breakpoints.down('sm')]: {
		borderRadius: 0,
		border: 'none',
	},

	'& .MuiTableHead-root': {
		background: theme.palette.background.secondary,
	},
	'& .MuiTableCell-root': {
		padding: '12px',
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			borderRight: 'none',
		},
	},
	'& .MuiTableRow-root:last-child .MuiTableCell-root': {
		borderBottom: 0,
	},
	'& .MuiTableCell-head': {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.body1.fontSize,
		lineHeight: theme.typography.body1.lineHeight,
		fontStyle: 'normal',
		fontWeight: '700',
		'&:first-of-type': {
			fontSize: theme.typography.h2.fontSize,
			lineHeight: theme.typography.h2.lineHeight,
			fontStyle: 'normal',
			fontWeight: '700',
		},
		'&.error': {
			color: theme.palette.error.main,
		},
	},

	'& .MuiTableBody-root': {
		background: theme.palette.background.default,

		'& th.MuiTableCell-root': {
			color: theme.palette.text.secondary,
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.5px',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
			alignItems: 'center',
			gap: '10px',

			'& .MuiBox-root': {
				display: 'flex',
				flexDirection: 'column',

				'& span': {
					fontWeight: '400',
					letterSpacing: '0.5px',
					display: 'block',
				},
			},
		},
	},

	'& .collapseBody': {
		padding: 0,

		'& .MuiListItem-root': {
			padding: '0 16px',
		},

		'& .MuiListItemText-root': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
			gap: '3px',
			margin: 0,

			'&.error .MuiListItemText-secondary': {
				color: theme.palette.error.main,
			},
		},

		'& .MuiListItemText-primary': {
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.5px',
		},
		'& .MuiListItemText-secondary': {
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			fontStyle: 'normal',
			fontWeight: '400',
			letterSpacing: '0.5px',
			color: theme.palette.text.secondary,
		},
	},

	'& .collapseButton': {
		display: 'flex',
		background: theme.palette.background.secondary,
		alignItems: 'center',
		color: theme.palette.text.secondary,
		fontSize: theme.typography.caption.fontSize,
		lineHeight: theme.typography.caption.lineHeight,
		fontWeight: '700',
		letterSpacing: '0.5px',
		padding: '5px 10px',
		gap: '5px',
		cursor: 'pointer',
	},
}));

export default TableContainer;
