import React from 'react';
import DataGrid from 'components/common/dataGrid';
import { Paper, PaperTitle } from 'components/common/dashboardLayout';
import { GridColDef } from '@mui/x-data-grid';
import { usePatientDetailsQuery } from 'hooks/enterprise';
import { Avatar, Stack } from '@mui/material';
import ImageWithPreview from 'components/common/imageWithPreview/imageWithPreview';

interface MedsNotSentListProps {
	patientId?: string;
}

export function MedsNotSentList({ patientId }: MedsNotSentListProps) {
	const { data, isLoading } = usePatientDetailsQuery({ id: patientId });
	const items = data?.medsNotSent || [];
	const totalItems = items.length;

	const columns: GridColDef[] = [
		{
			field: 'imageUrl',
			headerName: 'Image',
			sortable: false,
			width: 60,
			display: 'flex',
			renderCell: ({ row, value: imageUrl }) => (
				<ImageWithPreview source={imageUrl} title={row.name}>
					<Avatar alt={row.name} src={imageUrl} sx={{ width: 40, height: 40 }} variant="rounded" />
				</ImageWithPreview>
			),
		},
		{
			field: 'name',
			headerName: 'Name',
			sortable: false,
			flex: 3,
		},
		{
			field: 'reason',
			headerName: 'Reason',
			sortable: false,
			flex: 1,
			display: 'flex',
		},
		{ field: 'genericFor', headerName: 'Generic For', sortable: false, flex: 1 },
		{
			field: 'notSentAlert',
			headerName: 'Not Sent Alert',
			sortable: false,
			flex: 2,
		},
	];

	return (
		<Paper>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
				<PaperTitle>Meds not sent ({totalItems})</PaperTitle>
			</Stack>
			<DataGrid
				autoHeight
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 5,
						},
					},
				}}
				rows={items || []}
				getRowId={(row) => row.name}
				columns={columns}
				loading={isLoading}
				pageSizeOptions={[5, 10, 25]}
				paginationMode="client"
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
			/>
		</Paper>
	);
}

export default MedsNotSentList;
