import React from 'react';
import { Stack, Typography } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { Bullet } from './CalendarWeekLegend.styles';

interface ICalendarWeekDayProps {
	items: {
		label: string;
		color: keyof PaletteOptions;
	}[];
}

export function CalendarWeekLegend({ items }: ICalendarWeekDayProps) {
	return (
		<Stack direction="row" gap="12px" justifyContent="center">
			{items.map(({ label, color }) => (
				<Stack direction="row" alignItems="center" gap="6px" key={label}>
					<Bullet color={color} />
					<Typography fontSize={12} fontWeight={400} color="grey.600">
						{label}
					</Typography>
				</Stack>
			))}
		</Stack>
	);
}
