import React, { useMemo } from 'react';
import { Box, Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import { PatientStatus } from 'core/models/admin/patients.models';

type ChipColors = 'success' | 'warning' | 'info' | 'error';

interface StatusConfig {
	color: ChipColors;
	label: string;
}

type ActivePatientStatus = Exclude<PatientStatus, PatientStatus.DELETED>;

type StatusConfigMap = {
	[key in ActivePatientStatus]: StatusConfig;
};

export interface IPatientStatusChipProps extends Omit<ChipProps, 'label' | 'size' | 'color'> {
	status: PatientStatus | string;
}

function PatientStatusChip({ status, ...props }: IPatientStatusChipProps) {
	const commonStyles = useMemo(
		() =>
			({
				fontWeight: 700,
				textTransform: 'capitalize',
			}) as const,
		[]
	);

	const statusConfigs: StatusConfigMap = useMemo(
		() => ({
			[PatientStatus.ACTIVE]: { color: 'success', label: status },
			[PatientStatus.PRE_REGISTER]: { color: 'warning', label: status },
			[PatientStatus.SUSPENDED]: { color: 'info', label: status },
			[PatientStatus.BLOCKED]: { color: 'error', label: status },
		}),
		[status]
	);

	const isValidStatus = (s: string): s is ActivePatientStatus => Object.hasOwn(statusConfigs, s);

	if (!isValidStatus(status)) {
		return <Box sx={commonStyles}>{status}</Box>;
	}

	return (
		<Chip
			{...props}
			component="span"
			label={status}
			size="small"
			color={statusConfigs[status].color}
			sx={commonStyles}
		/>
	);
}

export default React.memo(PatientStatusChip);
