import React from 'react';
import { Alert, Box, List, ListItem, ListItemText, Typography, Stack } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageWithPreview from 'components/common/imageWithPreview/imageWithPreview';
import RefillsStatusChip from 'components/common/refillsStatusChip/RefillsStatusChip';
import { IMedication, MedicationRefillAlert } from 'core/api/medications/medications.models';
import { mapMedicationOnboardingAlert } from 'core/api/medications/medications.api';
import eventService from 'services/eventService';
import {
	Medication,
	MedicationItem,
	MedicationItemImage,
	MedicationItemContent,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from './MedicationDetails.styles';

interface ListItemRefillsAlertMessageProps extends Pick<IMedication, 'refillAlert' | 'refillsRemaining'> {}

function ListItemRefillsAlertMessage({ refillAlert, refillsRemaining = 0 }: ListItemRefillsAlertMessageProps) {
	if (refillAlert === MedicationRefillAlert.NO_REFILLS) {
		return (
			<ListItem sx={{ marginTop: '10px' }}>
				<ListItemText
					variant="body1"
					secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.error.main } }}
					secondary="No refills. Please contact your prescriber. Your prescriber has not responded to our requests."
				/>
			</ListItem>
		);
	}

	if (refillAlert === MedicationRefillAlert.REFILL_DENIED) {
		return (
			<ListItem sx={{ marginTop: '10px' }}>
				<ListItemText
					variant="body1"
					secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.error.main } }}
					secondary="Refill denied. Please contact your prescriber. Your prescriber will not approve this refill without speaking to you."
				/>
			</ListItem>
		);
	}

	if (refillsRemaining! <= 2) {
		return (
			<ListItem sx={{ marginTop: '10px' }}>
				<ListItemText
					variant="body1"
					secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.text.secondary } }}
					secondary="ExactCare is attempting to contact the prescriber to request additional refills. There is no action needed by you at this time."
				/>
			</ListItem>
		);
	}

	return null;
}

interface MedicationDetailsProps extends IMedication {}

export function MedicationDetails({
	onboardingAlerts,
	imageUrl,
	name,
	rxNumber,
	status,
	refillsStatus,
	refillsStatusAlertLevel,
	refillAlert,
	refillsRemaining,
	directions,
	whenToTake,
	prescriberName,
	prescriberPhone,
	commonUses,
	howToUse,
	sideEffects,
	precautions,
}: MedicationDetailsProps) {
	const emitEventOnOpenedTab = (action: string) => (event: React.SyntheticEvent, expanded: boolean) => {
		if (expanded) {
			eventService.emitEvent({ category: 'button', action });
		}
	};

	return (
		<Stack direction="column" gap={2}>
			{onboardingAlerts &&
				onboardingAlerts.map((alert) => (
					<Alert key={alert} severity="error" icon={<ErrorIcon color="error" />}>
						{mapMedicationOnboardingAlert(alert)}
					</Alert>
				))}
			<Medication>
				<MedicationItem>
					<MedicationItemImage>
						<ImageWithPreview
							source={imageUrl}
							title={name}
							onClick={() =>
								eventService.emitEvent({ category: 'imageWithPreview', action: 'medicationZoomOnPicture' })
							}
						>
							<Box component="img" src={imageUrl || ''} alt={name || ''} />
						</ImageWithPreview>
					</MedicationItemImage>
					<MedicationItemContent>
						<List>
							<ListItem>
								<ListItemText variant="body1" primary="Rx Number:" secondary={rxNumber || 'N/A'} />
							</ListItem>
							<ListItem>
								<ListItemText variant="body1" primary="Status:" secondary={status} />
							</ListItem>
							<ListItem>
								<ListItemText
									variant="body1"
									primary="Refills:"
									secondary={
										<RefillsStatusChip
											refillsStatus={refillsStatus}
											refillsStatusAlertLevel={refillsStatusAlertLevel}
										/>
									}
								/>
							</ListItem>
							<ListItemRefillsAlertMessage {...{ refillsRemaining, refillAlert }} />

							<ListItem sx={{ marginTop: '20px' }}>
								<ListItemText variant="body1" primary="Directions:" secondary={directions} />
							</ListItem>
							<ListItem>
								<ListItemText variant="body1" primary="When to Take:" secondary={whenToTake} />
							</ListItem>

							<ListItem sx={{ marginTop: '20px' }}>
								<ListItemText variant="body1" primary="Prescriber:" secondary={prescriberName} />
							</ListItem>
							<ListItem>
								<ListItemText variant="body1" primary="Prescriber Phone:" secondary={prescriberPhone} />
							</ListItem>
							<ListItem>
								<ListItemText
									variant="body1"
									primary="Note:"
									secondary="If this is not your prescriber, please contact ExactCare at (877) 355-7225."
								/>
							</ListItem>
						</List>
					</MedicationItemContent>
				</MedicationItem>
				<Box sx={{ marginTop: '20px' }}>
					<Accordion onChange={emitEventOnOpenedTab('openedTabCommonUses')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Common Uses</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>{commonUses}</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion onChange={emitEventOnOpenedTab('openedTabHowToUse')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>How to Use</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>{howToUse}</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion onChange={emitEventOnOpenedTab('openedTabSideEffects')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Side Effects</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>{sideEffects}</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion onChange={emitEventOnOpenedTab('openedTabPrecautions')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Precautions</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>{precautions}</Typography>
						</AccordionDetails>
					</Accordion>
				</Box>
			</Medication>
		</Stack>
	);
}

export default MedicationDetails;
