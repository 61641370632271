import { StorageService } from './storageService';

export class LocalStorageService extends StorageService {
	constructor(prefix?: string) {
		super(window.localStorage, prefix);
	}
}

export const localStorageService = new LocalStorageService();
export default localStorageService;
