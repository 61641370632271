import React from 'react';
import { Tab } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { InboxMessagesTabsStyled } from './InboxMessagesTabs.styles';

interface InboxMessagesTabsProps {
	value: string;
	readonly tabs: {
		label: string;
		key: string;
		to: string;
	}[];
}

export function InboxMessagesTabs({ value, tabs }: InboxMessagesTabsProps) {
	return (
		<InboxMessagesTabsStyled value={value}>
			{tabs.map(({ key, label, to }) => (
				<Tab
					key={key}
					value={key}
					label={label}
					component={RouterLink}
					to={to}
					state={{ showSidebarNavigation: false }}
				/>
			))}
		</InboxMessagesTabsStyled>
	);
}

export default InboxMessagesTabs;
