import React, { useState } from 'react';
import { Link as RouterLink, UIMatch, useLocation, useMatches, useParams } from 'react-router-dom';
import { Box, MenuItem, Toolbar, Link, Divider, Badge, Stack, useMediaQuery, Theme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import routes from 'routes';
import AnewHealthExactCareLightLogo from 'components/common/logo/AnewHealthExactCareLightLogo';
import HideOnScroll from 'components/common/HideOnScroll';
import { SUPPORT_URLS } from 'constants/urls';
import useShowSidebarNavigation from 'hooks/patient/useShowSidebarNavigation';
import { camelize, isActiveRoute } from 'utils';
import {
	MobileTopAppBar,
	MobileBottomAppBar,
	DesktopAppBar,
	AccountMenu,
	MobileBackLink,
	MobileLogo,
	MobileHelpLink,
	MobileToolbarSpacer,
	MobileMenuLink,
	DesktopLogo,
	DesktopMenuButton,
} from './Header.styles';

interface MainMenuItem {
	label: string;
	to: string;
	icon?: React.FC;
	notifications?: number | string;
	activeRoutes?: string[];
	target?: string;
}

interface HeaderProps {
	mainMenu: MainMenuItem[];
	onLogout: () => void;
	accountBalance: string | null;
	accountName: string | null;
}

function MobileHeader({ mainMenu }: Omit<HeaderProps, 'onLogout'>) {
	const matches = useMatches() as UIMatch<
		unknown,
		{
			back: ({ params }: any) => {
				name: string;
				to: string;
				state: { showSidebarNavigation?: boolean };
			};
		}
	>[];
	const location = useLocation();
	const queryParams = useParams();
	const showSidebarNavigation = useShowSidebarNavigation();

	const backButton = matches.find((match) => Boolean(match.handle?.back))?.handle?.back({ params: queryParams });
	const showLogo = location.pathname === routes.home;
	const isAccountActive = isActiveRoute(location.pathname, [
		routes.account.personalInformation,
		routes.account.paymentsAndBilling,
		routes.account.notificationsPreferences,
	]);

	return (
		<>
			<HideOnScroll>
				<MobileTopAppBar sx={{ height: showLogo ? '110px' : '76px' }}>
					<Toolbar>
						<Stack gap={2}>
							{!showSidebarNavigation && backButton && (
								<MobileBackLink component={RouterLink} to={backButton.to} state={backButton.state}>
									<ArrowBackIosIcon />
									{backButton.name}
								</MobileBackLink>
							)}
							{showLogo && (
								<MobileLogo component={RouterLink} to={routes.home}>
									<AnewHealthExactCareLightLogo />
								</MobileLogo>
							)}
						</Stack>
						<MobileHelpLink href={SUPPORT_URLS.HELP_CENTER} target="_blank">
							<HelpOutlineIcon />
						</MobileHelpLink>
					</Toolbar>
				</MobileTopAppBar>
			</HideOnScroll>
			<MobileToolbarSpacer $showLogo={showLogo} />
			<MobileBottomAppBar position="fixed" data-testid="mobile-app-bar" className="EcaBox-mainHeader">
				<Toolbar>
					{mainMenu
						.filter((item) => item.icon)
						.map(({ label, to, icon, notifications, activeRoutes, target }) => (
							<MobileMenuLink
								component={RouterLink}
								key={label}
								to={to}
								className={`EcaLink-${camelize(label)} ${isActiveRoute(location.pathname, activeRoutes) ? 'active' : ''}`}
								target={target}
							>
								{notifications && notifications !== 0 ? (
									<Badge badgeContent={notifications} color="error" max={9}>
										<Box className="iconButton">{icon && React.createElement(icon)}</Box>
									</Badge>
								) : (
									<Box className="iconButton">{icon && React.createElement(icon)}</Box>
								)}
								{label}
							</MobileMenuLink>
						))}
					<MobileMenuLink to={routes.account.personalInformation} className={isAccountActive ? 'active' : ''}>
						<Box className="iconButton">
							<AccountCircleOutlinedIcon />
						</Box>
						Account
					</MobileMenuLink>
				</Toolbar>
			</MobileBottomAppBar>
		</>
	);
}

function DesktopHeader({ mainMenu, onLogout, accountBalance, accountName }: HeaderProps) {
	const location = useLocation();
	const [anchorAccountMenu, setAnchorAccountMenu] = useState<Element | null>(null);

	const handleAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorAccountMenu(event.currentTarget);
	};

	const handleAccountMenuClose = () => {
		setAnchorAccountMenu(null);
	};

	return (
		<DesktopAppBar data-testid="desktop-app-bar" className="EcaBox-mainHeader">
			<Toolbar>
				<Box sx={{ display: 'flex' }}>
					<DesktopLogo component={RouterLink} to={routes.home}>
						<AnewHealthExactCareLightLogo />
					</DesktopLogo>
				</Box>
				<Box sx={{ flexGrow: 1 }} />
				<Box className="MainMenu">
					{mainMenu.map(({ label, to, notifications, activeRoutes, target }) => (
						<React.Fragment key={label}>
							{notifications && notifications !== 0 ? (
								<Badge badgeContent={notifications} color="error" max={9}>
									<Link
										component={RouterLink}
										to={to}
										className={`EcaLink-${camelize(label)} ${isActiveRoute(location.pathname, activeRoutes) ? 'active' : ''}`}
										target={target}
									>
										{label}
									</Link>
								</Badge>
							) : (
								<Link
									component={RouterLink}
									to={to}
									className={`EcaLink-${camelize(label)} ${isActiveRoute(location.pathname, activeRoutes) ? 'active' : ''}`}
									target={target}
								>
									{label}
								</Link>
							)}
						</React.Fragment>
					))}
				</Box>
				<DesktopMenuButton
					id="account-button"
					variant="text"
					aria-controls={anchorAccountMenu ? 'account-menu' : undefined}
					aria-expanded={anchorAccountMenu ? 'true' : undefined}
					aria-haspopup="true"
					onClick={handleAccountMenu}
					endIcon={<KeyboardArrowDownIcon />}
				>
					<AccountCircleOutlinedIcon sx={{ marginRight: '5px', color: 'grey.500' }} /> {accountName ?? 'N/A'}
				</DesktopMenuButton>
				<AccountMenu
					anchorEl={anchorAccountMenu}
					open={Boolean(anchorAccountMenu)}
					onClose={handleAccountMenuClose}
					id="account-menu"
					aria-labelledby="account-button"
				>
					<MenuItem
						component={RouterLink}
						to={routes.account.personalInformation}
						selected={isActiveRoute(location.pathname, [routes.account.personalInformation])}
					>
						Personal Info
					</MenuItem>
					<MenuItem
						component={RouterLink}
						to={routes.account.notificationsPreferences}
						selected={isActiveRoute(location.pathname, [routes.account.notificationsPreferences])}
					>
						Notifications
					</MenuItem>
					{accountBalance && (
						<MenuItem
							className="Eca-account-balance"
							component={RouterLink}
							to={routes.account.paymentsAndBilling}
							selected={isActiveRoute(location.pathname, [routes.account.paymentsAndBilling])}
						>
							Balance: {accountBalance}
						</MenuItem>
					)}
					<Divider />
					<MenuItem onClick={onLogout}>Sign Out</MenuItem>
				</AccountMenu>
			</Toolbar>
		</DesktopAppBar>
	);
}

export default function Header(props: HeaderProps) {
	const upDesktopScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

	return upDesktopScreen ? <DesktopHeader {...props} /> : <MobileHeader {...props} />;
}
