import React from 'react';
import { Box, BoxProps, Stack } from '@mui/material';
import { CardContent, CardHeader, CardTitle } from './ContentCard.styles';

interface IContentCardProps extends Omit<BoxProps, 'children' | 'title'> {
	children: React.ReactNode;
	icon?: React.ReactNode | string;
	title?: React.ReactNode | string;
	actions?: React.ReactNode | string;
	disableTitleTypography?: boolean;
}

export function ContentCard({
	children,
	icon,
	title,
	disableTitleTypography,
	actions,
	...restProps
}: IContentCardProps) {
	return (
		<Box {...restProps}>
			{title && (
				<CardHeader>
					<Stack direction="row" gap="16px" alignItems="center">
						{icon}
						{disableTitleTypography && title}
						{!disableTitleTypography && <CardTitle variant="h1">{title}</CardTitle>}
					</Stack>
					{actions}
				</CardHeader>
			)}
			<CardContent>{children}</CardContent>
		</Box>
	);
}
