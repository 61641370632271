import React from 'react';
import { Grid } from '@mui/material';
import { PatientNotificationList } from 'components/admin';
import { useParams } from 'react-router-dom';

function PatientsNotificationsPage() {
	const { patientId } = useParams();

	return (
		<Grid item xs={12}>
			<PatientNotificationList userId={Number(patientId)} />
		</Grid>
	);
}

export default PatientsNotificationsPage;
