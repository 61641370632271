import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { AuthError, InternalServerError } from 'core/errors';
import { useNavigate, useRouteError, isRouteErrorResponse } from 'react-router-dom';
import AppErrorPage from 'pages/errors/AppErrorPage';
import UnauthorizedAccessPage from 'pages/errors/UnauthorizedAccessPage';
import NotFoundPage from 'pages/errors/NotFoundPage';

export function ErrorBoundary() {
	const error = useRouteError();
	const navigate = useNavigate();

	const handleClose = () => {
		navigate(-1);
	};

	if (isRouteErrorResponse(error)) {
		return <NotFoundPage />;
	}

	if (error instanceof AuthError) {
		return <UnauthorizedAccessPage />;
	}

	if (error instanceof InternalServerError) {
		return (
			<Snackbar open autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					Something went wrong
				</Alert>
			</Snackbar>
		);
	}

	return <AppErrorPage />;
}
