import { useMemo } from 'react';
import {
	WalkThroughScreen,
	WalkThroughScreenKeys,
	WalkThroughStep,
} from 'components/patient/common/walkThrough/WalkThrough';
import { useUserMetaDataList } from 'hooks/patient/useUserMetaData';
import { UserMetaDataKeys } from 'core/api/userAccount/userAccount.models';

export const useMyScheduleTodaysMedicationWalkThrough = (props?: {
	isSkipped: boolean;
	isActive: boolean;
}): WalkThroughScreen => {
	const { data: userMetaDataWT } = useUserMetaDataList({ key: UserMetaDataKeys.WALKTHROUGH });
	const screensCompleted = useMemo(
		() => userMetaDataWT?.member?.[0]?.value.split(',') || [],
		[userMetaDataWT]
	) as WalkThroughScreenKeys[];

	const screenKey: WalkThroughScreenKeys[] = [];
	const steps: WalkThroughStep[] = [];

	if (!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED) && !!props?.isSkipped) {
		screenKey.push(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED);
	}
	if (!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE) && !!props?.isActive) {
		screenKey.push(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE);
	}
	if (!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SCHEDULED_TIME)) {
		screenKey.push(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SCHEDULED_TIME);
	}

	if (
		!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED) &&
		!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE) &&
		!screensCompleted.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SCHEDULED_TIME)
	) {
		steps.push({
			target: 'body',
			title: 'Track the medication you have taken for the day.',
			content: '',
			placement: 'center',
			disableBeacon: true,
		});
	}

	if (screenKey.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SKIPPED)) {
		steps.push({
			target: '.EcaLink-ScheduleListItem-passed',
			title: 'Tap an earlier time to mark medications as taken or skipped.',
			content: '',
			disableBeacon: true,
		});
	}

	if (screenKey.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_ACTIVE)) {
		steps.push({
			target: '.EcaPage-TodaysMedDetailsPage',
			title: 'You can mark that you have taken all your medications or that you skipped one for that time of the day.',
			content: '',
			disableBeacon: true,
		});
	}

	if (screenKey.includes(WalkThroughScreenKeys.MY_SCHEDULE_TODAY_MEDS_SCHEDULED_TIME)) {
		steps.push({
			target: '.EcaMedTableRow-root',
			title: 'Toggle the buttons to track which medication you have taken for the day.',
			content: 'Green means taken. Gray means skipped.',
			disableBeacon: true,
		});
	}

	if (steps.length > 0) {
		steps[steps.length - 1].disableOverlayClose = false;
	}

	return {
		screenKey,
		steps,
	} as WalkThroughScreen;
};
