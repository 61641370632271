import React, { useCallback } from 'react';
import { Dayjs } from 'dayjs';
import { Typography } from '@mui/material';
import { useMonthRange, useMedHistoryQuery } from 'hooks';
import ContentCard from 'components/patient/common/ContentCard';
import { Scheduler } from 'components/patient/mySchedule';
import { generatePath, useNavigate } from 'react-router-dom';
import routes from 'routes';

export function ReviewMyHistoryPage() {
	const navigate = useNavigate();
	const [fromDate, toDate, setMonthRange] = useMonthRange();
	const { data: medHistory, isLoading: medHistoryIsLoading } = useMedHistoryQuery({
		from: fromDate.format('YYYY-MM-DD'),
		to: toDate.format('YYYY-MM-DD'),
	});

	const handleDateClick = useCallback((daySelected: Dayjs | null) => {
		if (daySelected) {
			navigate(generatePath(routes.mySchedule.reviewMyHistory.day, { date: daySelected.format('YYYY-MM-DD') }));
		}
	}, []);

	const handleMonthChange = useCallback(
		(date: Dayjs | null) => {
			if (date) {
				setMonthRange(date);
			}
		},
		[setMonthRange]
	);

	return (
		<ContentCard title="Manage My History">
			<Typography variant="body1" color="text.secondary" sx={{ display: { xs: 'none', sm: 'flex' } }}>
				Choose a day and update your daily log.
			</Typography>
			<Scheduler
				data={medHistory?.days}
				isLoading={medHistoryIsLoading}
				onDateClick={handleDateClick}
				onMonthChange={handleMonthChange}
			/>
		</ContentCard>
	);
}
