import React from 'react';
import RegisterProvider from './RegisterProvider';
import StepContent from './StepContent';

export function Register(): JSX.Element {
	return (
		<RegisterProvider>
			<StepContent />
		</RegisterProvider>
	);
}

export default Register;
