import React, { useMemo } from 'react';
import routes from 'routes';
import { generatePath } from 'react-router-dom';
import dayjs from 'dayjs';
import { PaletteOptions, Stack, Typography } from '@mui/material';
import { useAppContext, useUserContext } from 'contexts/RootProvider';
import useWeekDays, { IDayDetails } from 'hooks/common/useWeekDays/useWeekDays';
import { MainSidebarMenu, ContentCard } from 'components/patient/common';
import ManageMedicationRemindersIcon from 'components/common/icons/ManageMedicationRemindersIcon';
import { RefillIcon } from 'components/common/icons/RefillIcon';
import { BillingIcon } from 'components/common/icons/BillingIcon';
import { MailIcon } from 'components/common/icons/MailIcon';
import { CalendarWeek, CalendarWeekDay, CalendarWeekLegend } from 'components/common/calendarWeek';

type MedsLoggedThisWeek = IDayDetails & { color?: keyof PaletteOptions };

function HomeSidebar() {
	const { firstName, balance } = useUserContext();
	const { homepage, badges, anyOrderDelivered, currentOrderToday } = useAppContext();
	const currentDate = dayjs().format('dddd, MMMM D');

	const medsThisWeek = homepage?.medsThisWeek || [];
	const weekDays = useWeekDays();

	const medsLoggedThisWeek: MedsLoggedThisWeek[] = useMemo(() => {
		return weekDays.map((day) => {
			const medThisWeek = medsThisWeek.find((med) => med.day === day.day.format('YYYY-MM-DD'));
			let color: keyof PaletteOptions | undefined;

			if (medThisWeek?.status === 'logged') {
				color = 'success';
			} else if (medThisWeek?.status === 'incomplete') {
				color = 'warning';
			}

			return {
				...day,
				color,
			};
		});
	}, [weekDays, medsThisWeek]);

	const menuItems = [
		{
			label: 'Messages',
			icon: <MailIcon fontSize="large" />,
			to: generatePath(routes.inbox.list, { tab: 'all' }),
			state: { showSidebarNavigation: false },
			activeRoutes: [routes.home, routes.inbox.list],
			helperText: (
				<Typography variant="body1" color="grey.600">
					{!!badges.unreadMessagesBadge && `${badges.unreadMessagesBadge} Unread`}
				</Typography>
			),
		},
		{
			label: 'Refill Alerts',
			icon: <RefillIcon fontSize="large" />,
			to: generatePath(routes.medication),
			state: { showSidebarNavigation: false },
			activeRoutes: [routes.medication],
			alert: !!badges.refillAlertsBadge,
			helperText: (
				<Typography variant="body1" color="grey.600">
					{(!!badges.refillAlertsBadge && 'Action Required') || 'None'}
				</Typography>
			),
		},
		{
			label: 'Set Medication Reminders',
			icon: <ManageMedicationRemindersIcon fontSize="large" />,
			to: routes.mySchedule.manageMedicationReminders,
			activeRoutes: [routes.mySchedule.manageMedicationReminders],
			disabled: !anyOrderDelivered || !currentOrderToday,
		},
		{
			label: 'Manage My History',
			to: routes.mySchedule.reviewMyHistory.calendar,
			activeRoutes: [routes.mySchedule.reviewMyHistory.calendar],
			disableArrow: true,
			disabled: !anyOrderDelivered,
			helperText: (
				<Stack direction="column" gap="12px" py="12px">
					<CalendarWeek>
						{medsLoggedThisWeek.map(({ day, today, color }) => (
							<CalendarWeekDay
								key={day.format('ddd')}
								day={day.date()}
								dayLabel={day.format('ddd')}
								showWeekDayLabel
								{...{ today, color }}
							/>
						))}
					</CalendarWeek>
					<CalendarWeekLegend
						items={[
							{ label: 'Logged', color: 'success' },
							{ label: 'Incomplete', color: 'warning' },
						]}
					/>
				</Stack>
			),
		},
		{
			label: 'Current Balance',
			icon: <BillingIcon fontSize="large" />,
			to: routes.account.paymentsAndBilling,
			activeRoutes: [routes.account.paymentsAndBilling],
			helperText: (
				<Typography variant="body1" color="grey.600">
					{balance}
				</Typography>
			),
		},
	];

	return (
		<ContentCard
			disableTitleTypography
			title={
				<Stack gap="5px" alignItems="flex-start" direction="column">
					<Typography variant="h3" fontWeight="bold" color="text.secondary">
						Welcome, {firstName}
					</Typography>
					<Typography variant="body1">{currentDate}</Typography>
				</Stack>
			}
		>
			<MainSidebarMenu menuItems={menuItems} />
		</ContentCard>
	);
}

export default HomeSidebar;
