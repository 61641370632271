import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ClockIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 50 50" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM25 45C13.9543 45 5 36.0457 5 25C5 13.9543 13.9543 5 25 5C36.0457 5 45 13.9543 45 25C45 36.0457 36.0457 45 25 45ZM27.5 22.5H35C36.3807 22.5 37.5 23.6193 37.5 25C37.5 26.3807 36.3807 27.5 35 27.5H25C23.6193 27.5 22.5 26.3807 22.5 25V15C22.5 13.6193 23.6193 12.5 25 12.5C26.3807 12.5 27.5 13.6193 27.5 15V22.5Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
