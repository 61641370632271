import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Suspense from 'components/common/Suspense';
import { MedicationSummary, RefillAlerts, RefillRequestForm } from 'components/patient/medication';
import { useGetMedications, useGetMedication, useRequestRefill } from 'hooks/patient/useMedications';
import Dialog from 'components/common/dialog';
import { useDisclosure } from 'hooks';
import { medsGroupedByAlerts } from 'core/api/medications/medications.api';
import { IMedication } from 'core/api/medications/medications.models';

function MedicationPage() {
	const { sfid } = useParams();
	const { data: medicationSummaryData, isLoading: medicationSummaryIsLoading } = useGetMedication({ sfid });
	const { data, isLoading: medicationListIsLoading } = useGetMedications();
	const requestRefillMutation = useRequestRefill();
	const medications = data?.medications || [];
	const {
		isOpen: isOpenRefillRequestFormDialog,
		onOpen: onOpenRefillRequestFormDialog,
		onClose: onCloseRefillRequestFormDialog,
	} = useDisclosure();

	const handleRequestRefillFromExactCare = () => sfid && requestRefillMutation.mutate({ sfid });

	const medsGroupedByAlertsData = useMemo(() => medsGroupedByAlerts(medications), [medications]);
	const totalAlerts = useMemo(() => medications.filter((med: IMedication) => med.refillAlert).length, [medications]);

	if (!sfid) {
		return (
			<Suspense isLoading={medicationListIsLoading}>
				<RefillAlerts
					onOpenRefillRequestForm={onOpenRefillRequestFormDialog}
					medsGroupedByAlerts={medsGroupedByAlertsData}
					totalAlerts={totalAlerts}
				/>
				<Dialog
					title="Refill Request Form"
					scroll="paper"
					fullWidth
					maxWidth="xs"
					open={isOpenRefillRequestFormDialog}
					onClose={onCloseRefillRequestFormDialog}
				>
					<RefillRequestForm medsGroupedByAlerts={medsGroupedByAlertsData} />
				</Dialog>
			</Suspense>
		);
	}

	return (
		<Suspense isLoading={medicationSummaryIsLoading && !medicationSummaryData}>
			{medicationSummaryData && (
				<MedicationSummary
					onRequestRefillFromExactCare={handleRequestRefillFromExactCare}
					requestRefillFromExactCareIsSuccess={requestRefillMutation.isSuccess}
					requestRefillFromExactCareIsLoading={requestRefillMutation.isPending}
					resetRequestRefillFromExactCare={requestRefillMutation.reset}
					medicationSummaryData={medicationSummaryData}
				/>
			)}
		</Suspense>
	);
}

export default MedicationPage;
