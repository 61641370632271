import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	changePassword,
	changeDateOfBirth,
	changeEmail,
	deletePatient,
	suspendPatient,
	unblockPatient,
	changeBypassAuthCode,
} from 'core/api/admin/patients.api';
import {
	IChangeEmailPatientRequest,
	IChangePasswordPatientRequest,
	IPatientsListRequest,
	PatientChangePasswordFormFields,
} from 'core/api/admin/patients.models';
import { IApiError } from 'core/errors/ApiError';
import { adminPatientQueries } from 'queries/admin';

export function usePatientsListQuery(props: IPatientsListRequest) {
	return useQuery(adminPatientQueries.patientsList(props));
}

export function usePatientMutations() {
	const queryClient = useQueryClient();

	const invalidatePatientQueries = () => {
		queryClient.invalidateQueries({
			queryKey: ['admin', 'patients'],
			refetchType: 'all',
		});
	};

	const suspendPatientMutation = useMutation({
		mutationFn: suspendPatient,
		onSettled: () => {
			invalidatePatientQueries();
		},
	});

	const unblockPatientMutation = useMutation({
		mutationFn: unblockPatient,
		onSettled: () => {
			invalidatePatientQueries();
		},
	});

	const deletePatientMutation = useMutation({
		mutationFn: deletePatient,
		onSettled: () => {
			invalidatePatientQueries();
		},
	});

	const changeEmailMutation = useMutation<
		void,
		IApiError<Record<keyof IChangeEmailPatientRequest, string>>,
		IChangeEmailPatientRequest,
		unknown
	>({
		mutationFn: changeEmail,
		onSettled: () => {
			invalidatePatientQueries();
		},
	});

	const changeDateOfBirthMutation = useMutation({
		mutationFn: changeDateOfBirth,
		onSettled: () => {
			invalidatePatientQueries();
		},
	});

	const changePatientPasswordMutation = useMutation<
		void,
		IApiError<Record<keyof PatientChangePasswordFormFields, string>>,
		IChangePasswordPatientRequest,
		unknown
	>({
		mutationFn: changePassword,
		onSettled: () => {
			invalidatePatientQueries();
		},
	});

	const changeBypassAuthCodeMutation = useMutation({
		mutationFn: changeBypassAuthCode,
		onSettled: () => {
			invalidatePatientQueries();
		},
	});

	return {
		suspendPatientMutation,
		unblockPatientMutation,
		deletePatientMutation,
		changeEmailMutation,
		changeDateOfBirthMutation,
		changePatientPasswordMutation,
		changeBypassAuthCodeMutation,
	};
}

export default {
	usePatientMutations,
	usePatientsListQuery,
};
