import { useQuery, useMutation } from '@tanstack/react-query';
import { medicationQueries } from 'queries/patient/medicationQueries';
import { IGetDeliveriesSummaryRequestParams } from 'core/api/delivery/delivery.models';
import { requestRefill } from 'core/api/medications/medications.api';

export function useGetMedications() {
	return useQuery(medicationQueries.getMedications());
}

export function useGetMedication(props: Partial<IGetDeliveriesSummaryRequestParams>) {
	return useQuery(medicationQueries.getMedication(props));
}

export function useRequestRefill() {
	return useMutation({
		mutationFn: requestRefill,
	});
}

export default {
	useGetMedications,
	useGetMedication,
	useRequestRefill,
};
