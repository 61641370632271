import React from 'react';
import { List, ListItem, ListItemText, Chip, Stack, Typography } from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { PaperTitle, Paper } from 'components/common/dashboardLayout';
import { usePatientDetailsQuery } from 'hooks/enterprise';

interface PatientContactsWidgetProps {
	patientId?: string;
}

export function PatientContactsWidget({ patientId }: PatientContactsWidgetProps) {
	const { data } = usePatientDetailsQuery({ id: patientId });

	return (
		<Paper>
			<PaperTitle sx={{ mb: 0 }}>Personal Contacts</PaperTitle>
			<List dense sx={{ maxHeight: '290px', overflow: 'auto' }}>
				{data?.patientPersonalContacts.map((contact) => (
					<ListItem
						dense
						disableGutters
						key={`contact-${contact.sfid}`}
						sx={{ borderTop: '1px solid', borderColor: 'divider' }}
					>
						<ListItemText
							disableTypography
							primary={
								<Typography fontWeight={700} mb={1}>
									{contact.fullName}
								</Typography>
							}
							secondary={
								<Stack direction="column" spacing={1} alignItems="flex-start">
									{contact.phone && (
										<Chip
											variant="outlined"
											icon={<LocalPhoneOutlinedIcon />}
											label={contact.phone}
											size="small"
											sx={{ px: 2, width: '100%', justifyContent: 'flex-start' }}
										/>
									)}
									{contact.email && (
										<Chip
											variant="outlined"
											icon={<EmailOutlinedIcon />}
											label={contact.email}
											size="small"
											sx={{ px: 2, width: '100%', justifyContent: 'flex-start' }}
										/>
									)}
								</Stack>
							}
						/>
					</ListItem>
				))}
			</List>
		</Paper>
	);
}

export default PatientContactsWidget;
