import React from 'react';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routes';

export default function AppErrorPage() {
	return (
		<Container
			maxWidth="sm"
			sx={{
				textAlign: 'center',
				gap: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			<Stack>
				<Typography component="h1" variant="h2" fontWeight={700}>
					Application Error
				</Typography>
			</Stack>
			<Typography fontSize={18}>An error occurred in the application and the page could not be served.</Typography>
			<Button to={routes.home} component={RouterLink} variant="contained">
				Go to Homepage
			</Button>
		</Container>
	);
}
