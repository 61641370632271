import { Box, CardContent as MuiCardContent, Typography, styled } from '@mui/material';

export const CardTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
	fontWeight: 700,
	flex: '1 1 auto',
}));

export const CardHeader = styled(Box)(({ theme }) => ({
	paddingBottom: '24px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'flex-start',

	'&  svg': {
		fontSize: '2.4rem',
	},

	[theme.breakpoints.down('sm')]: {
		padding: '6px 12px 12px 12px',
		flexDirection: 'column',
		alignItems: 'stretch',
		gap: '6px',
	},
}));

export const CardContent = styled(MuiCardContent)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	gap: '16px',
	padding: 0,
	height: '100%',
	[theme.breakpoints.down('sm')]: {
		padding: '12px',
	},
}));
