import React from 'react';
import { Stack, Alert, Button, TextField } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IPatientResponse } from 'core/api/admin/patients.models';
import { usePatientMutations } from 'hooks/admin';

const schema = Joi.object({
	bypassAuthCode: Joi.string()
		.pattern(/^\d{6}$/)
		.messages({
			'string.pattern.base': 'Bypass auth code must be exactly 6 digits',
		}),
});

interface PatientChangeBypassAuthCodeForm {
	bypassAuthCode: string | null;
}

interface PatientChangeBypassAuthCodeProps {
	patient: IPatientResponse;
	onSuccess: () => void;
}

export function PatientChangeBypassAuthCode({ patient, onSuccess }: PatientChangeBypassAuthCodeProps) {
	const { changeBypassAuthCodeMutation } = usePatientMutations();

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<PatientChangeBypassAuthCodeForm>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			bypassAuthCode: '',
		},
	});

	const handleSubmitChangeBypassAuthCode = (data: PatientChangeBypassAuthCodeForm) => {
		changeBypassAuthCodeMutation.mutate(
			{
				userId: patient.userId.toString(),
				bypassAuthCode: data.bypassAuthCode ?? null,
			},
			{ onSuccess }
		);
	};

	return (
		<Stack gap={2} mt={2} component="form" onSubmit={handleSubmit(handleSubmitChangeBypassAuthCode)}>
			{changeBypassAuthCodeMutation.error?.message && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{changeBypassAuthCodeMutation.error.message}
				</Alert>
			)}
			<Controller
				name="bypassAuthCode"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...field}
						value={field.value ?? ''}
						label="Bypass auth code"
						placeholder="Enter 6 digits or leave empty to remove"
						variant="outlined"
						size="small"
						fullWidth
						helperText={error?.message}
						error={!!error}
						inputProps={{
							maxLength: 6,
							pattern: '\\d*',
						}}
					/>
				)}
			/>
			<Button
				disabled={!isValid || changeBypassAuthCodeMutation.isPending}
				variant="outlined"
				color="primary"
				fullWidth
				type="submit"
			>
				{changeBypassAuthCodeMutation.isPending ? 'Loading...' : 'Save'}
			</Button>
		</Stack>
	);
}

export default PatientChangeBypassAuthCode;
