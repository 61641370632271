import { Box, styled } from '@mui/material';

export const PrescriberList = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	gap: 15,
}));

export const PrescriberListItem = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
}));

export const PrescriberListItemName = styled(Box)(() => ({}));
