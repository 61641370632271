import React from 'react';
import { Alert, Button, Stack } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IPatientResponse } from 'core/api/admin/patients.models';
import { usePatientMutations } from 'hooks/admin';

const schema = Joi.object({
	dateOfBirth: Joi.string().required().messages({
		'string.empty': 'Date of birth cannot be empty',
		'any.required': 'Date of birth is required',
	}),
});

interface PatientChangeDateOfBirthForm {
	dateOfBirth: string;
}

interface PatientChangeDateOfBirthProps {
	patient: IPatientResponse;
	onSuccess: () => void;
}

export function PatientChangeDateOfBirth({ patient, onSuccess }: PatientChangeDateOfBirthProps) {
	const { changeDateOfBirthMutation } = usePatientMutations();

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<PatientChangeDateOfBirthForm>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			dateOfBirth: patient?.dateOfBirth ?? '',
		},
	});

	const handleSubmitChangeDateOfBirth = (data: PatientChangeDateOfBirthForm) => {
		changeDateOfBirthMutation.mutate(
			{
				userId: patient.userId.toString(),
				dateOfBirth: data.dateOfBirth,
			},
			{ onSuccess }
		);
	};

	return (
		<Stack gap={2} mt={2} component="form" onSubmit={handleSubmit(handleSubmitChangeDateOfBirth)}>
			{changeDateOfBirthMutation.error?.message && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{changeDateOfBirthMutation.error.message}
				</Alert>
			)}
			<Controller
				name="dateOfBirth"
				control={control}
				render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
					<DateField
						{...fieldProps}
						value={value ? dayjs(value) : null}
						onChange={(newValue) => {
							onChange(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
						}}
						label="New date of birth"
						slotProps={{
							textField: {
								size: 'small',
								variant: 'outlined',
								fullWidth: true,
								error: !!error,
								helperText: error?.message,
							},
						}}
					/>
				)}
			/>
			<Button
				disabled={!isValid || changeDateOfBirthMutation.isPending}
				variant="outlined"
				color="primary"
				fullWidth
				type="submit"
			>
				{changeDateOfBirthMutation.isPending ? 'Loading...' : 'Save'}
			</Button>
		</Stack>
	);
}

export default PatientChangeDateOfBirth;
