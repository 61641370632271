import React, { useMemo } from 'react';
import { Link, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {
	IGetDeliveriesListResponse,
	IGetDeliveriesSummaryResponse,
	SFOrderStatus,
} from 'core/api/delivery/delivery.models';
import { ContentCard } from 'components/patient/common/ContentCard';
import { SUPPORT_URLS } from 'constants/urls';
import { DeliveryRecordType } from 'core/models/delivery/delivery.models';

interface Message {
	condition: boolean;
	title?: string;
	message?: React.ReactNode;
	icon?: React.ReactNode;
}

interface DeliveriesMessageProps {
	deliveriesList: IGetDeliveriesListResponse;
	deliverySummary?: IGetDeliveriesSummaryResponse;
}

export function DeliveriesMessage({ deliveriesList, deliverySummary }: DeliveriesMessageProps) {
	const { shippingAddress, firstRoutineOrderReceived, anyOrderReceived, vialsPatient } = deliveriesList;

	const messages: Message[] = useMemo(
		() => [
			// DEL-1
			{
				condition: !deliverySummary && firstRoutineOrderReceived === true,
				title: 'Deliveries',
				message: (
					<>
						<Typography variant="body1" fontWeight="bold" color="text.secondary">
							Delivery Address
						</Typography>
						<Typography variant="body1" color="text.secondary">
							This is where you will receive your medication deliveries:
						</Typography>
						<Typography variant="body1" color="text.secondary">
							{shippingAddress}
						</Typography>
						<Typography variant="body1" color="text.secondary">
							Call 1-877-355-7225 if the info above is incorrect or you are running low on any medication.
						</Typography>
					</>
				),
			},
			// DEL-2
			{
				condition: !deliverySummary && firstRoutineOrderReceived === false && anyOrderReceived === false,
				title: 'We Are Preparing Your First Deliveries',
				message: (
					<>
						<Typography variant="body1" color="text.secondary">
							You recently completed your Health and Medication Review with ExactCare!
						</Typography>
						<Typography variant="body1" fontWeight={700} color="text.secondary">
							Here is what will happen next:
						</Typography>
						<Typography variant="body1" color="text.secondary">
							<ul>
								<li>We will contact your doctors to get your prescriptions.</li>
								<li>
									We will line up your refill dates so that all of your prescriptions can be sent to you at the same
									time.
								</li>
								<li>
									We will call you with an important update about your upcoming deliveries and copayments, if you have
									any.
								</li>
							</ul>
						</Typography>
						<Typography variant="body1" color="text.secondary">
							Please call us if you have any questions!
						</Typography>
						<Typography variant="body1" fontWeight={700} color="text.secondary">
							1-877-355-7225
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-3
			{
				condition:
					!deliverySummary &&
					firstRoutineOrderReceived === false &&
					anyOrderReceived === true &&
					vialsPatient === false,
				title: 'You Are On Your Way To Your First ExactPack',
				message: (
					<>
						<Typography variant="body1" color="text.secondary">
							We are working to get your prescriptions from your doctors and line up your refill dates.
						</Typography>
						<Typography variant="body1" color="text.secondary">
							<ul>
								<li>This lets us fill your medications together every month.</li>
								<li>That is how we can package your doses by day and time in the ExactPack.</li>
								<li>You will likely receive some medications in vials until this is complete.</li>
								<li>There may also be less than a 30-day supply of medications.</li>
							</ul>
						</Typography>
						<Typography variant="body1" color="text.secondary">
							Don’t worry! This is a normal part of the process. Call 1-877-355-7225 if you have any questions!
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-4
			{
				condition:
					!deliverySummary && firstRoutineOrderReceived === false && anyOrderReceived === true && vialsPatient === true,
				title: 'You Are On Your Way To Your First Monthly Order',
				message: (
					<>
						<Typography variant="body1" color="text.secondary">
							We are working to get your prescriptions from your doctors and line up your refill dates.
						</Typography>
						<Typography variant="body1" color="text.secondary">
							<ul>
								<li>This lets us fill your medications together every month.</li>
								<li>There may also be less than a 30-day supply of medications.</li>
							</ul>
						</Typography>
						<Typography variant="body1" color="text.secondary">
							Don’t worry! This is a normal part of the process. Call 1-877-355-7225 if you have any questions!
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-C
			{
				condition:
					!!deliverySummary &&
					firstRoutineOrderReceived === false &&
					deliverySummary.recordType !== DeliveryRecordType.ROUTINE,
				title: 'There May Be Some Bottles In This Delivery',
				message: (
					<>
						<Typography component="p" color="text.secondary">
							Don’t worry! This is a normal part of the process. Here’s why.
						</Typography>
						<Typography component="p" color="text.secondary">
							<b>We are lining up your refill dates.</b>
							<br />
							This is so all your medications can be filled at the same time every month.
						</Typography>
						<Typography component="p" color="text.secondary">
							<b>You may also get less than a 30-day supply of some medications.</b>
							<br />
							This is how we line up your refills. You get a small supply now so that we can send a full supply of all
							your medications together on the same date.
						</Typography>
						<Typography component="p" color="text.secondary">
							<b>Please call us if you do not understand your delivery.</b>
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-D
			{
				condition:
					!!deliverySummary &&
					deliverySummary.orderStatus !== SFOrderStatus.RETURNED &&
					deliverySummary.isFirstExactPack === true,
				title: 'Congratulations On Your First ExactPack',
				message: (
					<>
						<Typography component="p" color="text.secondary">
							If we are still aligning your refill dates, your order may include some bottles
						</Typography>
						<Typography component="p" color="text.secondary">
							Always call us if you have any questions!
						</Typography>
						<Typography component="p" color="text.secondary">
							<Link href={SUPPORT_URLS.HOW_TO_USE} target="_blank">
								Click here
							</Link>{' '}
							to read more about using the ExactPack.
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-V
			{
				condition:
					!!deliverySummary &&
					deliverySummary.orderStatus !== SFOrderStatus.RETURNED &&
					deliverySummary.isFirstMonthlyOrder === true,
				title: 'Congratulations On Your First Monthly Delivery',
				message: (
					<>
						<Typography component="p" color="text.secondary">
							This delivery contains your first monthly order!{' '}
						</Typography>
						<Typography component="p" color="text.secondary">
							We may be still aligning your refills. There may be less than a 30-day supply of medications.{' '}
						</Typography>
						<Typography component="p" color="text.secondary">
							Always call us if you have any questions!
						</Typography>
					</>
				),
				icon: <CheckCircleOutlineOutlinedIcon color="success" />,
			},
			// DEL-F
			{
				condition: !!deliverySummary && deliverySummary.orderStatus === SFOrderStatus.RETURNED,
				title: 'Returned Order',
				message: (
					<Typography component="p" color="text.secondary">
						This order was returned to ExactCare. There are many reasons this could have happened. If you have not
						already talked to ExactCare about this order, please call us. We may need info from you to deliver your
						medications.
					</Typography>
				),
				icon: <ErrorOutlineRoundedIcon color="error" />,
			},
		],
		[firstRoutineOrderReceived, anyOrderReceived, shippingAddress, vialsPatient, deliverySummary]
	);

	return (
		<>
			{messages
				.filter(({ condition, title }) => condition && !!title)
				.map(({ title, icon, message }) => (
					<ContentCard key={title} title={title} icon={icon}>
						{message}
					</ContentCard>
				))}
		</>
	);
}

export default DeliveriesMessage;
