import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Pill2Icon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 58 59" {...props}>
			<path
				d="M53.25 30.5L28.5 5.75C25.25 2.5 21 0.75 16.75 0.75C12.5 0.75 8.25 2.5 5.25 5.5C2.25 8.5 0.5 12.75 0.5 17C0.5 21.25 2.25 25.5 5.25 28.5L6.25 29.5C2.75 32.5 0.5 37.25 0.5 42C0.5 51 7.75 58.25 16.75 58.25C21.5 58.25 26 56.25 29.25 52.5L30.25 53.5C33.25 56.5 37.5 58.25 41.75 58.25C46 58.25 50.25 56.5 53.25 53.5C56.25 50.5 58 46.25 58 42C58 37.75 56.25 33.5 53.25 30.5ZM49.75 50C47.5 52.25 44.75 53.25 41.75 53.25C38.75 53.25 35.75 52 33.75 50L32 48.25C32.75 46.25 33.25 44 33.25 42C33.25 38.5 32.25 35 30 32.25L39 23L49.75 33.75C52 36 53 38.75 53 41.75C53 45 52 48 49.75 50ZM16.75 25.75C14.5 25.75 12.5 26.25 10.5 27L8.75 25.25C6.5 23 5.25 20.25 5.25 17C5.25 13.75 6.5 11 8.75 9C11 6.75 13.75 5.75 16.75 5.75C19.75 5.75 22.75 7 24.75 9L35.5 19.75L26.5 28.75C23.5 26.75 20.25 25.75 16.75 25.75ZM19.25 53.25V30.75C24.5 32 28.25 36.5 28.25 42C28.25 47.5 24.25 52 19.25 53.25ZM5.25 42C5.25 36.5 9 32 14.25 30.75V53.25C9.25 52 5.25 47.25 5.25 42Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
