import { styled } from '@mui/material/styles';
import { Stepper as MuiStepper } from '@mui/material';

export const Stepper = styled(MuiStepper)(({ theme }) => ({
	'& .MuiStepLabel-label': {
		fontWeight: 700,
		fontSize: '1rem',
	},
	'& .MuiStepLabel-label.Mui-active': {
		color: theme.palette.primary.main,
	},
	'& .MuiStepLabel-label.Mui-completed': {
		color: theme.palette.primary.main,
	},
	'& .MuiStepLabel-label.Mui-disabled': {
		color: theme.palette.grey[500],
	},
	'& .MuiStepIcon-root': {
		color: 'transparent',
		borderRadius: '50%',
		border: `2px solid ${theme.palette.grey[500]}`,
		fontSize: '2rem',
	},
	'& .MuiStepIcon-root.Mui-active': {
		color: 'transparent',
		borderColor: theme.palette.primary.main,
	},
	'& .MuiStepIcon-root.Mui-completed': {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
	},
	'& .MuiStepIcon-text': {
		fontWeight: 700,
		fill: theme.palette.grey[500],
	},
	'& .Mui-active .MuiStepIcon-text': {
		fill: theme.palette.primary.main,
	},
	'& .MuiStepConnector-lineVertical': {
		borderLeftStyle: 'dashed',
		borderLeftWidth: '2px',
	},
	'& .Mui-completed .MuiStepConnector-lineVertical, & .Mui-active .MuiStepConnector-lineVertical': {
		borderColor: theme.palette.primary.main,
	},
}));

export default Stepper;
