import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PhoneForwardedIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 57 57" {...props}>
			<path
				d="M44.4996 2.5L54.4996 12.5M54.4996 12.5L44.4996 22.5M54.4996 12.5H34.4996M51.9996 42.3V49.8C52.0024 50.4963 51.8598 51.1854 51.5809 51.8234C51.3019 52.4613 50.8928 53.034 50.3798 53.5047C49.8667 53.9754 49.261 54.3337 48.6015 54.5568C47.9419 54.7798 47.243 54.8627 46.5496 54.8C38.8567 53.9641 31.4671 51.3354 24.9746 47.125C18.9342 43.2867 13.8129 38.1654 9.97459 32.125C5.74953 25.603 3.1202 18.1775 2.29959 10.45C2.23711 9.75867 2.31928 9.0619 2.54084 8.40406C2.7624 7.74622 3.11852 7.14172 3.5865 6.62905C4.05449 6.11638 4.6241 5.70677 5.25907 5.4263C5.89403 5.14584 6.58044 5.00065 7.27459 5H14.7746C15.9879 4.98806 17.1641 5.4177 18.084 6.20883C19.0039 6.99997 19.6048 8.09862 19.7746 9.3C20.0911 11.7002 20.6782 14.0568 21.5246 16.325C21.8609 17.2198 21.9337 18.1923 21.7344 19.1272C21.535 20.0621 21.0717 20.9203 20.3996 21.6L17.2246 24.775C20.7835 31.0339 25.9657 36.2161 32.2246 39.775L35.3996 36.6C36.0793 35.9278 36.9375 35.4646 37.8724 35.2652C38.8073 35.0658 39.7798 35.1386 40.6746 35.475C42.9428 36.3214 45.2994 36.9084 47.6996 37.225C48.914 37.3963 50.0231 38.008 50.8159 38.9437C51.6088 39.8795 52.03 41.0739 51.9996 42.3Z"
				stroke="currentColor"
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}
