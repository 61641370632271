import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import useShowSidebarNavigation from 'hooks/patient/useShowSidebarNavigation';
import { Paper } from './ContentLayout.styles';

interface ContentLayoutProps {
	children: React.ReactNode;
	sidebar: React.ReactNode;
}

export function ContentLayout({ children, sidebar }: ContentLayoutProps) {
	const xsScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xs'));
	const showSidebarNavigation = useShowSidebarNavigation();

	if (xsScreen) {
		if (showSidebarNavigation) {
			return (
				<Grid xs={12}>
					<Paper>{sidebar}</Paper>
				</Grid>
			);
		}

		return (
			<Grid xs={12}>
				<Paper>{children}</Paper>
			</Grid>
		);
	}

	return (
		<>
			<Grid sm={4}>
				<Paper>{sidebar}</Paper>
			</Grid>
			<Grid sm={8}>
				<Paper>{children}</Paper>
			</Grid>
		</>
	);
}
