import React from 'react';
import { ContentLayout, WalkThrough, deliveriesWalkThrough } from 'components/patient/common';
import DeliveriesSidebar from './DeliveriesSidebar';

interface DeliveriesLayoutProps {
	children: React.ReactNode;
}

export function DeliveriesLayout({ children }: DeliveriesLayoutProps) {
	return (
		<ContentLayout sidebar={<DeliveriesSidebar />}>
			{children}
			<WalkThrough {...deliveriesWalkThrough} />
		</ContentLayout>
	);
}

export default DeliveriesLayout;
