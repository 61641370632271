import React, { useCallback } from 'react';
import { Button, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ArrowIcon } from 'components/common/icons/ArrowIcon';
import { ScheduleIcon, ScheduleActions } from 'components/common/scheduleList';
import { UseMutateFunction } from '@tanstack/react-query';
import {
	IRecordTakenRequestParams,
	IRecordTakenResponse,
	IScheduledMedDetails,
	ScheduledMedStatus,
} from 'core/api/mySchedule/mySchedule.models';
import { SidebarList, SidebarListItem } from 'components/common/sidebarList';
import { HydraCollection } from 'core/api/api-service.models';

interface MedScheduleListByDayProps {
	data?: HydraCollection<IScheduledMedDetails>;
	isPending: boolean;
	hideActionButtons?: boolean;
	activeId?: number | null;
	onLogChange: UseMutateFunction<IRecordTakenResponse, Error, IRecordTakenRequestParams, unknown>;
	onShowScheduleDetails: (scheduleDetails: IScheduledMedDetails) => void;
}

export function MedScheduleListByDay({
	data,
	isPending,
	hideActionButtons,
	activeId,
	onLogChange,
	onShowScheduleDetails,
}: MedScheduleListByDayProps) {
	const { member: scheduleList } = data ?? {};

	const handleLogAllAsTaken = useCallback(
		(scheduleItem: IScheduledMedDetails) => (e: React.MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			const takenIds = [
				...scheduleItem.exactPackMedication,
				...scheduleItem.additionalMedication,
				...scheduleItem.monthlyMedication,
			].map((med) => med.id);
			onLogChange({ takenIds, skippedIds: [] });
		},
		[onLogChange]
	);

	return (
		<SidebarList>
			{scheduleList &&
				scheduleList.map((scheduleItem) => {
					const { type, title, status, scheduleInfo, timeOfDaySuffix, id } = scheduleItem;
					const isActive = activeId !== undefined ? activeId === id : status === ScheduledMedStatus.Active;

					return (
						<SidebarListItem
							key={title}
							onClick={() => onShowScheduleDetails(scheduleItem)}
							className={`EcaLink-ScheduleListItem-${status} ${isActive ? 'active' : ''}`}
						>
							<Stack direction="row" gap="12px" alignItems="center">
								<ListItemIcon
									sx={{
										minWidth: 'unset',
										'& .MuiSvgIcon-root': {
											fontSize: { xs: '1.875rem', sm: '2.25rem' },
										},
									}}
								>
									<ScheduleIcon {...{ type, status }} />
								</ListItemIcon>

								<ListItemText
									sx={{
										display: 'flex',
										flexDirection: 'column',
										margin: 0,
									}}
									disableTypography
									primary={
										<Typography variant="h2" fontWeight={500} color={isActive ? 'text.secondary' : 'text.primary'}>
											{title}
										</Typography>
									}
									secondary={
										<Stack gap="5px" alignItems="center" direction="row">
											<Typography variant="body1" color={isActive ? 'text.secondary' : 'text.primary'}>
												{scheduleInfo}{' '}
											</Typography>
											{timeOfDaySuffix && (
												<Tooltip
													placement="right"
													title={
														<>
															<b>Time-sensitive.</b>
															<br />
															Follow the schedule exactly as prescribed.
														</>
													}
												>
													<ScheduleRoundedIcon color="warning" />
												</Tooltip>
											)}
										</Stack>
									}
								/>
								<Stack direction="row" gap={2} color={isActive ? 'text.secondary' : 'text.primary'}>
									{status === ScheduledMedStatus.Logged && (
										<Typography variant="caption" display="inline-flex" gap="5px" alignItems="center" textAlign="right">
											Logged <CheckCircleIcon color="success" />
										</Typography>
									)}
									{status === ScheduledMedStatus.Passed && (
										<Typography variant="caption" display="inline-flex" gap="5px" alignItems="center" textAlign="right">
											Log Meds <ErrorIcon color="warning" />
										</Typography>
									)}
								</Stack>
								{!hideActionButtons && isActive && (
									<ScheduleActions sx={{ display: { xs: 'none', sm: 'flex' } }}>
										<Button
											onClick={handleLogAllAsTaken(scheduleItem)}
											disabled={isPending}
											variant="contained"
											color="primary"
										>
											Log All as Taken
										</Button>
										<Button onClick={() => onShowScheduleDetails(scheduleItem)} variant="outlined" color="inherit">
											Skip a Medication
										</Button>
									</ScheduleActions>
								)}
								<ListItemIcon sx={{ minWidth: 'unset' }}>
									<ArrowIcon />
								</ListItemIcon>
							</Stack>
							{!hideActionButtons && isActive && (
								<ScheduleActions
									sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', alignItems: 'stretch' }}
								>
									<Button
										onClick={handleLogAllAsTaken(scheduleItem)}
										disabled={isPending}
										variant="contained"
										color="primary"
									>
										Log All as Taken
									</Button>
									<Button onClick={() => onShowScheduleDetails(scheduleItem)} variant="outlined" color="inherit">
										Skip a Medication
									</Button>
								</ScheduleActions>
							)}
						</SidebarListItem>
					);
				})}
		</SidebarList>
	);
}

export default MedScheduleListByDay;
