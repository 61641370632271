import React from 'react';
import { Typography } from '@mui/material';
import ContentCard from 'components/patient/common/ContentCard';
import { AsNeededMedList } from 'components/patient/mySchedule';
import Suspense from 'components/common/Suspense';

export function LogAsNeededMedPage() {
	return (
		<ContentCard title="Log As-Needed Medication">
			<Typography variant="body1" component="p" color="text.secondary">
				Track the medications you are taking only when needed or when symptoms appear.
			</Typography>
			<Suspense>
				<AsNeededMedList />
			</Suspense>
		</ContentCard>
	);
}
