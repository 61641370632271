import React from 'react';
import routes from 'routes';
import PersonalInfoIcon from 'components/common/icons/PersonalInfoIcon';
import AlertsIcon from 'components/common/icons/AlertsIcon';
import StatementIcon from 'components/common/icons/StatementIcon';
import { ContentCard, MainSidebarMenu } from 'components/patient/common';

export function AccountSidebar() {
	const menuItems = [
		{
			label: 'Personal Information',
			icon: <PersonalInfoIcon fontSize="large" />,
			to: routes.account.personalInformation,
			state: { showSidebarNavigation: false },
			activeRoutes: [routes.account.personalInformation],
		},
		{
			label: 'Notifications Preferences',
			icon: <AlertsIcon fontSize="large" />,
			to: routes.account.notificationsPreferences,
			activeRoutes: [routes.account.notificationsPreferences],
		},
		{
			label: 'Payments and Billing',
			icon: <StatementIcon fontSize="large" />,
			to: routes.account.paymentsAndBilling,
			activeRoutes: [routes.account.paymentsAndBilling],
		},
	];

	return (
		<ContentCard title="Account">
			<MainSidebarMenu menuItems={menuItems} />
		</ContentCard>
	);
}

export default AccountSidebar;
