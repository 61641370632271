import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextField, Button, Box, Typography, Link, Stack } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { ICreateUserRequestParams } from 'core/api/registration/registration.models';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';
import { LEGAL_URLS } from 'constants/urls';
import setFormErrors from 'utils/setFormErrors';
import { StepTexts, useRegister } from '../RegisterProvider';

const schema = Joi.object({
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.messages({
			'*': 'Please enter a valid email address.',
		}),
	password: buildPasswordSchema().required().messages({
		'*': 'Please enter a valid password.',
	}),
	confirmPassword: Joi.string().valid(Joi.ref('password')).required().messages({
		'*': 'Please enter a valid password.',
	}),
});

interface FormFields extends ICreateUserRequestParams {
	confirmPassword: string;
}

export function CreateAccount() {
	const { initialData, hash } = useRegister();
	const { firstName, patientEmail } = initialData ?? {};
	const { createUser, resetRegister } = useRegisterMutation();

	const {
		control,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<FormFields>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			email: patientEmail ?? '',
		},
	});

	const handleCreateUser = handleSubmit(({ email, password }) =>
		createUser.mutate(
			{ email, password, hash },
			{
				onError: (error) => setFormErrors<ICreateUserRequestParams>(error, setError),
			}
		)
	);

	return (
		<>
			<StepTexts introText="You're Halfway There!" extraIntroText="Create your account in just a few short steps." />

			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				{firstName}, Create Your Account
			</Typography>
			<Typography component="p" fontWeight="400" variant="caption" color="text.secondary">
				Not you?{' '}
				<Button
					onClick={() => resetRegister.mutate({ hash })}
					disabled={resetRegister.isPending}
					color="inherit"
					variant="text"
				>
					{resetRegister.isPending ? 'Loading...' : 'Switch Account'}
				</Button>
			</Typography>
			<Stack sx={{ width: { xs: '100%', md: '56%' } }} gap={2} component="form" onSubmit={handleCreateUser}>
				<Controller
					name="email"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							error={!!error}
							helperText={error?.message}
							label="Enter Your Email Address"
							size="small"
							autoComplete="username"
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<AlternateEmailIcon />
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				<Controller
					name="password"
					control={control}
					render={({ field: { value, ...restField }, fieldState: { error } }) => (
						<PasswordField
							{...restField}
							value={value ?? ''}
							label="Choose Your Password"
							size="small"
							autoComplete="new-password"
							error={!!error}
							helperText={error?.message}
							fullWidth
						/>
					)}
				/>

				<Controller
					name="confirmPassword"
					control={control}
					render={({ field: { value, ...restField }, fieldState: { error } }) => (
						<PasswordField
							{...restField}
							value={value ?? ''}
							autoComplete="new-password"
							helperText={error?.message}
							error={!!error}
							label="Type Your Password Again"
							size="small"
							disableTooltip
							fullWidth
						/>
					)}
				/>

				<FormErrorMessages errors={errors} name="root" />

				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Button disabled={createUser.isPending} variant="contained" color="primary" fullWidth type="submit">
						{createUser.isPending ? 'Loading...' : 'Create Your Account'}
					</Button>
				</Box>

				<Typography fontWeight="400" variant="caption" color="text.secondary">
					By clicking &apos;Create Your Account&apos; you agree to the{' '}
					<Link href={LEGAL_URLS.TERMS_AND_CONDITIONS} fontSize="14px" textTransform="none" target="_blank">
						Terms and Conditions
					</Link>
					.
				</Typography>
			</Stack>
		</>
	);
}

export default CreateAccount;
