import { useQuery } from '@tanstack/react-query';
import { patientPushDeviceList } from 'core/api/admin/patients.api';
import { PatientPushDevicesParams } from 'core/api/admin/patients.models';

export function usePatientPushDeviceListQuery({ page, patientId, ...restRequest }: PatientPushDevicesParams) {
	return useQuery({
		queryKey: ['admin', 'patients', 'view', patientId, 'pushDevice', 'list', restRequest, page],
		queryFn: () => patientPushDeviceList({ page, patientId, ...restRequest }),
		enabled: !!patientId,
	});
}

export default usePatientPushDeviceListQuery;
