import React from 'react';
import { Stack, Button, TextField } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IPatientResponse } from 'core/api/admin/patients.models';
import { usePatientMutations } from 'hooks/admin';
import setFormErrors from 'utils/setFormErrors';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';

const schema = Joi.object({
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.messages({
			'string.empty': 'Email cannot be empty',
			'string.email': 'Invalid email',
			'any.required': 'Email is required',
		}),
});

interface PatientChangeEmailForm {
	email: string;
}

interface PatientChangeEmailProps {
	patient: IPatientResponse;
	onSuccess: () => void;
}

export function PatientChangeEmail({ patient, onSuccess }: PatientChangeEmailProps) {
	const { changeEmailMutation } = usePatientMutations();

	const {
		control,
		handleSubmit,
		formState: { isValid, errors },
		setError,
	} = useForm<PatientChangeEmailForm>({
		mode: 'onChange',
		resolver: joiResolver(schema),
		defaultValues: {
			email: patient?.email || '',
		},
	});

	const handleSubmitChangeEmail = (data: PatientChangeEmailForm) => {
		changeEmailMutation.mutate(
			{
				userId: patient.userId.toString(),
				email: data.email,
			},
			{ onSuccess, onError: (error) => setFormErrors<PatientChangeEmailForm>(error, setError) }
		);
	};

	return (
		<Stack gap={2} mt={2} component="form" onSubmit={handleSubmit(handleSubmitChangeEmail)}>
			<FormErrorMessages errors={errors} name="root" />
			<Controller
				name="email"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...field}
						value={field.value || ''}
						label="New email"
						variant="outlined"
						size="small"
						fullWidth
						helperText={error?.message}
						error={!!error}
					/>
				)}
			/>
			<Button
				disabled={!isValid || changeEmailMutation.isPending}
				variant="outlined"
				color="primary"
				fullWidth
				type="submit"
			>
				{changeEmailMutation.isPending ? 'Loading...' : 'Save'}
			</Button>
		</Stack>
	);
}

export default PatientChangeEmail;
