import { Subject, fromEvent, merge, filter, map, Observable } from 'rxjs';
import { distinctUntilChanged, share } from 'rxjs/operators';

export type StorageValue<T> = T | null;

export abstract class StorageService implements Storage {
	private readonly storageSubject = new Subject<{ key: string; value: any }>();

	private readonly storageObservable: Observable<{ key: string; value: any }>;

	public get length(): number {
		return this.api.length;
	}

	public constructor(
		protected readonly api: Storage,
		protected readonly prefix?: string
	) {
		this.storageObservable = this.initializeStorageObservable();
	}

	private initializeStorageObservable(): Observable<{ key: string; value: any }> {
		return merge(this.initializeStorageListener(), this.storageSubject.asObservable()).pipe(share());
	}

	private initializeStorageListener(): Observable<{ key: string; value: any }> {
		return fromEvent<StorageEvent>(window, 'storage').pipe(
			filter((event: StorageEvent): boolean => {
				if (!event.key) return false;
				return !this.prefix || event.key.startsWith(this.prefix);
			}),
			map((event) => {
				if (!event.newValue) return { key: event.key!, value: null };
				try {
					return { key: event.key!, value: JSON.parse(event.newValue) };
				} catch {
					return { key: event.key!, value: event.newValue };
				}
			})
		);
	}

	private getStorageKey(key: string): string {
		return (this.prefix ?? '') + key;
	}

	public setItem(key: string, value: any): void {
		const storageKey = this.getStorageKey(key);
		const storageValue = typeof value === 'object' ? JSON.stringify(value) : value;
		this.api.setItem(storageKey, storageValue);
		this.storageSubject.next({ key: storageKey, value });
	}

	public getItem<T>(key: string): StorageValue<T>;
	public getItem<T>(key: string, otherwise: T): T;
	public getItem<T>(key: string, otherwise?: T): StorageValue<T> {
		const data: string | null = this.api.getItem(this.getStorageKey(key));

		if (data !== null) {
			try {
				return JSON.parse(data);
			} catch {
				return data as T;
			}
		}

		return otherwise ?? null;
	}

	public removeItem(key: string): void {
		const storageKey = this.getStorageKey(key);
		this.api.removeItem(storageKey);
		this.storageSubject.next({ key: storageKey, value: null });
	}

	public clear(): void {
		this.api.clear();
		this.storageSubject.next({ key: '*', value: null });
	}

	public key(index: number): string | null {
		return this.api.key(index);
	}

	public watch<T>(key: string): Observable<StorageValue<T>> {
		const storageKey = this.getStorageKey(key);
		const initialValue = this.getItem<T>(key);

		return merge(
			this.storageObservable.pipe(
				filter((event) => event.key === storageKey || event.key === '*'),
				map((event) => event.value)
			),
			new Observable<StorageValue<T>>((subscriber) => {
				subscriber.next(initialValue);
			})
		).pipe(distinctUntilChanged((prev, curr) => prev === curr));
	}

	public watchAll(): Observable<{ key: string; value: any }> {
		return this.storageObservable;
	}
}
