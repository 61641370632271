import { useQuery } from '@tanstack/react-query';
import { IGetDeliveriesSummaryRequestParams } from 'core/api/delivery/delivery.models';
import { deliveryQueries } from 'queries/patient';

export const useDeliveriesListQuery = () => {
	return useQuery(deliveryQueries.getDeliveries());
};

export const useDeliveryDetailsQuery = (props: Partial<IGetDeliveriesSummaryRequestParams>) => {
	return useQuery(deliveryQueries.getDelivery(props));
};

export const useDeliveries = {
	getDeliveriesQuery: useDeliveriesListQuery,
	getDeliveryQuery: useDeliveryDetailsQuery,
};

export default useDeliveries;
