import { Box, styled } from '@mui/material';

export const Content = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	[theme.breakpoints.down('sm')]: {
		margin: '0 -16px',
	},
}));

export const AppLayoutBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.secondary,
	backgroundImage:
		'radial-gradient(at 27% 99%, hsla(206, 80%, 43%, 0.63) 0px, transparent 50%), radial-gradient(at 58% 46%, hsla(283, 100%, 28%, 0.34) 0px, transparent 50%)',
	minHeight: '100vh',
	paddingBottom: '40px',

	[theme.breakpoints.down('sm')]: {
		backgroundImage:
			'radial-gradient(at 51% 3%, hsla(240,11%,96%,0.59) 0px, transparent 50%), radial-gradient(at 24% 72%, hsla(206,80%,43%,0.5) 0px, transparent 50%), radial-gradient(at 77% 99%, hsla(283,100%,28%,0.4) 0px, transparent 50%)',
	},

	'& .MuiContainer-root': {
		gap: '24px',
		display: 'flex',
		minHeight: '100vh',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		minWidth: '1180px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
			minWidth: 'auto',
			gap: '0',
		},
	},
}));
