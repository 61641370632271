import { queryOptions } from '@tanstack/react-query';
import { getInit } from 'core/api/init/init.api';
import bearerTokenService from 'services/bearerTokenService';

export const initQuery = () => {
	const token = bearerTokenService.getToken();

	return queryOptions({
		queryKey: ['init'],
		queryFn: getInit,
		enabled: !!token,
	});
};

export default initQuery;
